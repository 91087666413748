import React, { useEffect, useState } from "react";
import "../feedback/survey.scss";

// eslint-disable-next-line no-unused-vars
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import sikka_logo from "../../assets/images/sikka_logo_transparent.png";
import Big_spinner from "../../assets/images/Spinner.gif";

import useSetTitle from "../../hooks/useSetTitle";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiCalls } from "../../api/allApiCalls";
import Trademark from "../Trademark/Trademark";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";
import PpDashboard from "../../assets/images/PpDashboard.png";
import ButtonLoader from "../../components/loaders/ButtonLoader";

const Survey = () => {
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const url = window.location.href;
    const parameters = {};
    const fullURL = url.split("?");

    const queryParameters = fullURL[1].split("&");

    // queryParameters.forEach((parameter) => {
    //     const [key, value] = parameter.split("=");
    //     parameters[key] = value;
    // });
    queryParameters.forEach((parameter) => {
        const firstEqualsIndex = parameter.indexOf("=");
        if (firstEqualsIndex !== -1) {
            const key = parameter.substring(0, firstEqualsIndex);
            const value = parameter.substring(firstEqualsIndex + 1);
            parameters[key] = value;
        }
    });
    const referenceIdPost = parameters["ReferenceId"];
    // // const sid = parameters["sid"];

    // const sidPost = parameters["sid"];
    const referenceId = encodeURIComponent(parameters["ReferenceId"]);
    // const sid = parameters["sid"];

    const sid = encodeURIComponent(parameters["sid"]);

    const { apiToken } = useSelector((state) => state.userInfo);
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [showSpinner]);
    useEffect(() => {
        if (!referenceId || apiToken) return navigate("*");
    }, [apiToken, referenceId]);

    const [selectedOptions, setSelectedOptions] = useState({});
    const [nonSelectedQuestion, setNonSelectedQuestion] = useState([]);
    const [data, setData] = useState([]);
    const [textError, setTextError] = useState(true);
    const [textvalue, setTextValue] = useState("");
    const [qid, setQid] = useState("");

    const handleOptionChange = (questionId, option, controlType) => {
        setQid(questionId);
        if (controlType == "TextBox" && questionId) {
            const wordsArray = option.split(/\w+/);
            setTextValue(wordsArray);

            if (wordsArray.length <= 200 && wordsArray.length >= 10) {
                setTextError(false);
            } else {
                // alert("eee");
                setTextError(true);
                return;
            }
        }

        const updatedSelectedOptions = { ...selectedOptions };

        if (updatedSelectedOptions.questionId) {
            if (controlType == "TextBox" && option.length === 0 && questionId) {
                delete updatedSelectedOptions[questionId];
            } else {
                delete updatedSelectedOptions[questionId];
            }
        } else {
            updatedSelectedOptions[questionId] = option;
        }

        setSelectedOptions(updatedSelectedOptions);
    };

    const uncheckOption = (questionId) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [questionId]: null,
        }));
    };

    const submitHandle = () => {
        setLoading(true);
        setButtonDisabled(true);
        const nonselectedQuestions = data.filter(
            (item) =>
                !selectedOptions[item.questionId] &&
                item.controlType !== "CloseMessage"
        );
        setNonSelectedQuestion(nonselectedQuestions);

        if (nonselectedQuestions.length === 0) {
            const questionAnswers = selectedOptions;
            const formattedString = Object.keys(questionAnswers)
                .map((questionId) => {
                    const answer = questionAnswers[questionId];
                    return `${questionId}#$#${answer}`;
                })
                .join("#|#");

            // Handle asynchronous operation outside the try-catch block
            const postFeedback = async () => {
                try {
                    const res = await ApiCalls.authenticationPOST(
                        `/v2/EmailResponse/SurveyData`,
                        {
                            referenceId: referenceIdPost,
                            surveyId: data[0].surveyId,
                            options: formattedString,
                        }
                    );

                    if (
                        res.isSuccess === true &&
                        res.message === "successful"
                    ) {
                        trackAcvitity(
                            itemType.EMAILRESPONSE_SURVEY,
                            itemName.EMAILRESPONSE_SURVEY,
                            "Click on Link >> Select Options and type Answer>> Click 'Submit' button >> Success"
                        );
                        Swal.fire({
                            title: "Feedback form submitted successfully!",
                            icon: "success",
                            html: `<div>
                            <div><p>You can now stay on top of your treatment plans and payments, communicate with your dentist, and make appointments through your very own patient portal.</p></div>
                            <div style = "color: #681C9A; font-size: 20px; cursor: pointer;" id="createAccount">Create an Account</div>
                            <div><img alt="" src=${PpDashboard} style="max-width: 80%; height: auto; display: block; margin: 15px auto; border:1px solid #C4C4C4"/></div>
                            </div>`,
                            width: "50em",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                        const handleCreateAccountClick = () => {
                            Swal.close();
                            navigate("/authentication");
                        };

                        document
                            .getElementById("createAccount")
                            .addEventListener(
                                "click",
                                handleCreateAccountClick
                            );
                    } else {
                        Swal.fire({
                            title: "Something went wrong!",
                            icon: "error",
                        });
                    }
                } catch (err) {
                    console.log(err);
                    Swal.fire({
                        title: "An error occurred while submitting the form.",
                        icon: "error",
                    });
                } finally {
                    setLoading(false);
                    setButtonDisabled(false);
                }
            };

            postFeedback();
        }
    };

    useSetTitle("survey");

    useEffect(() => {
        if (!referenceId) {
            return navigate("*");
        }

        const fetchSurveyData = async () => {
            try {
                const res = await ApiCalls.authenticationGET(
                    `/v2/EmailResponse/SurveyData?ReferenceId=${referenceId}&SurveyId=${sid}`
                );
                setData(res);
            } catch (err) {
                console.log(err);
            }
        };

        fetchSurveyData();
    }, [referenceId]);

    return (
        <>
            {showSpinner ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                        background: `url('${sikka_logo}') center center no-repeat`,
                        backgroundSize: "40%",
                    }}
                >
                    <div style={{ marginTop: "200px" }}>
                        <img
                            src={Big_spinner}
                            alt="Loading..."
                            height={80}
                            width={80}
                        />
                    </div>
                </div>
            ) : (
                <div className="feedback-page">
                    <div className="container ">
                        <div className="row header-container">
                            <div className="col">
                                <div className="head-title">
                                    <h1 className="head-title">Survey</h1>
                                    <h1 className="head-title-1">
                                        “The goal as a company is to have
                                        customer service that is not just the
                                        best but legendary.”
                                    </h1>
                                </div>
                                {/* 
                                <button
                                    className="button-php-survey mt-5"
                                    onClick={() =>
                                        (window.location.href =
                                            "https://patientportal.sikkasoft.com")
                                    }
                                >
                                    Patient Portal{" "}
                                    <ArrowForwardIosIcon className="php-icon" />
                                </button> */}
                            </div>
                        </div>
                        <div className="container mt-5 feedback-questions">
                            {data &&
                                data.map((item) => (
                                    <ul
                                        className="list-unstyled row mt-5 question-map"
                                        key={item.questionId}
                                    >
                                        <li key={item.questionId}>
                                            <h6
                                                key={item.questionId}
                                                className={
                                                    nonSelectedQuestion.some(
                                                        (question) =>
                                                            question.questionId ===
                                                            item.questionId
                                                    )
                                                        ? "red-question"
                                                        : "black-question"
                                                }
                                            >
                                                {item.controlType ===
                                                "CloseMessage" ? null : (
                                                    <>{item.question}</>
                                                )}{" "}
                                            </h6>
                                            <ul className="mt-1 row">
                                                {item.controlType ===
                                                    "CheckBox" &&
                                                    item.options.map(
                                                        (option, index) => (
                                                            <span
                                                                key={index}
                                                                onClick={() =>
                                                                    uncheckOption(
                                                                        item.questionId
                                                                    )
                                                                }
                                                                className="option-format"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    value={
                                                                        option.option
                                                                    }
                                                                    name={`question_${item.questionId}`}
                                                                    checked={
                                                                        selectedOptions[
                                                                            item
                                                                                .questionId
                                                                        ] ===
                                                                        option.option
                                                                    }
                                                                    onChange={() =>
                                                                        handleOptionChange(
                                                                            item.questionId,
                                                                            option.option
                                                                        )
                                                                    }
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight:
                                                                            "5px",
                                                                        border: "1px solid #000",
                                                                        borderRadius:
                                                                            "3px",
                                                                        padding:
                                                                            "2px",
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {option.option}
                                                            </span>
                                                        )
                                                    )}

                                                {item.controlType ===
                                                    "RadioButton" &&
                                                    item.options.map(
                                                        (option, index) => (
                                                            <span
                                                                key={index}
                                                                onClick={() =>
                                                                    uncheckOption(
                                                                        item.questionId
                                                                    )
                                                                }
                                                                className="option-format"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    value={
                                                                        option.option
                                                                    }
                                                                    name={`question_${item.questionId}`}
                                                                    checked={
                                                                        selectedOptions[
                                                                            item
                                                                                .questionId
                                                                        ] ===
                                                                        option.option
                                                                    }
                                                                    onChange={() =>
                                                                        handleOptionChange(
                                                                            item.questionId,
                                                                            option.option
                                                                        )
                                                                    }
                                                                />
                                                                &nbsp;
                                                                {option.option}
                                                            </span>
                                                        )
                                                    )}

                                                {item.controlType ===
                                                    "TextBox" && (
                                                    <>
                                                        <textarea
                                                            type="text"
                                                            value={
                                                                selectedOptions[
                                                                    (item.questionId,
                                                                    textvalue)
                                                                ]
                                                            }
                                                            style={{
                                                                width: "90%",
                                                                height: "8rem",
                                                            }}
                                                            onChange={(e) =>
                                                                handleOptionChange(
                                                                    item.questionId,
                                                                    e.target
                                                                        .value,
                                                                    item.controlType
                                                                )
                                                            }
                                                        />
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                justifyContent:
                                                                    "space-between",
                                                                width: "90%",
                                                            }}
                                                        >
                                                            {textError &&
                                                            item.questionId ===
                                                                qid ? (
                                                                <span
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    A minimum of
                                                                    10 and a
                                                                    maximum of
                                                                    200 words
                                                                    are
                                                                    required!
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}

                                                            <span
                                                                className={
                                                                    item.questionId ===
                                                                        qid &&
                                                                    textvalue.length >
                                                                        200
                                                                        ? "wordCountError "
                                                                        : ""
                                                                }
                                                            >
                                                                {item.questionId ===
                                                                qid
                                                                    ? textvalue.length +
                                                                      "/" +
                                                                      200
                                                                    : ""}
                                                            </span>
                                                        </span>
                                                    </>
                                                )}

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    {item.controlType ===
                                                        "Rating" &&
                                                        item.options.map(
                                                            (option, index) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    <span
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="Rating_Q"
                                                                        onClick={() =>
                                                                            uncheckOption(
                                                                                item.questionId
                                                                            )
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="radio"
                                                                            value={
                                                                                option.option
                                                                            }
                                                                            name={`question_${item.questionId}`}
                                                                            checked={
                                                                                selectedOptions[
                                                                                    item
                                                                                        .questionId
                                                                                ] ===
                                                                                option.option
                                                                            }
                                                                            onChange={() =>
                                                                                handleOptionChange(
                                                                                    item.questionId,
                                                                                    option.option
                                                                                )
                                                                            }
                                                                        />
                                                                        &nbsp;
                                                                        {
                                                                            option.option
                                                                        }
                                                                    </span>
                                                                    &nbsp;&nbsp;
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </ul>
                                        </li>
                                    </ul>
                                ))}

                            {data &&
                                data.map(
                                    (item) =>
                                        item.controlType === "CloseMessage" && (
                                            <div
                                                key={item.questionId}
                                                className="closeMessage"
                                            >
                                                {item.options.map(
                                                    (option, index) => (
                                                        <span
                                                            key={index}
                                                            className="option-format"
                                                        >
                                                            &nbsp;
                                                            {option.option}
                                                        </span>
                                                    )
                                                )}
                                            </div>
                                        )
                                )}
                        </div>
                    </div>

                    <div className="button-flex mt-3">
                        <button
                            className="appointment-btn"
                            onClick={() => submitHandle()}
                            disabled={buttonDisabled}
                        >
                            {loading ? <ButtonLoader /> : "Submit"}
                        </button>
                    </div>
                    <div>
                        <Trademark />
                    </div>
                </div>
            )}
        </>
    );
};

export default Survey;
