import React, { useState } from "react";
import { itemName, itemType, trackAcvitity } from "../utils/activityTracking";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import ComposeMailForm from "../pages/Messages/ComposeMailForm";

function IncorrectInfoContact() {
    const [openComposeMailModal, setOpenComposeMailModal] = useState(false);
    const tooltipContent =
        "If any information is incorrect, or needs to be updated, click here to contact support";
    return (
        <div className="bg-white position-relative" id="incorrect-info-id">
            <Tooltip title={tooltipContent}>
                <HelpOutlineIcon
                    style={{
                        height: "40px",
                        width: "40px",
                    }}
                    id="IncorrectInfoContact-id"
                    role="button"
                    onClick={() => {
                        setOpenComposeMailModal(true);
                        trackAcvitity(
                            itemType.MESSAGES,
                            itemName.OPEN_COMPOSE_MAIL_MODAL,
                            "Any page >> Click Help Icon to contact support"
                        );
                    }}
                ></HelpOutlineIcon>
            </Tooltip>
            <div>
                {openComposeMailModal && (
                    <ComposeMailForm
                        openModal={openComposeMailModal}
                        closeModal={() => setOpenComposeMailModal(false)}
                        contactSupport={true}
                    />
                )}
            </div>
        </div>
    );
}

export default IncorrectInfoContact;
