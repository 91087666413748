import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { RotatingLines } from "react-loader-spinner";

import "./InsuranceDetails.scss";
import { ApiCalls } from "../../api/allApiCalls";
import Modal from "../../components/modals/Modal";
import { convertBase64 } from "../../utils";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";

function InsuranceCardForm({ openModal, closeModal, setUpdateData }) {
    const { masterId, userId, practiceId, custId, patientId } = useSelector(
        (state) => state.userInfo
    );
    const [newInsuranceName, setNewInsuranceName] = useState("");
    const frontRef = useRef(null);
    const backRef = useRef(null);
    const [front, setFront] = useState(null);
    const [back, setBack] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleUpload = async () => {
        if (!newInsuranceName || !front || !back) {
            setError(
                "Please make sure you entered Insuance Name, one image for the front and one image for the back of the Insurance Card"
            );
            return;
        }

        try {
            setLoading(true);
            const res = await ApiCalls.authPostAPI(
                "/v2/Patient/InsuranceCardData",
                {
                    masterId,
                    userId,
                    practiceId,
                    custId,
                    patientId,
                    cardName: newInsuranceName,
                    frontFileName: front.name,
                    frontFileBase64: front.fileBase64,
                    backFileName: back.name,
                    backFileBase64: back.fileBase64,
                }
            );
            if (res?.isSuccess && res?.message === "Successful") {
                Swal.fire({
                    icon: "success",
                    text: "Insurance Card uploaded successfully.",
                });
                setUpdateData((prev) => !prev);
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again later",
                });
            }
            trackAcvitity(
                itemType.USER_PROFILE,
                itemName.UPLOAD_NEW_INSURANCE_CARD,
                "User Profile page >> Click plus icon to add Insurance Card >> Fill insurance name and upload front & back images >> Click 'Upload' button"
            );
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    const processFile = (side, files) => {
        if (files) {
            const file = files[0];

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!file?.type?.includes("image")) {
                    setError("Invalid format (Jpg, Png, Jpeg)");
                    setTimeout(() => {
                        setError("");
                    }, 2000);
                    return;
                }
                const fileOjb = {
                    name: file.name,
                    fileBase64: convertBase64(reader.result),
                };
                if (side === "front") {
                    setFront(fileOjb);
                } else {
                    setBack(fileOjb);
                }
            };
        }
    };

    const handleClick = (event) => {
        const { target = {} } = event || {};
        target.value = "";
    };

    return (
        <Modal openModal={openModal} closeModal={closeModal}>
            <div
                className="d-flex flex-column gap-4 p-5"
                style={{ maxWidth: 400 }}
            >
                <div className="position-relative">
                    {loading && (
                        <div
                            className="position-absolute width-100p height-100p bg-white d-flex flex-column align-items-center justify-content-center text-center"
                            style={{ zIndex: 1 }}
                        >
                            <RotatingLines
                                height="40"
                                width="40"
                                strokeColor="#681c9a"
                            />
                            <div className="mt-4">
                                Processing data...
                                <br />
                                Please keep the modal open.
                            </div>
                        </div>
                    )}
                    <div>
                        <label>Insurance</label>
                        <input
                            placeholder="Enter Insurance Name"
                            value={newInsuranceName}
                            onChange={(e) =>
                                setNewInsuranceName(e.target.value)
                            }
                        />
                    </div>
                    <div>
                        <div className="mb-2 mt-4">
                            Please upload your insurance card
                            <br />
                            <span
                                className="text-italic"
                                style={{ fontSize: 13 }}
                            >
                                (Jpg, Png, Jpeg file format)
                            </span>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-3">
                                <div style={{ width: 40 }}>Front</div>
                                <input
                                    ref={frontRef}
                                    type="file"
                                    id="input-insurance-front-upload"
                                    accept="image/jpeg, image/jpg, image/png"
                                    multiple={false}
                                    onChange={(e) =>
                                        processFile("front", e.target.files)
                                    }
                                    onClick={handleClick}
                                />
                                <label
                                    id="label-insurance-front-upload"
                                    className="border-0"
                                    htmlFor="input-insurance-front-upload"
                                >
                                    <CloudUploadIcon
                                        className="font-purple"
                                        role="button"
                                    />
                                </label>
                                <div className="text-underline opacity-05">
                                    {front ? front?.name : ""}
                                </div>
                            </div>
                            <div className="d-flex gap-3">
                                <div style={{ width: 40 }}>Back</div>
                                <input
                                    ref={backRef}
                                    type="file"
                                    id="input-insurance-back-upload"
                                    accept="image/jpeg, image/jpg, image/png"
                                    multiple={false}
                                    onChange={(e) =>
                                        processFile("back", e.target.files)
                                    }
                                    onClick={handleClick}
                                />
                                <label
                                    id="label-insurance-back-upload"
                                    className="border-0"
                                    htmlFor="input-insurance-back-upload"
                                >
                                    <CloudUploadIcon
                                        className="font-purple"
                                        role="button"
                                    />
                                </label>
                                <div className="text-underline opacity-05">
                                    {back ? back?.name : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex gap-3 mt-4">
                        <button
                            className="button button-primary"
                            onClick={handleUpload}
                        >
                            Upload
                        </button>
                        <button
                            className="button button-secondary"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div>
                        {error && (
                            <div className="fz-14 font-destructive mt-2">
                                {error}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default InsuranceCardForm;
