import React from "react";
import AuthenticationLeftColumn from "../../components/AuthenticationLeftColumn";

import "./Authentication.scss";
import AuthenticationForm from "./AuthenticationForm";
import useSetTitle from "../../hooks/useSetTitle";

function Authentication() {
	useSetTitle("Authentication");

	return (
		<div className="authentication-page">
			<div className="authentication-left-content">
				<AuthenticationLeftColumn />
			</div>
			<div className="authentication-right-content">
				<AuthenticationForm />
			</div>
		</div>
	);
}

export default Authentication;
