import React, { useEffect, useState } from "react";

import "./Education.scss";
import useSetTitle from "../../hooks/useSetTitle";
import { articleData } from "../../components/articles/articleData";
import { formatISOtoShortMonthDate } from "../../utils";
import oralB_logo from "../../assets/images/oralB_logo.png";
import Crest_logo from "../../assets/images/Crest_logo.png";
import IncorrectInfoContact from "../../components/IncorrectInfoContact";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";
//import SaigeButton from "../../components/SAIGE/SaigeButton";

const sourceImgStyles = {
    height: 16,
};

function Education() {
    const [filteredData, setFilteredData] = useState(articleData);
    const [selectedOption, setSelectedOption] = useState("0");

    useSetTitle("Education");

    useEffect(() => {
        const sortedArticles = [...articleData];
        switch (selectedOption) {
            case "0": {
                setFilteredData(articleData);
                break;
            }
            case "1": {
                sortedArticles.sort((a, b) => {
                    if (a.articleTitle < b.articleTitle) {
                        return 1;
                    }
                    if (a.articleTitle > b.articleTitle) {
                        return -1;
                    }
                    return 0;
                });
                setFilteredData(sortedArticles);
                break;
            }
            case "2": {
                sortedArticles.sort((a, b) => {
                    if (a.articleTitle < b.articleTitle) {
                        return -1;
                    }
                    if (a.articleTitle > b.articleTitle) {
                        return 1;
                    }
                    return 0;
                });
                setFilteredData(sortedArticles);
                break;
            }
            case "3": {
                sortedArticles.sort((a, b) => {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                });
                setFilteredData(sortedArticles);
                break;
            }
            case "4": {
                sortedArticles.sort((a, b) => {
                    return new Date(a.timestamp) - new Date(b.timestamp);
                });
                setFilteredData(sortedArticles);
                break;
            }
            default:
                setFilteredData(articleData);
        }
    }, [selectedOption]);

    return (
        <div style={{ padding: 13 }}>
            <div className="top-bar">
                {/* <SaigeButton /> */}
                <IncorrectInfoContact />
            </div>
            <div className="education-page">
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="fz-20 mt-4 mb-4 fw-bold">
                        Educational Articles
                    </h2>
                    <select
                        value={selectedOption}
                        style={{ maxWidth: 250 }}
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                            trackAcvitity(
                                itemType.EDUCATION_ARTICLES,
                                itemName.FILTER_EDUCATION_ARTICLES,
                                "Education page >> Click on 'Filter Article By' dropdown >> Filter the Articles"
                            );
                        }}
                    >
                        <option value="0">Filter Article By</option>
                        <option value="2">Article Title A-Z</option>
                        <option value="1">Article Title Z-A</option>
                        <option value="3">Publication Date (Latest)</option>
                        <option value="4">Publication Date (Oldest)</option>
                    </select>
                </div>

                <div className="articles-container">
                    {filteredData.map((item, idx) => (
                        <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={idx}
                            className="article font-gray"
                            style={{
                                height: 150,
                                overflowY: "auto",
                                textDecoration: "none",
                            }}
                            onClick={() =>
                                trackAcvitity(
                                    itemType.EDUCATION_ARTICLES,
                                    itemName.CLICK_EDUCATION_ARTICLE_LINK,
                                    "Education page >> Click article card"
                                )
                            }
                        >
                            <div className="d-flex align-items-center gap-3">
                                <div>
                                    <img
                                        style={{
                                            width: 85,
                                            height: 85,
                                            objectFit: "cover",
                                            borderRadius: 6,
                                        }}
                                        alt=""
                                        src={item.imgSrc}
                                    />
                                </div>

                                <div
                                    className="height-100p d-flex align-items-start flex-column justify-content-start"
                                    style={{ overflow: "hidden" }}
                                >
                                    <div className="education-card line-clamp-container mb-1">
                                        <div
                                            className="line-clamp fw-bold font-grey01"
                                            style={{ fontSize: 15 }}
                                        >
                                            {item.articleTitle}
                                        </div>
                                    </div>
                                    <div
                                        className="education-card line-clamp-container"
                                        style={{ lineHeight: 1.25 }}
                                    >
                                        <div className="line-clamp fz-14">
                                            {item.content}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex align-items-start justify-content-between mt-3 fz-12">
                                {item.timestamp && (
                                    <div
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {formatISOtoShortMonthDate(
                                            item.timestamp
                                        )}
                                    </div>
                                )}
                                <div className="d-flex align-items-center gap-1">
                                    Source:{" "}
                                    {item.source === "Oral B" ? (
                                        <img
                                            alt="Oral B"
                                            src={oralB_logo}
                                            style={sourceImgStyles}
                                        />
                                    ) : item.source === "Crest" ? (
                                        <img
                                            alt="Crest"
                                            src={Crest_logo}
                                            style={sourceImgStyles}
                                        />
                                    ) : (
                                        item.source
                                    )}
                                </div>
                                <div className="font-purple text-underline">
                                    View Full Article
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Education;
