import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Swal from "sweetalert2";

import "./FamilyMemberInformation.scss";
import { avatarUrl } from "../../api/apiRoot";
import CardLoader from "../../components/loaders/CardLoader";
import { ApiCalls } from "../../api/allApiCalls";
import NewFamilyMemberForm from "./NewFamilyMemberForm";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";

const iconStyle = {
    color: "#681c9a",
    width: 22,
    height: 22,
    marginBottom: 2,
    marginRight: 5,
};

const initialNewMember = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    message: "",
};

const ADDING = "Adding new member";

function FamilyMemberInformation() {
    const {
        masterId,
        userId,
        practiceId,
        custId,
        patientId,
        firstName,
        lastName,
    } = useSelector((state) => state.userInfo);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newMember, setNewMember] = useState(initialNewMember);
    const [state, setState] = useState(null);
    const [updateData, setUpdateData] = useState(false);

    useEffect(() => {
        const fetchGuarantorData = async () => {
            try {
                setLoading(true);
                const res = await ApiCalls.authGetAPI(
                    "/v2/Patient/FamilyMemberData",
                    {
                        masterId,
                        userId,
                        practiceId,
                        custId,
                        patientId,
                    }
                );

                const familyMemberList = [];
                res?.forEach((i) => {
                    if (!(i.firtName === firstName && i.lastName === lastName))
                        familyMemberList.push(i);
                });
                setData(familyMemberList);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        fetchGuarantorData();
    }, [updateData]);

    const resetNewMemberForm = () => {
        setNewMember(initialNewMember);
        setState(null);
    };

    const saveNewMember = async () => {
        // check if FN or LN is missing
        // save new member in DB
        // close Form
        // Update list
        // reset form
        if (!newMember.firstName || !newMember.lastName) {
            Swal.fire({
                icon: "warning",
                text: "First name and last name are required.",
            });
            return;
        }

        try {
            setLoading(true);
            const res = await ApiCalls.authPostAPI(
                "/v2/Patient/FamilyMemberData",
                {
                    masterId,
                    userId,
                    custId,
                    practiceId,
                    patientId,
                    firstName: newMember.firstName,
                    lastName: newMember.lastName,
                    email: newMember.email,
                    phoneNumber: newMember.phoneNumber,
                    address: newMember.address,
                    message: "",
                }
            );

            if (!res.isSuccess) {
                Swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again later.",
                });
            }
            trackAcvitity(
                itemType.USER_PROFILE,
                itemName.ADD_NEW_FAMILY_MEMBER,
                "User Profile page >> Click Plus icon >> Fill new member info >> Click 'Save New Family Member' button"
            );
        } catch (err) {
            console.log(err);
        } finally {
            resetNewMemberForm();
            setLoading(false);
            setUpdateData(!updateData);
        }
    };

    const handleRemove = async (fmToRemove) => {
        try {
            setLoading(true);
            const res = await ApiCalls.authDeleteAPI(
                "/v2/Patient/FamilyMemberData",
                {
                    masterId,
                    userId,
                    uniqueId: fmToRemove.uniqueId,
                    patientId,
                    practiceId,
                }
            );

            if (!res.isSuccess || res.message !== "successful") {
                Swal.fire({
                    text: "Something went wrong. Please try again later",
                });
            }
            setUpdateData(!updateData);
            trackAcvitity(
                itemType.USER_PROFILE,
                itemName.REMOVE_FAMILY_MEMBER,
                "User Profile page >> Click on Remove icon to remove an existing family member"
            );
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="user-profile__family-members">
            <div className="mb-2 d-flex justify-content-between">
                <h3 className="fz-16 fw-bold">Family Member/s Information</h3>

                {!loading && (
                    <div className="d-flex align-items-center">
                        {!state && (
                            <div className="d-flex align-items-center">
                                <AddCircleOutlineIcon
                                    style={iconStyle}
                                    role="button"
                                    onClick={() => {
                                        setState(ADDING);
                                        trackAcvitity(
                                            itemType.USER_PROFILE,
                                            itemName.CLICK_ADD_NEW_FAMILY_MEMBER,
                                            "User Profile page >> Click on Plus icon in order to add a new Family Member"
                                        );
                                    }}
                                />
                                Add
                            </div>
                        )}
                    </div>
                )}
            </div>

            {loading ? (
                <div
                    className="position-relative height-100p width-100p"
                    style={{ height: 80 }}
                >
                    <CardLoader />
                </div>
            ) : (
                <>
                    <div className="family-member-info">
                        {data.map((item, i) => (
                            <div
                                key={i}
                                className="family-member-info-container"
                            >
                                <img
                                    className="me-3 border-radius-100p mt-1"
                                    alt="user avatar"
                                    src={avatarUrl(item.firstName)}
                                    style={{
                                        height: 48,
                                        width: 48,
                                    }}
                                />
                                <div className="family-member-flex flex-1">
                                    <div className="family-member-flex-item">
                                        <label>First Name</label>
                                        <input
                                            name="firstName"
                                            disabled={true}
                                            value={item.firstName}
                                        />
                                    </div>
                                    <div className="family-member-flex-item">
                                        <label>Last Name</label>
                                        <input
                                            name="lastName"
                                            disabled={true}
                                            value={item.lastName}
                                        />
                                    </div>
                                    <div className="family-member-flex-item">
                                        <label>Email</label>
                                        <input
                                            name="email"
                                            disabled={true}
                                            value={item.email}
                                        />
                                    </div>
                                    <div className="family-member-flex-item">
                                        <label>Phone Number</label>
                                        <input
                                            name="phone"
                                            disabled={true}
                                            value={item.phone}
                                        />
                                    </div>
                                    <div className="family-member-flex-item d-flex align-items-center gap-3">
                                        <div>
                                            <label>Address</label>
                                            <input
                                                name="address"
                                                disabled={true}
                                                value={item.address}
                                            />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label style={{ opacity: 0 }}>
                                                R
                                            </label>
                                            <RemoveCircleOutlineIcon
                                                className="font-purple cursor-pointer"
                                                onClick={() =>
                                                    handleRemove(item)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* NEW MEMBER FORM */}
                        {state === ADDING && (
                            <div className="new-member-form-container d-flex align-items-center mb-3">
                                <NewFamilyMemberForm
                                    newMember={newMember}
                                    setNewMember={setNewMember}
                                />
                            </div>
                        )}
                    </div>

                    <div className="save-changes-buttons-container align-items-center">
                        {state === ADDING && (
                            <>
                                <button
                                    className="button button-primary"
                                    onClick={saveNewMember}
                                >
                                    Save New Family Member
                                </button>
                                <button
                                    className="button button-secondary"
                                    onClick={resetNewMemberForm}
                                >
                                    Discard
                                </button>
                            </>
                        )}
                    </div>

                    {!state && data.length === 0 && (
                        <div className="text-italic mt-3">
                            No record of family member available
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default FamilyMemberInformation;
