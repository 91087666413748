import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import { ApiCalls } from "../../api/allApiCalls";
import "./PracticeInformation.scss";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import CardLoader from "../../components/loaders/CardLoader";
import EditIcon from "../../assets/images/EditIcon.svg";
import { VETERINARY } from "../../utils";
const initialValues = {
	userName: "",
	password: "",
	practiceName: "",
	practiceNumber: "",
	practiceAddress: "",
};

const secondaryInitValues = {
	practiceName: "",
	practiceNumber: "",
	practiceAddr: "",
};

function PracticeInformation() {
	const {
		masterId,
		userId,
		patientId,
		practiceId,
		custId,
		guarId,
		specialty,
	} = useSelector((state) => state.userInfo);

	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({ initialValues });
	const [practiceData, setPracticeData] = useState({ initialValues });

	// Secondary practice states
	const [secondaryLoading, setSecondaryLoading] = useState(true);
	const [secondaryData, setSecondaryData] = useState(secondaryInitValues);
	const [secondaryFormData, setSecondaryFormData] =
		useState(secondaryInitValues);
	const [isSecondaryEditing, setIsSecondaryEditing] = useState(false);
	const [updateSecondary, setUpdateSecondary] = useState(false);

	useEffect(() => {
		const getSettingsProfileData = async () => {
			try {
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/SettingsProfileData",
					{
						masterId,
						userId,
						practiceId,
						patientId,
					}
				);

				const dataToStore = {
					...res[0],
				};
				setPracticeData(dataToStore);
				setData(dataToStore);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
				setIsEditing(false);
			}
		};
		getSettingsProfileData();
	}, []);

	// Fetch Secondary Data
	useEffect(() => {
		const getSecondaryData = async () => {
			try {
				setSecondaryLoading(true);
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/EmergencyPracticeData",
					{
						masterId,
						userId,
						practiceId,
						patientId,
					}
				);

				if (res?.length > 0) {
					setSecondaryData(res[0]);
					setSecondaryFormData(res[0]);
				} else {
					setSecondaryData(initialValues);
					setSecondaryFormData(initialValues);
				}
			} catch (err) {
				console.log;
			} finally {
				setSecondaryLoading(false);
			}
		};

		getSecondaryData();
	}, [updateSecondary]);

	const handleOnchange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setPracticeData({ ...practiceData, [name]: value });
	};

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleCancel = () => {
		setIsEditing(false);
		setPracticeData(data);
	};

	const handlePracticeInformation = async () => {
		try {
			const res = await ApiCalls.authPutAPI(
				"/v2/Patient/SettingsProfileData",
				{
					masterId,
					userId,
					patientId,
					practiceId,
					userName: practiceData.userName,
					password: practiceData.password,
					practiceName: practiceData.practiceName,
					practiceNumber: practiceData.practiceNumber,
					practiceAddress: practiceData.practiceAddress,
				}
			);
			if (res.isSuccess && res.message === "Successful") {
				Swal.fire({
					text: "Practice Information has been updated Successfully!",
					icon: "success",
				});
			} else {
				Swal.fire({
					text: "Something wrong happened. Please try again later",
					icon: "error",
				});
			}
			trackAcvitity(
				itemType.PRACTICE_INFO,
				itemName.UPDATE_PRACTICE_INFO,
				"Profile & Setting page >> Practice Information>> Fill the Practice name,number and address >> Click 'Save Changes' Button "
			);
		} catch (err) {
			console.log(err);
		} finally {
			setIsEditing(false);
		}
	};

	const handleStartEditingSecondaryData = () => {
		setIsSecondaryEditing(true);
	};

	const handleChangeSecondary = (e) => {
		setSecondaryFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSaveSecondary = async () => {
		try {
			setSecondaryLoading(true);
			const res = await ApiCalls.authPostAPI(
				"/v2/Patient/EmergencyPracticeData",
				{
					masterId,
					userId,
					patientId,
					practiceId,
					custId,
					guarId,
					practiceName: secondaryFormData.practiceName,
					practiceNumber: secondaryFormData.practiceNumber,
					practiceAddr: secondaryFormData.practiceAddr,
				}
			);

			if (res?.isSuccess && res?.message === "Successful") {
				setUpdateSecondary(!updateSecondary);
			} else {
				Swal.fire({
					icon: "error",
					text: "Something went wrong. Please try again later.",
				});
			}
		} catch (err) {
			console.log;
		} finally {
			setSecondaryLoading(false);
			setIsSecondaryEditing(false);
		}
	};

	const handleCancelSecondary = () => {
		setSecondaryFormData({ ...secondaryData });
		setIsSecondaryEditing(false);
	};

	return (
		<div>
			<div className="profile-header">
				<h2>Practice Information</h2>

				{specialty !== VETERINARY && (
					<div
						className="d-flex align-items-center gap-2"
						hidden={isEditing ? true : false}
						role="button"
						onClick={handleEdit}
					>
						<img src={EditIcon} alt="Edit" style={{ height: 20 }} />
						<div>Edit</div>
					</div>
				)}
			</div>

			{/* PRIMARY */}
			<div style={{ marginBottom: 16 }} className="position-relative">
				{loading && (
					<div
						className="position-absolute"
						style={{ top: 0, left: 0, right: 0, bottom: 0 }}
					>
						<CardLoader />
					</div>
				)}

				{specialty === VETERINARY && (
					<div className="d-flex align-items-center gap-3 mb-3">
						<h4 className="fz-18 fw-bold">Primary Practice</h4>
						<img
							src={EditIcon}
							alt="Edit"
							style={{ height: 20, marginBottom: 8 }}
							role="button"
							onClick={handleEdit}
							hidden={isEditing ? true : false}
						/>
					</div>
				)}

				<div className="practiceInformation-input-form">
					<div className="input-container">
						<div>
							<label>Practice Name</label>
							<div className="input-description">
								Enter full name of your doctor
							</div>
						</div>
						<div>
							<input
								placeholder="Full Name"
								disabled={isEditing ? false : true}
								value={practiceData.practiceName}
								name="practiceName"
								onChange={handleOnchange}
							/>
						</div>
					</div>
					<div className="input-container">
						<div>
							<label>Practice Number</label>
							<div className="input-description">
								Enter country code & number of your practice
							</div>
						</div>
						<div>
							<input
								placeholder="Phone number"
								disabled={isEditing ? false : true}
								value={practiceData.practiceNumber}
								name="practiceNumber"
								onChange={handleOnchange}
							/>
						</div>
					</div>
					<div className="input-container">
						<div>
							<label>Practice Address</label>
							<div className="input-description">
								Manage your account settings and preferences
							</div>
						</div>
						<div>
							<input
								placeholder="Practice Address Goes Here"
								disabled={isEditing ? false : true}
								value={practiceData.practiceAddress}
								name="practiceAddress"
								onChange={handleOnchange}
							/>
						</div>
					</div>
				</div>
				{isEditing && (
					<div className="d-flex gap-3" style={{ marginTop: 18 }}>
						<button
							className="button button-primary"
							onClick={handlePracticeInformation}
						>
							Save Changes
						</button>
						<button
							className="button button-secondary"
							onClick={handleCancel}
						>
							Cancel
						</button>
					</div>
				)}
			</div>

			{/* SECONDARY */}
			{specialty === VETERINARY && (
				<div style={{ marginTop: 32 }} className="position-relative">
					{secondaryLoading && (
						<div
							className="position-absolute"
							style={{ top: 0, left: 0, right: 0, bottom: 0 }}
						>
							<CardLoader />
						</div>
					)}

					<div className="d-flex align-items-center gap-3 mb-3">
						<h4 className="fz-18 fw-bold">
							Secondary / Emergency Practice
						</h4>
						<img
							src={EditIcon}
							alt="Edit"
							style={{ height: 20, marginBottom: 7 }}
							role="button"
							onClick={handleStartEditingSecondaryData}
							hidden={isSecondaryEditing ? true : false}
						/>
					</div>

					<div className="practiceInformation-input-form">
						<div className="input-container">
							<div>
								<label>Practice Name</label>
								<div className="input-description">
									Enter full name of your doctor
								</div>
							</div>
							<div>
								<input
									placeholder="Full Name"
									disabled={isSecondaryEditing ? false : true}
									value={secondaryFormData.practiceName}
									name="practiceName"
									onChange={handleChangeSecondary}
								/>
							</div>
						</div>
						<div className="input-container">
							<div>
								<label>Practice Number</label>
								<div className="input-description">
									Enter country code & number of your practice
								</div>
							</div>
							<div>
								<input
									placeholder="Phone number"
									disabled={isSecondaryEditing ? false : true}
									value={secondaryFormData.practiceNumber}
									name="practiceNumber"
									onChange={handleChangeSecondary}
								/>
							</div>
						</div>
						<div className="input-container">
							<div>
								<label>Practice Address</label>
								<div className="input-description">
									Manage your account settings and preferences
								</div>
							</div>
							<div>
								<input
									placeholder="Practice Address Goes Here"
									disabled={isSecondaryEditing ? false : true}
									value={secondaryFormData.practiceAddr}
									name="practiceAddr"
									onChange={handleChangeSecondary}
								/>
							</div>
						</div>
					</div>
					{isSecondaryEditing && (
						<div className="d-flex gap-3" style={{ marginTop: 18 }}>
							<button
								className="button button-primary"
								onClick={handleSaveSecondary}
							>
								Save Changes
							</button>
							<button
								className="button button-secondary"
								onClick={handleCancelSecondary}
							>
								Cancel
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default PracticeInformation;
