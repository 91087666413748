import { createSlice } from "@reduxjs/toolkit";

export const divLoaderSlice = createSlice({
	name: "loader",
	initialState: {
		loading: true,
	},
	reducers: {
		setDivLoaderStatus: (state, action) => {
			state.loading = action.payload; // payload should be boolean
		},
	},
});

export const { setDivLoaderStatus } = divLoaderSlice.actions;

export default divLoaderSlice.reducer;
