import React, { useEffect, useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import styled from "styled-components";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import { RotatingLines } from "react-loader-spinner";

import { avatarUrl } from "../../api/apiRoot";
import { useDispatch, useSelector } from "react-redux";
import { ApiCalls } from "../../api/allApiCalls";
import Swal from "sweetalert2";
import { addPetList } from "../../states/petInfoSlice";
import { browserStore, convertBase64, setPetList } from "../../utils";
import CardLoader from "../../components/loaders/CardLoader";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import EditIcon from "../../assets/images/EditIcon.png";

const StyledPetInformation = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	.pet-info-inputs {
		width: 100%;
		display: flex;
		row-gap: 18px;
		column-gap: 24px;
		justify-content: space-between;
	}

	.petName,
	.species,
	.breed {
		flex: 1;
	}

	.pet-image-edit-popover {
		span.MuiGrid-root {
			padding: 0 0.75rem;
			&:hover {
				// background-color: #fef9ff;
				background-color: red !important;
			}
		}
	}

	@media screen and (max-width: 920px) {
		.pet-info-inputs {
			flex-wrap: wrap;
			justify-content: flex-start;
		}

		.petName,
		.species,
		.breed {
			// flex: unset;
			min-width: 200px;
			max-width: unset;
		}

		.age,
		.gender {
		}
	}

	@media screen and (max-width: 600px) {
		.pet-info-container {
			row-gap: 38px !important;
		}

		.input-container {
			flex: unset;
			width: 100%;
			min-width: unset;
			max-width: unset;
		}
	}
`;

function PetInformation() {
	const { masterId, userId, custId, practiceId, guarId } = useSelector(
		(state) => state.userInfo
	);
	const { petList } = useSelector((state) => state.petInfo);
	const dispatch = useDispatch();

	const [pets, setPets] = useState(petList);
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState({});
	const [imageLoading, setImageLoading] = useState(
		Array(petList.length).fill(false)
	);

	const getPets = () => {
		const petsToStore = petList.map((p) => {
			let gender = "Male";
			if (p.gender.toLowerCase().includes("f")) {
				gender = "Female";
			}
			return {
				...p,
				gender,
			};
		});
		setPets(petsToStore);
	};

	useEffect(() => {
		getPets();
	}, [petList]);

	const handleChange = (e, id) => {
		setPets((prev) =>
			[...prev].map((p) => {
				if (p.patientId === id) {
					return { ...p, [e.target.name]: e.target.value };
				} else {
					return p;
				}
			})
		);
	};

	const handleClosePopover = () => {
		setAnchorEl({});
	};

	const handleOpenPopover = (event, petId) => {
		setAnchorEl({ [`petId${petId}`]: event.currentTarget });
	};

	const updatePetList = async () => {
		try {
			const petData = await ApiCalls.authGetAPI("/v2/Patient/PetData", {
				masterId,
				userId,
				practiceId,
				guarId,
			});

			const petDataToStore = setPetList(petData);
			browserStore.set("petList", petDataToStore); // Store data in sessionStorage
			dispatch(addPetList(petDataToStore));
		} catch (err) {
			console.log(err);
		}
	};

	const handleSave = async () => {
		// check which pets have been modified
		const modifiedPets = [];
		pets.forEach((p, idx) => {
			const gender = petList[idx].gender.toLowerCase().includes("f")
				? "Female"
				: "Male";
			// // compare each field to see if there is any difference
			if (
				p.name !== petList[idx].name ||
				p.species !== petList[idx].species ||
				p.breed !== petList[idx].breed ||
				p.age !== petList[idx].age ||
				p.gender !== gender
			) {
				modifiedPets.push(p);
			}
		});

		if (modifiedPets.length > 0) {
			// Check if pet name is empty
			let isValid = true;
			modifiedPets.forEach((p) => {
				if (!p.name.trim() || !p.species.trim()) {
					isValid = false;
				}
			});
			if (!isValid) {
				Swal.fire({
					icon: "warning",
					text: "Pet name and species are required!",
				});
				return;
			}

			// Call API
			try {
				setLoading(true);
				const res = await Promise.all(
					modifiedPets.map((p) => {
						return ApiCalls.authPutAPI("/v2/Patient/PetData", {
							masterId,
							userId,
							custId,
							practiceId,
							patientId: p.patientId,
							guarId,
							name: p.name.trim(),
							species: p.species.trim(),
							breed: p.breed.trim(),
							age: p.age.trim(),
							gender: p.gender,
						});
					})
				);
				// Update petList
				const updatedData = await ApiCalls.authGetAPI(
					"/v2/Patient/PetData",
					{
						masterId,
						userId,
						practiceId,
						guarId,
					}
				);

				const petDataToStore = setPetList(updatedData);
				dispatch(addPetList(petDataToStore));
				browserStore.set("petList", petDataToStore);

				const success = res.map((obj) => obj.isSuccess);
				if (success.includes(false)) {
					Swal.fire({
						icon: "error",
						text: "Something went wrong. All changes might not be recorded. Please try again later.",
					});
				}
				trackAcvitity(
					itemType.SETTINGS,
					itemName.EDIT_PET_INFO,
					"Profile&Settings >> Edit Pet's Information >> Save changes [petID: " +
						modifiedPets.map((p) => p.patientId).join(",") +
						"]"
				);
			} catch (err) {
				console.log(err);
			} finally {
				setIsEditing(false);
				setLoading(false);
			}
		} else {
			setIsEditing(false);
		}
	};

	const handleImageChange = (pet, e, index) => {
		const imageFile = e.target.files[0];

		if (imageFile) {
			const reader = new FileReader();
			reader.readAsDataURL(imageFile);

			if (
				imageFile?.type !== "image/png" &&
				imageFile?.type !== "image/jpg" &&
				imageFile?.type !== "image/jpeg"
			) {
				handleClosePopover();
				Swal.fire({
					icon: "error",
					text: "Uploaded file should be in Jpg, Png or Jpeg format",
				});
				return;
			}

			reader.onload = async (e) => {
				const base64String = convertBase64(e.target.result);

				// update url
				try {
					setImageLoading((prev) =>
						[...prev].map((item, idx) => {
							if (idx === index) {
								return true;
							}
							return item;
						})
					);

					const found = petList.find(
						(p) => p.patientId === pet.patientId
					);
					if (found && found?.pictureUrl) {
						// First, delete if there is an existing image
						const deleteRes = await ApiCalls.authDeleteAPI(
							"/v2/Patient/PetProfilePicture",
							{
								uniqueId: found.uniqueId,
								masterId,
								userId,
								patientId: found.patientId,
								practiceId: found.practiceId,
							}
						);

						if (
							!deleteRes.isSuccess ||
							deleteRes.message.toLowerCase() !== "successful"
						) {
							Swal.fire({
								icon: "error",
								text: "Something went wrong. Please try again later.",
							});
							return;
						}
					}

					const res = await ApiCalls.authPostAPI(
						"/v2/Patient/PetProfilePicture",
						{
							masterId,
							userId,
							practiceId: pet.practiceId,
							custId: pet.custId,
							patientId: pet.patientId,
							imageName: imageFile?.name,
							imageBase64Data: base64String,
						}
					);

					if (res.isSuccess && res.message === "Successful") {
						// update petList
						await updatePetList(index);
					} else {
						handleClosePopover();
						Swal.fire({
							icon: "error",
							text: "Something went wrong. Please try again later.",
						});
					}
					trackAcvitity(
						itemType.USER_PROFILE,
						itemName.UPLOAD_PET_PICTURE,
						`Profile & Settings >> Uploaded a pet picture [patient pet Id ${pet.patientId}]`
					);
				} catch (err) {
					console.log(err);
				} finally {
					handleClosePopover();
					setImageLoading((prev) =>
						[...prev].map((item, idx) => {
							if (idx === index) {
								return false;
							}
							return item;
						})
					);
				}
			};
		}
	};

	const handleUpload = (petId) => {
		const inputFile = document.getElementById(
			`petinfo-upload-input-petId-${petId}`
		);
		inputFile.click();
	};

	const handleDelete = async (pet, index) => {
		try {
			setImageLoading((prev) =>
				[...prev].map((item, idx) => {
					if (idx === index) {
						return true;
					}
					return item;
				})
			);

			const res = await ApiCalls.authDeleteAPI(
				"/v2/Patient/PetProfilePicture",
				{
					uniqueId: pet.uniqueId,
					masterId,
					userId,
					patientId: pet.patientId,
					practiceId: pet.practiceId,
				}
			);

			if (res.isSuccess && res.message.toLowerCase() === "successful") {
				// update petList
				await updatePetList();
			} else {
				Swal.fire({
					icon: "error",
					text: "Something went wrong. Please try again later.",
				});
			}
			trackAcvitity(
				itemType.USER_PROFILE,
				itemName.DELELTE_PET_PICTURE,
				`Profile & Settings >> Deleted a pet picture [patient pet Id ${pet.patientId}]`
			);
		} catch (err) {
			console.log(err);
		} finally {
			handleClosePopover();
			setImageLoading((prev) =>
				[...prev].map((item, idx) => {
					if (idx === index) {
						return false;
					}
					return item;
				})
			);
		}
	};

	return (
		<StyledPetInformation>
			<div className="profile-header">
				<h2>Pet&apos;s Information</h2>
				{!isEditing && !loading && (
					<div
						className="d-flex align-items-center"
						role="button"
						onClick={() => setIsEditing(true)}
					>
						<BorderColorIcon
							style={{
								color: "#681c9a",
								fontSize: 18,
								marginRight: 8,
							}}
						/>
						Edit
					</div>
				)}
			</div>
			<div
				className="d-flex flex-wrap position-relative"
				style={{ gap: 32 }}
			>
				{loading && <CardLoader />}
				<div className="d-flex flex-column gap-4 width-100p pet-info-container">
					{pets.map((pet, idx) => (
						<div key={pet.patientId} className="pet-info-inputs">
							<div className="position-relative petinfo-avatar">
								{/* PET AVATAR */}
								<img
									style={{
										borderRadius: "100%",
										width: 64,
										height: 64,
										marginBottom: 5,
										border: "solid 1px #F6F6F6",
									}}
									src={
										pet.pictureUrl
											? pet.pictureUrl
											: avatarUrl(pet.name)
									}
								/>

								{/* EDIT ICON */}
								<div
									role="button"
									className="petinfo-editBtn position-absolute d-flex justify-content-center align-items-center"
									style={{
										borderRadius: "100%",
										backgroundColor: "#ffffff",
										width: 29,
										height: 29,
										bottom: -1,
										right: -6,
										border: "solid 1px #F6F6F6",
										cursor: "pointer",
									}}
									onClick={(e) =>
										handleOpenPopover(e, pet.patientId)
									}
								>
									<img
										src={EditIcon}
										style={{
											width: 19,
											height: 19,
										}}
									/>
								</div>

								<Popover
									id={
										anchorEl[`petId${pet.patientId}`]
											? "simple-popover"
											: undefined
									}
									open={Boolean(
										anchorEl[`petId${pet.patientId}`]
									)}
									anchorEl={anchorEl[`petId${pet.patientId}`]}
									onClose={handleClosePopover}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
								>
									<Typography
										sx={{ p: 2 }}
										className="px-0 py-1"
									>
										<Grid
											component="span"
											className="d-flex flex-column fz-14 pet-image-edit-popover"
										>
											<Grid
												component="span"
												role="button"
												onClick={() =>
													handleUpload(pet.patientId)
												}
											>
												{pet.pictureUrl
													? "Update Picture"
													: "Upload"}
											</Grid>

											{pet.pictureUrl && (
												<Grid
													component="span"
													role="button"
													onClick={() =>
														handleDelete(pet, idx)
													}
												>
													Delete
												</Grid>
											)}
										</Grid>
									</Typography>
								</Popover>

								<input
									type="file"
									id={`petinfo-upload-input-petId-${pet.patientId}`}
									accept="image/*"
									onChange={(event) =>
										handleImageChange(pet, event, idx)
									}
									style={{
										display: "none", // Make the input element invisible
									}}
									title=""
									onClick={(event) => {
										event.currentTarget.value = null;
									}}
								/>

								{imageLoading[idx] && (
									<div
										className="position-absolute"
										style={{
											top: "50%",
											left: "50%",
											transform: "translate(-50%, -50%)",
										}}
									>
										<RotatingLines
											strokeColor="#ffffff"
											strokeWidth="5"
											animationDuration="0.75"
											width="20"
											visible={true}
										/>
									</div>
								)}
							</div>

							<div className="input-container petName">
								<label>Pet Name</label>
								<input
									name="name"
									value={pet.name}
									onChange={(e) =>
										handleChange(e, pet.patientId)
									}
									disabled={!isEditing}
								/>
							</div>
							<div className="input-container species">
								<label>Species</label>
								<input
									name="species"
									value={pet.species}
									onChange={(e) =>
										handleChange(e, pet.patientId)
									}
									disabled={!isEditing}
								/>
							</div>
							<div className="input-container breed">
								<label>Breed</label>
								<input
									name="breed"
									value={pet.breed}
									onChange={(e) =>
										handleChange(e, pet.patientId)
									}
									disabled={!isEditing}
								/>
							</div>
							<div className="input-container age">
								<label>Age (in years)</label>
								<input
									name="age"
									onChange={(e) =>
										handleChange(e, pet.patientId)
									}
									value={pet.age}
									disabled={!isEditing}
								/>
							</div>
							<div className="input-container gender">
								<label>Gender</label>
								<select
									name="gender"
									value={pet.gender}
									onChange={(e) =>
										handleChange(e, pet.patientId)
									}
									disabled={!isEditing}
								>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
								</select>
							</div>
						</div>
					))}
				</div>
				{isEditing && (
					<div
						className="d-flex gap-3"
						style={{
							height: 36,
							alignSelf: "flex-end",
						}}
					>
						<button
							className="button button-primary"
							type="submit"
							onClick={handleSave}
						>
							Save Changes
						</button>
						<button
							className="button button-secondary"
							onClick={() => {
								getPets();
								setIsEditing(false);
							}}
						>
							Cancel
						</button>
					</div>
				)}
			</div>
		</StyledPetInformation>
	);
}

export default PetInformation;
