import React from "react";
import { RotatingLines } from "react-loader-spinner";

function ButtonLoader() {
	return (
		<RotatingLines
			strokeColor="#ffffff"
			strokeWidth="3"
			animationDuration="0.75"
			width="24"
		/>
	);
}

export default ButtonLoader;
