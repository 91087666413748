import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import { RotatingLines } from "react-loader-spinner";

import "./PetSelect.scss";
import {
    DENTAL,
    VETERINARY,
    browserStore,
    convertBase64,
    setPetList,
    storeSelectedPet,
} from "../../utils";
import { addPetList, updateSelectedPet } from "../../states/petInfoSlice";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";
import { avatarUrl } from "../../api/apiRoot";
import EditIcon from "../../assets/images/EditIcon.png";
import { ApiCalls } from "../../api/allApiCalls";
import Swal from "sweetalert2";

function PetSelect() {
    const { masterId, userId, practiceId, guarId, specialty } = useSelector(
        (state) => state.userInfo
    );
    const { petList, selectedPet } = useSelector((state) => state.petInfo);

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState({});
    const [loading, setLoading] = useState(Array(petList.length).fill(false));

    const handleClosePopover = () => {
        setAnchorEl({});
    };

    const handleOpenPopover = (event, petId) => {
        setAnchorEl((prev) => ({
            ...prev,
            [petId]: event.currentTarget,
        }));
    };

    useEffect(() => {
        if (specialty === DENTAL) return;
        if (!selectedPet) {
            dispatch(updateSelectedPet(petList[0]));
            storeSelectedPet(petList[0]);
        }
    }, []);

    const onPetSelect = (petId) => {
        const found = petList.find((p) => p.patientId === petId);
        if (found) {
            dispatch(updateSelectedPet(found));
            storeSelectedPet(found); // store to sessionStorage
            trackAcvitity(
                itemType.PET,
                itemName.PET_SELECTION,
                "Click on a pet avatar [PetID " + found.patientId + "]"
            );
        }
    };

    const updatePetList = async () => {
        try {
            const petData = await ApiCalls.authGetAPI("/v2/Patient/PetData", {
                masterId,
                userId,
                practiceId,
                guarId,
            });

            const petDataToStore = setPetList(petData);
            browserStore.set("petList", petDataToStore); // Store data in sessionStorage
            dispatch(addPetList(petDataToStore));
        } catch (err) {
            console.log(err);
        }
    };

    const handleImageChange = (pet, e, index) => {
        const imageFile = e.target.files[0];

        if (imageFile) {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);

            if (
                imageFile?.type !== "image/png" &&
                imageFile?.type !== "image/jpg" &&
                imageFile?.type !== "image/jpeg"
            ) {
                handleClosePopover();
                Swal.fire({
                    icon: "error",
                    text: "Uploaded file should be in Jpg, Png or Jpeg format",
                });
                return;
            }

            reader.onload = async (e) => {
                const base64String = convertBase64(e.target.result);

                // update url
                try {
                    setLoading((prev) =>
                        [...prev].map((item, idx) => {
                            if (idx === index) {
                                return true;
                            }
                            return item;
                        })
                    );

                    const found = petList.find(
                        (p) => p.patientId === pet.patientId
                    );
                    if (found && found?.pictureUrl) {
                        // First, delete if there is an existing image
                        const deleteRes = await ApiCalls.authDeleteAPI(
                            "/v2/Patient/PetProfilePicture",
                            {
                                uniqueId: found.uniqueId,
                                masterId,
                                userId,
                                patientId: found.patientId,
                                practiceId: found.practiceId,
                            }
                        );

                        if (
                            !deleteRes.isSuccess ||
                            deleteRes.message.toLowerCase() !== "successful"
                        ) {
                            Swal.fire({
                                icon: "error",
                                text: "Something went wrong. Please try again later.",
                            });
                            return;
                        }
                    }

                    const res = await ApiCalls.authPostAPI(
                        "/v2/Patient/PetProfilePicture",
                        {
                            masterId,
                            userId,
                            practiceId: pet.practiceId,
                            custId: pet.custId,
                            patientId: pet.patientId,
                            imageName: imageFile?.name,
                            imageBase64Data: base64String,
                        }
                    );

                    if (res.isSuccess && res.message === "Successful") {
                        // update petList
                        await updatePetList(index);
                    } else {
                        handleClosePopover();
                        Swal.fire({
                            icon: "error",
                            text: "Something went wrong. Please try again later.",
                        });
                    }
                    trackAcvitity(
                        itemType.USER_PROFILE,
                        itemName.UPLOAD_PET_PICTURE,
                        `Uploaded a pet picture [Pet Patient ID ${pet.patientId}]`
                    );
                } catch (err) {
                    console.log(err);
                } finally {
                    handleClosePopover();
                    setLoading((prev) =>
                        [...prev].map((item, idx) => {
                            if (idx === index) {
                                return false;
                            }
                            return item;
                        })
                    );
                }
            };
        }
    };

    const handleUpload = (petId) => {
        const inputFile = document.getElementById(
            `upload-input-petId-${petId}`
        );
        inputFile.click();
    };

    const handleDelete = async (pet, index) => {
        try {
            setLoading((prev) =>
                [...prev].map((item, idx) => {
                    if (idx === index) {
                        return true;
                    }
                    return item;
                })
            );

            const res = await ApiCalls.authDeleteAPI(
                "/v2/Patient/PetProfilePicture",
                {
                    uniqueId: pet.uniqueId,
                    masterId,
                    userId,
                    patientId: pet.patientId,
                    practiceId: pet.practiceId,
                }
            );

            if (res.isSuccess && res.message.toLowerCase() === "successful") {
                // update petList
                await updatePetList();
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again later.",
                });
            }
            trackAcvitity(
                itemType.USER_PROFILE,
                itemName.DELELTE_PET_PICTURE,
                `Deleted a pet picture [Pet Patient ID ${pet.patientId}]`
            );
        } catch (err) {
            console.log(err);
        } finally {
            handleClosePopover();
            setLoading((prev) =>
                [...prev].map((item, idx) => {
                    if (idx === index) {
                        return false;
                    }
                    return item;
                })
            );
        }
    };

    return (
        <>
            {specialty === VETERINARY && (
                <div
                    className="my-3 bg-white position-relative"
                    id="onboard-last-step-id"
                >
                    <div className="font-gray mb-2 fz-16">Select Pet</div>

                    <div className="d-flex gap-3">
                        {petList.map((pet, idx) => (
                            <div
                                role="button"
                                key={pet.patientId}
                                className="d-flex flex-column align-items-center fz-16"
                            >
                                <div className="position-relative">
                                    {/* PET AVATAR */}
                                    <img
                                        style={{
                                            borderRadius: "100%",
                                            width: 64,
                                            height: 64,
                                            outline: `2px solid #${
                                                selectedPet?.patientId ===
                                                pet.patientId
                                                    ? "681c9a"
                                                    : "ffffff"
                                            }`,
                                            outlineOffset: 3,
                                            marginBottom: 5,
                                            border: "solid 1px #F6F6F6",
                                        }}
                                        src={
                                            pet.pictureUrl
                                                ? pet.pictureUrl
                                                : avatarUrl(pet.name)
                                        }
                                        onClick={() =>
                                            onPetSelect(pet.patientId)
                                        }
                                    />

                                    {/* EDIT ICON */}
                                    <div
                                        role="button"
                                        className="position-absolute d-flex justify-content-center align-items-center"
                                        style={{
                                            borderRadius: "100%",
                                            backgroundColor: "#ffffff",
                                            width: 29,
                                            height: 29,
                                            bottom: -1,
                                            right: -6,
                                            border: "solid 1px #F6F6F6",
                                            cursor: "pointer",
                                        }}
                                        onClick={(e) =>
                                            handleOpenPopover(e, pet.patientId)
                                        }
                                    >
                                        <img
                                            src={EditIcon}
                                            style={{
                                                width: 19,
                                                height: 19,
                                            }}
                                        />
                                    </div>

                                    <Popover
                                        id={
                                            anchorEl[pet.patientId]
                                                ? "simple-popover"
                                                : undefined
                                        }
                                        open={Boolean(anchorEl[pet.patientId])}
                                        anchorEl={anchorEl[pet.patientId]}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        <Typography
                                            sx={{ p: 2 }}
                                            className="px-0 py-1"
                                        >
                                            <Grid
                                                component="span"
                                                className="d-flex flex-column fz-14 pet-image-edit-popover"
                                            >
                                                <Grid
                                                    component="span"
                                                    role="button"
                                                    onClick={() =>
                                                        handleUpload(
                                                            pet.patientId
                                                        )
                                                    }
                                                >
                                                    {pet.pictureUrl
                                                        ? "Update Picture"
                                                        : "Upload"}
                                                </Grid>

                                                {pet.pictureUrl && (
                                                    <Grid
                                                        component="span"
                                                        role="button"
                                                        onClick={() =>
                                                            handleDelete(
                                                                pet,
                                                                idx
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Typography>
                                    </Popover>

                                    <input
                                        type="file"
                                        id={`upload-input-petId-${pet.patientId}`}
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleImageChange(pet, event, idx)
                                        }
                                        style={{
                                            display: "none", // Make the input element invisible
                                        }}
                                        title=""
                                        onClick={(event) => {
                                            event.currentTarget.value = null;
                                        }}
                                    />

                                    {loading[idx] && (
                                        <div
                                            className="position-absolute"
                                            style={{
                                                top: "50%",
                                                left: "50%",
                                                transform:
                                                    "translate(-50%, -50%)",
                                            }}
                                        >
                                            <RotatingLines
                                                strokeColor="#ffffff"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="20"
                                                visible={true}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div style={{ color: "#1E1E1E" }}>
                                    {pet.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default PetSelect;
