import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showNoMatchMessage: false,
	authToken: "",
	email: "",
	cell: "",
	mediaType: "",
	masterId: "",
	custId: "",
	practiceId: "",
	patientId: "",
	maskedEmail: "",
	maskedCell: "",
	authCode: "",
};

export const authSlice = createSlice({
	name: "authentication",
	initialState,
	reducers: {
		storeRecordMatchingStatus: (state, action) => {
			state.recordsMatched = action.payload;
		},
		setShowNoMatchMessage: (state) => {
			state.showNoMatchMessage = true;
		},
		setAuthData: (state, action) => {
			(state.firstName = action.payload.firstName),
				(state.lastName = action.payload.lastName),
				(state.birthDate = action.payload.birthDate),
				(state.ssn = action.payload.ssn),
				(state.authToken = action.payload.authToken),
				(state.maskedEmail = action.payload.maskedEmail),
				(state.base64Data = action.payload.base64Data),
				(state.maskedCell = action.payload.maskedCell);
		},
		updateAuthData: (state, action) => {
			(state.masterId = action.payload.masterId),
				(state.mediaType = action.payload.mediaType),
				(state.practiceId = action.payload.practiceId),
				(state.custId = action.payload.custId),
				(state.patientId = action.payload.patientId),
				(state.email = action.payload.email),
				(state.cell = action.payload.cell);
		},
		addAuthCode: (state, action) => {
			state.authCode = action.payload;
		},
		resetAuth: () => initialState,
	},
});

export const {
	storeRecordMatchingStatus,
	setShowNoMatchMessage,
	setAuthData,
	updateAuthData,
	addAuthCode,
	resetAuth,
} = authSlice.actions;

export default authSlice.reducer;
