import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import "./Verification.scss";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { ApiCalls } from "../../../api/allApiCalls";
import { addAuthCode } from "../../../states/authSlice";
import useSetTitle from "../../../hooks/useSetTitle";

function Verification() {
	const {
		authToken,
		maskedEmail,
		maskedCell,
		mediaType,
		masterId,
		practiceId,
		patientId,
		email,
		cell,
	} = useSelector((state) => state.authentication);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]); // Initial state with 4 empty strings
	const [loading, setLoading] = useState(false);
	const inputRefs = [
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
	]; // Refs for each input element

	useSetTitle("Authentication");

	useEffect(() => {
		// Check if there is authToken && master Id
		if (!authToken || !masterId) {
			navigate("/authentication");
		}
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// if the code is valid, navigate to create-account page

		// Call API
		try {
			setLoading(true);
			const verificationCode = otpValues.join("");
			const res = await ApiCalls.authenticationPOST(
				"/v2/SignUp/VerifyAuthCode",
				{
					authToken,
					masterId,
					verificationCode,
				}
			);

			if (res?.isSuccess && res?.authToken && res?.authCode) {
				dispatch(addAuthCode(res.authCode));
				navigate("/authentication/create-account");
			} else {
				Swal.fire({
					icon: "error",
					text: "Verification Failed. Re-enter code or click the link below if you wish to restart the process",
					footer: `<a href="${document.location.origin}/authentication">Restart</a>`,
				});
				setOtpValues(["", "", "", "", "", ""]);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleResendCode = async () => {
		try {
			setLoading(true);
			const res = await ApiCalls.authenticationPOST(
				"/v2/SignUp/SendVerificationCode",
				{
					authToken,
					masterId,
					practiceId,
					patientId,
					email,
					cell,
					mediaType: mediaType === "Email" ? "email" : "text",
				}
			);

			if (res?.isSuccess && res?.authToken) {
				Swal.fire({
					text: "Verification Code has been re-sent to you",
				});
			} else {
				Swal.fire({
					icon: "error",
					text: "Verification Failed",
					footer: `<a href="${document.location.origin}/authentication/follow-up">Sign up</a><span style="margin-inline: 5px">or</span><a href="${document.location.origin}/authentication">Restart the process</a>`,
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (event, index) => {
		const value = event.target.value;

		if (isNaN(value)) {
			return; // Only allow numeric values
		}

		const newOtpValues = [...otpValues];
		newOtpValues[index] = value;

		setOtpValues(newOtpValues);

		if (value && index < 5) {
			inputRefs[index + 1].current.focus(); // Focus on the next input element
		}
		if (!value && index > 0) {
			inputRefs[index - 1].current.focus(); // Focus on the previous input element if user deleted the current input
		}
	};

	return (
		<div className="authentication-form-page">
			<div className="form-content-wrapper">
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 48 }}
				/>

				<div className="font-purple my-4">
					Verify & authenticate your account
				</div>
				<div className="mb-5">
					Enter the 6-digit verification code sent to <br />
					{mediaType === "Email" ? maskedEmail : maskedCell}
				</div>

				<form
					className="d-flex flex-column"
					onSubmit={handleSubmit}
					style={{ minWidth: 320 }}
				>
					<div className="width-100p d-flex justify-content-between">
						{otpValues.map((value, index) => (
							<input
								key={index}
								required
								value={value}
								onChange={(event) => handleChange(event, index)}
								autoFocus={index === 0 ? true : false}
								maxLength={1}
								style={{
									width: 42,
									height: 42,
									textAlign: "center",
									fontSize: 20,
								}}
								ref={inputRefs[index]}
							/>
						))}
					</div>
					<button
						className="button button-primary mt-3 mb-3"
						disabled={loading ? true : false}
					>
						{loading ? <ButtonLoader /> : "Verify Code"}
					</button>

					<div
						className="resend-code-btn text-underline"
						role="button"
						disabled={loading ? true : false}
						onClick={handleResendCode}
					>
						Resend Code
					</div>
				</form>
			</div>
		</div>
	);
}

export default Verification;
