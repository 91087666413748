import React from "react";
import ReactDOM from "react-dom";
import { RotatingLines } from "react-loader-spinner";
import sikka_logo_transparent from "../../assets/images/sikka_logo_transparent.png";

function LazyLoader() {
	return ReactDOM.createPortal(
		<div
			style={{
				position: "fixed",
				width: "100%",
				height: "100%",
				top: 0,
				right: 0,
				backgroundColor: "#ffffff",
				zIndex: 1000,
				opacity: 1,
			}}
			className="d-flex flex-column justify-content-center align-items-center"
		>
			<img
				src={sikka_logo_transparent}
				alt="sikka.ai"
				style={{ width: "min(200px, 50%)" }}
			/>

			<h1 className="fs-4 mt-4 d-flex align-items-center justify-content-center">
				<RotatingLines
					strokeColor="grey"
					strokeWidth="5"
					animationDuration="0.75"
					width="20"
					visible={true}
				/>
				<span className="ms-2 me-3">Loading Resource</span>
			</h1>
		</div>,
		document.getElementById("overlay")
	);
}

export default LazyLoader;
