import React from "react";

function TreatmenPlansStatusCell(props) {
	const content = props.dataItem[props.field];
	return (
		<td>
			<div
				style={{
					border: `solid 1px ${
						content === "Scheduled" ? "#681c9a" : "#efa10b"
					}`,
					color: content === "Scheduled" ? "#681c9a" : "#efa10b",
					backgroundColor:
						content === "Scheduled" ? "#fef9ff" : "#fefaf3",
					width: "fit-content",
					minWidth: 112,
					minHeight: 32,
					borderRadius: 6,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{content}
			</div>
		</td>
	);
}

export default TreatmenPlansStatusCell;
