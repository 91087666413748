import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";

import "./MakeAppointmentModal.scss";
import Modal from "../../components/modals/Modal";
import { ApiCalls } from "../../api/allApiCalls";
import { useSelector } from "react-redux";
import AppointmentCalendar from "./AppointmentCalendar";
import Comments from "./Comments";
import Swal from "sweetalert2";
import { DENTAL, VETERINARY, formatTime2 } from "../../utils";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";

const initialErrors = {
    doctor: "",
    submit: "",
};

function MakeAppointmentModal({ openModal, closeModal }) {
    const {
        masterId,
        userId,
        patientId,
        practiceId,
        specialty,
        custId,
        firstName,
        lastName,
        emailId,
    } = useSelector((state) => state.userInfo);
    const { selectedPet } = useSelector((state) => state.petInfo);
    console.table(selectedPet);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [showCalendar, setShowCalendar] = useState(false);
    const [treatments, setTreatments] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [appointment, setAppointment] = useState({
        doctor: "",
        treatment: "",
        comments: "",
    });
    const [errors, setErrors] = useState(initialErrors);
    const [slots, setSlots] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Promise.all([
                    ApiCalls.authGetAPI("/v2/Patient/ProcedureCategory", {
                        masterId,
                        userId,
                        practiceId,
                        patientId:
                            specialty === DENTAL
                                ? patientId
                                : selectedPet.patientId,
                        specialty,
                    }),
                    ApiCalls.authGetAPI("/v2/Patient/Providers", {
                        masterId,
                        practiceId,
                        userId,
                        patientId:
                            specialty === DENTAL
                                ? patientId
                                : selectedPet.patientId,
                    }),
                ]);

                setDoctors(res[1]);
                const treatmentList = res[0].map((i) => i.categoryName);
                setTreatments(treatmentList);
                setAppointment((prev) => ({
                    ...prev,
                    treatment: treatmentList[0],
                }));
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        if (specialty === VETERINARY && !selectedPet) return;
        fetchData();
    }, [selectedPet]);

    const handleChange = (e) => {
        setAppointment((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        setSelectedSlots([]);
    };

    const resetErrors = () => {
        setTimeout(() => {
            setErrors(initialErrors);
        }, 3000);
    };

    const handleMakeAppt = async () => {
        if (!showCalendar) {
            if (!appointment.doctor) {
                setErrors((prev) => ({
                    ...prev,
                    doctor: "Please select a doctor.",
                }));
                resetErrors();
            } else {
                setShowCalendar(true);
            }
        } else {
            // validate data
            if (selectedSlots.length === 0) {
                setErrors((prev) => ({
                    ...prev,
                    submit: "Please select a slot.",
                }));
                resetErrors();
            } else {
                // call API to make appt
                try {
                    setLoading(true);

                    // ApptDates: 2023-05-11,2023-06-13
                    const apptDates = selectedSlots.reduce((prev, curr) => {
                        if (!prev.includes(curr.date)) {
                            prev.push(curr.date);
                        }
                        return prev;
                    }, []);
                    const datesTimes = [];

                    selectedSlots.forEach((item) => {
                        const index = datesTimes.findIndex(
                            (i) => i.date === item.date
                        );
                        if (index !== -1) {
                            datesTimes[index] = {
                                ...datesTimes[index],
                                time: [...datesTimes[index].time, item.time],
                            };
                        } else {
                            datesTimes.push({
                                date: item.date,
                                time: [item.time],
                            });
                        }
                    });

                    // ApptDatesTimes: 2023-05-27?9:30,3:00,4:30|2023-05-28?9:30,3:00,4:30|2023-05-29?9:30,3:00,4:30
                    const apptDatesTimes = datesTimes.map((i) => {
                        const timeStr = i.time
                            .map((t) => formatTime2(t))
                            .join(",");
                        return i.date + "?" + timeStr;
                    });

                    const guarantorData = await ApiCalls.authGetAPI(
                        "/v2/Patient/GuarantorData",
                        {
                            masterId,
                            userId,
                            practiceId,
                            custId,
                            patientId:
                                specialty === DENTAL
                                    ? patientId
                                    : selectedPet.patientId,
                        }
                    );

                    let guarantorId = "";

                    if (guarantorData?.length > 0) {
                        guarantorId = guarantorData[0]?.patientId;
                    }

                    // InitialParam: patid|practiceid|custid|providerid|fname|lname|emailorcell|guarid|provider#
                    const initialParam = [
                        specialty === DENTAL
                            ? patientId
                            : selectedPet.patientId,
                        practiceId,
                        custId,
                        appointment.doctor,
                        firstName,
                        lastName,
                        emailId,
                        guarantorId,
                        doctors.find((d) => d.providerId === appointment.doctor)
                            .providerName,
                    ];

                    const res = await ApiCalls.authPostAPI(
                        "/v2/Patient/ScheduleAppointment",
                        {
                            masterId,
                            userId,
                            practiceId,
                            patientId:
                                specialty === DENTAL
                                    ? patientId
                                    : selectedPet.patientId,
                            initialParam: initialParam.join("|"),
                            procedureType: appointment.treatment,
                            apptDates: apptDates.join(","),
                            apptDatesTimes: apptDatesTimes.join("|"),
                            description: appointment.comments,
                        }
                    );

                    if (
                        res?.isSuccess &&
                        res?.message ===
                            "You have successfully booked an appointment."
                    ) {
                        trackAcvitity(
                            itemType.SCHEDULING,
                            itemName.MAKE_APPOINTMENT,
                            "Home >> Click 'Make appointmet' button, select slot/s >> Click 'Make appointment' button >> Success"
                        );
                        Swal.fire({
                            icon: "success",
                            text: "Thank you for submitting an appointment request. You will receive a message confirming your appointment date and time.",
                        }).then(() => {
                            closeModal();
                        });
                    } else {
                        trackAcvitity(
                            itemType.SCHEDULING,
                            itemName.MAKE_APPOINTMENT,
                            "Home >> Click 'Make appointmet' button, select slot/s >> Click 'Make appointment' button >> ERROR"
                        );
                        Swal.fire({
                            icon: "error",
                            text: "Something went wrong. Please try again later.",
                        });
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    useEffect(() => {
        if (!appointment.doctor) {
            setShowCalendar(false);
        }
    }, [appointment.doctor]);

    return (
        <Modal openModal={openModal} closeModal={closeModal}>
            <div
                className="MakeAppointmentModal d-flex flex-column px-5 py-3 position-relative"
                style={{
                    gap: 28,
                    maxHeight: "90dvh",
                    overflowY: "auto",
                }}
            >
                {!loading && (
                    <>
                        <div>
                            <h2 className="fz-24" style={{ color: "#121B21" }}>
                                Request Appointment
                            </h2>
                            <div className="fz-16">
                                What type of appointment you would like to
                                schedule?
                            </div>
                        </div>

                        <div
                            className="d-flex flex-column fz-14"
                            style={{ gap: 32 }}
                        >
                            <div className="d-flex flex-column gap-3">
                                <div>
                                    <label>Select Treatment</label>
                                    <select
                                        name="treatment"
                                        value={appointment.treatment}
                                        onChange={handleChange}
                                    >
                                        {treatments.map((i) => (
                                            <option key={i} value={i}>
                                                {i.split("|")[0]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>Select Doctor</label>
                                    <select
                                        name="doctor"
                                        value={appointment.doctor}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Doctor</option>
                                        {doctors.map((i) => (
                                            <option
                                                key={
                                                    i.providerId +
                                                    i.providerName
                                                }
                                                value={i.providerId}
                                            >
                                                {i.providerName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.doctor && (
                                        <div className="font-destructive">
                                            {errors.doctor}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {showCalendar && (
                                <>
                                    <AppointmentCalendar
                                        selectedDate={selectedDate}
                                        slots={slots}
                                        setSlots={setSlots}
                                        appointment={appointment}
                                        selectedSlots={selectedSlots}
                                        setSelectedSlots={setSelectedSlots}
                                        setSelectedDate={setSelectedDate}
                                    />
                                    <Comments
                                        comments={appointment.comments}
                                        setAppointment={setAppointment}
                                    />
                                </>
                            )}

                            <div className="pb-2">
                                <div className="d-flex gap-3 justify-content-center">
                                    <button
                                        className="button button-primary"
                                        onClick={handleMakeAppt}
                                    >
                                        {showCalendar
                                            ? "Submit Request"
                                            : "Next"}
                                    </button>
                                    <button
                                        className="button button-secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                                {errors.submit && (
                                    <div className="font-destructive text-center">
                                        {errors.submit}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {loading && (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ minWidth: 400, minHeight: 400 }}
                    >
                        <ThreeDots
                            height="30"
                            width="60"
                            radius="9"
                            color="#681c9a"
                            ariaLabel="three-dots-loading"
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default MakeAppointmentModal;
