import { DENTAL } from "../../utils";

export const onboardingCallback = (data, setIsJoyrideRunning) => {
    try {
        const { action } = data;
        if (action === "reset" || action === "close") {
            setIsJoyrideRunning(false);
        }
    } catch (err) {
        console.log(err);
    }
};

const locale = { skip: <strong>SKIP</strong> };

export const getJoyrideSteps = (specialty, firstName) => {
    return [
        {
            target: specialty === DENTAL ? "body" : ".db-page",
            content: (
                <div className="d-flex flex-column align-items-center gap-3">
                    <h3 className="fz-18 fw-bold">
                        Hi <span className="text-capilatize ">{firstName}</span>
                        , Welcome!
                    </h3>
                    <div>
                        Let&apos;s take a minute to briefly introduce you to
                        Patient Portal.
                    </div>
                </div>
            ),
            placement: "center",
            locale,
        },
        {
            target: "#db-payment-overview-id",
            content: (
                <div className="d-flex flex-column align-items-center gap-3">
                    <h3 className="fz-18 fw-bold">Payment Overview</h3>
                    <div>
                        Gives you a quick glance at your dental costs, how much
                        you&apos;ve paid
                        {specialty === DENTAL
                            ? ", and benefits you have available to you."
                            : "."}
                    </div>
                </div>
            ),
            locale,
        },
        {
            target: "#db-your-procedures-id",
            content: (
                <div className="d-flex flex-column align-items-center gap-3">
                    <h3 className="fz-18 fw-bold">Procedures</h3>
                    <div>
                        View your upcoming and past appointments, or submit an
                        appointment request.
                    </div>
                </div>
            ),
            locale,
        },
        {
            target:
                specialty === DENTAL
                    ? "#overlay-onboard-id"
                    : "#vaccines-and-treatment-id",
            content: (
                <div className="d-flex flex-column align-items-center gap-3">
                    <h3 className="fz-18 fw-bold">
                        {specialty === DENTAL
                            ? "Treatment, Payments, Messaging"
                            : "Vaccines & Treatments"}
                    </h3>
                    <div>
                        {specialty === DENTAL
                            ? "View your scheduled treatments, track payments you've made, and communicate directly with your dentist"
                            : "Make sure your pets are up to date on their vaccines and treatments"}
                    </div>
                </div>
            ),
            locale,
        },
        {
            target:
                specialty === DENTAL
                    ? "#incorrect-info-id"
                    : "#onboard-last-step-id",
            content: (
                <div className="d-flex flex-column align-items-center gap-3">
                    <h3 className="fz-18 fw-bold">
                        {specialty === DENTAL
                            ? "SUPPORT"
                            : "Upload pet picture"}
                    </h3>
                    <div>
                        {specialty === DENTAL
                            ? "If your information appears incorrect, or you have questions regarding your account, reach out to support here"
                            : "Personalize your page by uploading photos of your pets"}
                    </div>
                </div>
            ),
            locale,
        },
    ];
};

export const onboardingStyles = {
    options: {
        backgroundColor: "#ffffff",
        primaryColor: "#681c9a",
        zIndex: 100000,
    },
};
