import React from "react";
import { NavLink } from "react-router-dom";

import { EDUCATION, PAYMENT_AND_BENEFITS, TREATMENT_PLANS, menu } from "./menu";
import { useSelector } from "react-redux";
import { VETERINARY } from "../../utils";
import {
    trackAcvitity,
    itemType,
    itemName,
} from "../../utils/activityTracking";

function NavLinks() {
    const { specialty } = useSelector((state) => state.userInfo);

    const handleTracking = (nav) => {
        trackAcvitity(
            itemType.NAVIGATION,
            itemName.NAVIGATE + nav.title + " page",
            "Click on " + nav.title + " Menu Item"
        );
    };

    return (
        <>
            {menu.map((nav) => {
                let title = nav.title;
                if (specialty === VETERINARY && title === EDUCATION) {
                    return (
                        <div key={nav.title} style={{ display: "none" }}></div>
                    );
                }
                if (specialty === VETERINARY && nav.title === TREATMENT_PLANS) {
                    title = "Estimates";
                }
                return (
                    <span onClick={() => handleTracking(nav)} key={nav.title}>
                        <NavLink
                            to={nav.path}
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-item-active"
                                    : "nav-item"
                            }
                            id={`${
                                title === PAYMENT_AND_BENEFITS
                                    ? "payment-and-benefits-id"
                                    : ""
                            }`}
                        >
                            {nav.icon}
                            {title}
                        </NavLink>
                    </span>
                );
            })}
        </>
    );
}

export default NavLinks;
