import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./FamilyMemberInformation.scss";
import { avatarUrl } from "../../api/apiRoot";
import { ApiCalls } from "../../api/allApiCalls";
import CardLoader from "../../components/loaders/CardLoader";

function GuarantorInformation() {
	const { masterId, userId, practiceId, custId, patientId } = useSelector(
		(state) => state.userInfo
	);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchGuarantorData = async () => {
			try {
				setLoading(true);
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/GuarantorData",
					{
						masterId,
						userId,
						practiceId,
						custId,
						patientId,
					}
				);
				setData(res);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		fetchGuarantorData();
	}, []);

	return (
		<div className="user-profile__family-members">
			<div className="d-flex justify-content-between align-items-center mb-2">
				<h3 className="fz-16 fw-bold">Guarantor/s Information</h3>
			</div>

			<div>
				{loading && (
					<div
						className="position-relative height-100p"
						style={{ height: 80 }}
					>
						<CardLoader />
					</div>
				)}
				<div className="family-member-info">
					{data.map((item, i) => (
						<div key={i} className="family-member-info-container">
							<img
								className="me-3 border-radius-100p mt-1"
								alt="user avatar"
								src={avatarUrl(item.firstName)}
								style={{
									height: 48,
									width: 48,
								}}
							/>
							<div className="family-member-flex flex-1">
								<div className="family-member-flex-item">
									<label>First Name</label>
									<input
										name="firstName"
										disabled={true}
										value={item.firstName}
									/>
								</div>
								<div className="family-member-flex-item">
									<label>Last Name</label>
									<input
										name="lastName"
										disabled={true}
										value={item.lastName}
									/>
								</div>
								<div className="family-member-flex-item">
									<label>Email</label>
									<input
										name="email"
										disabled={true}
										value={item.email}
									/>
								</div>
								<div className="family-member-flex-item">
									<label>Phone Number</label>
									<input
										name="phone"
										disabled={true}
										value={item.phone}
									/>
								</div>
								<div className="family-member-flex-item">
									<label>Address</label>
									<input
										name="address"
										disabled={true}
										value={item.address}
									/>
								</div>
							</div>
						</div>
					))}
				</div>

				{data.length === 0 && (
					<div className="text-italic">
						No record of guarantor available
					</div>
				)}
			</div>
		</div>
	);
}

export default GuarantorInformation;
