import React from "react";

function PasswordStrengthMeter(props) {
  const { password } = props;

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 12) strength += 1;
    if (password.length > 40) strength -= 1;
    if (/\d/.test(password)) strength += 1;
    if (/[~!@#$%^&*()-+]/.test(password)) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    return strength;
  };

  const getBarColor = () => {
    const strength = calculatePasswordStrength(password);
    if (strength === 0) return "#F3E1FF";
    if (strength === 1) return "#DFAFFF";
    if (strength === 2) return "#D494FF";
    if (strength === 3) return "#B672E3";
    if (strength === 4) return "#9D56CB";
    if (strength === 5) return "#681C9A";
  };

  const getBarWidth = () => {
    const strength = calculatePasswordStrength(password);
    return `${strength * 20}%`;
  };
  

  return (
    <div className="create-account-form__input password-input">
      Password Strength: {`${calculatePasswordStrength(password) * 20}%`}
      <div
        style={{
          height: "10px",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "#cccccc",
        }}
      >
        <div
          style={{
            height: "100%",
            width: getBarWidth(),
            backgroundColor: getBarColor(),
            transition: "width 0.5s, background-color 0.5s",
            borderRadius: '100px',
            transform: 'rotate(180deg)',
          }}
        />
      </div>
    </div>
  );
}

export default PasswordStrengthMeter;
