import React, { useEffect } from "react";
import styled from "styled-components";
import sikka_logo_transparent from "../../assets/images/sikka_logo_transparent.png";
import { logout } from "../../utils";

const StyledLogout = styled.div`
	position: fixed;
	width: 100vw;
	height: 100dvh;
	background: #ffffff;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	div {
		font-size: 32px;
		margin-top: 2rem;
	}

	img {
		width: 20%;
	}
`;

function Logout() {
	useEffect(() => {
		logout();
	}, []);

	return (
		<StyledLogout>
			<img alt="" src={sikka_logo_transparent} />
			<div>Logging out...</div>
		</StyledLogout>
	);
}

export default Logout;
