import React from "react";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import { useNavigate } from "react-router-dom";
import useSetTitle from "../../../hooks/useSetTitle";

function ResetPasswordSuccess() {
	const navigate = useNavigate();

	useSetTitle("Forgot Password");

	const handleSubmit = async () => {
		navigate("/login");
	};
	return (
		<div className="authentication-form-page">
			<div
				className="follow-up-content-wrapper form-content-wrapper"
				style={{ maxWidth: 700 }}
			>
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 36, margin: "1.5rem" }}
				/>
				<div
					className="mb-4 border-success bg-clr-success py-2 px-3 border-radius-4"
					style={{ maxWidth: 470, textAlign: "center" }}
				>
					Your password has been successfully changed!
				</div>
				<form className="follow-up-form" onSubmit={handleSubmit}>
					<button
						className="button button-primary mt-3 width-100p"
						type="submit"
						onClick={handleSubmit}
					>
						Login
					</button>
				</form>
			</div>
			<div className="company-copyright">
				© {new Date().getFullYear()} Sikka Software Corporation, The
				Practice Optimizer Company®. All Rights Reserved.
			</div>
		</div>
	);
}

export default ResetPasswordSuccess;
