import React, { useEffect, useState } from "react";
import Modal from "../modals/Modal";
import { articleData } from "./articleData";
import "./ArticleModal.scss";

function ArticleModal({ openModal, closeModal, articleId }) {
	const [selectedArticle, setSelectedArticle] = useState(articleData[0]);

	useEffect(() => {
		setSelectedArticle(articleData.find((a) => a.articleId === articleId));
	}, []);

	return (
		<Modal
			openModal={openModal}
			closeModal={closeModal}
			animationOff={true}
		>
			<div style={{ overflowY: "auto" }}>
				<h2 className="fz-18 text-center">
					{selectedArticle.articleTitle}
				</h2>

				<div className="article-modal-content-container">
					<iframe src={selectedArticle.pdfSource}></iframe>
				</div>
			</div>
		</Modal>
	);
}

export default ArticleModal;
