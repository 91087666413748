import React from "react";
import useSetTitle from "../../hooks/useSetTitle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ApiCalls } from "../../api/allApiCalls";
import Trademark from "../Trademark/Trademark";
import sikka_logo from "../../assets/images/sikka_logo_transparent.png";
import Big_spinner from "../../assets/images/Spinner.gif";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";

import "./sikkascore.scss";
const Sikkascore = () => {
    useSetTitle("SikkaScore");
    const { apiToken } = useSelector((state) => state.userInfo);
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [showSpinner]);
    const [practiceLogo, setPracticeLogo] = useState("");

    const currentUrl = window.location.href;
    const fullURL = currentUrl.split("?");
    const parameters = {};
    const queryParameters = fullURL[1].split("&");
    queryParameters.forEach((parameter) => {
        const firstEqualsIndex = parameter.indexOf("=");
        if (firstEqualsIndex !== -1) {
            const key = parameter.substring(0, firstEqualsIndex);
            const value = parameter.substring(firstEqualsIndex + 1);
            parameters[key] = value;
        }
    });
    const refId = parameters["ReferenceId"];
    const referenceId = encodeURIComponent(refId);

    useEffect(() => {
        if (!referenceId || apiToken) return navigate("*");
    }, []);

    useEffect(() => {
        if (!referenceId) {
            return navigate("*");
        }
        const fetchPracticeLogo = async () => {
            try {
                let res = await ApiCalls.authenticationGET(
                    `/v2/EmailResponse/PracticeLogo?ReferenceId=${referenceId}`
                );
                setPracticeLogo(res.logoUrl);
            } catch (err) {
                console.log(err);
            }
        };
        fetchPracticeLogo();
        const fetchSikkaScoreReplyMessage = async () => {
            try {
                const res = await ApiCalls.authenticationGET(
                    `/v2/EmailResponse/SikkaScoreReplyMessage?ReferenceId=${referenceId}`
                );
                if (res.isSuccess === true && res.message === "Successful") {
                    trackAcvitity(
                        itemType.EMAILRESPONSE_SIKKASCORE_AUTHORISE,
                        itemName.EMAILRESPONSE_SIKKASCORE_AUTHORISE,
                        "Click on 'Yes'Button >> Success"
                    );
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchSikkaScoreReplyMessage();
    }, [referenceId]);

    return (
        <div>
            {showSpinner ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                        background: `url('${sikka_logo}') center center no-repeat`,
                        backgroundSize: "40%",
                    }}
                >
                    <div style={{ marginTop: "200px" }}>
                        <img
                            src={Big_spinner}
                            alt="Loading..."
                            height={80}
                            width={80}
                        ></img>
                    </div>
                </div>
            ) : (
                <>
                    <div className="sikkascore-page  ">
                        <div>
                            {!practiceLogo ? (
                                <>
                                    <img
                                        src="https://static.vecteezy.com/system/resources/thumbnails/008/957/225/small/female-doctor-avatar-clipart-icon-in-flat-design-vector.jpg"
                                        alt="Image Loading..."
                                        style={{
                                            borderRadius: "50%",
                                            width: "200px",
                                            height: "200px",
                                            padding: "-1rem",
                                        }}
                                    ></img>
                                </>
                            ) : (
                                <img
                                    src={practiceLogo}
                                    alt="Image Loading..."
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        padding: "-1rem",
                                    }}
                                ></img>
                            )}
                        </div>
                        <div className="mt-5">
                            <h5>Thank you so much for authorizing us.</h5>
                        </div>
                        <div className="mt-5">
                            <Trademark />{" "}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Sikkascore;
