import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiCalls } from "../api/allApiCalls";
import { DENTAL, VETERINARY } from "../utils";

const useFetchUpcomingProcedures = () => {
	const { masterId, userId, practiceId, custId, patientId, specialty } =
		useSelector((state) => state.userInfo);
	const { selectedPet } = useSelector((state) => state.petInfo);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchUpcomingProceduresData = async () => {
			try {
				setLoading(true);
				let res = await ApiCalls.authGetAPI(
					"/v2/Patient/UpcomingProceduresData",
					{
						masterId,
						userId,
						practiceId,
						custId,
						patientId:
							specialty === DENTAL
								? patientId
								: selectedPet.patientId,
					}
				);
				setData(res);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		if (specialty === VETERINARY && !selectedPet) return;
		fetchUpcomingProceduresData();
	}, [selectedPet]);

	return [loading, data];
};

export default useFetchUpcomingProcedures;
