import { browserStore } from "../utils/index.js";

export function authHeader() {
	const token = browserStore.get("userInfo")
		? browserStore.get("userInfo")?.apiToken
		: "";
	if (token) {
		return {
			"x-auth-token": "Bearer " + token,
			"Content-Type": "application/json",
			"cache-control": "no-cache",
		};
	} else {
		return {};
	}
}
