import React from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";
import CloseModalButton from "./CloseModalButton";

function Modal(props) {
	return ReactDOM.createPortal(
		<>
			<div
				className={`app-modal ${
					props.animationOff ? " no-animation" : ""
				}`}
			>
				<div className="app-modal-content">
					<CloseModalButton closeModal={props.closeModal} />
					{props.header && (
						<h2 className="border-bottom pb-3 text-center">
							{props.header}
						</h2>
					)}
					{props.children}
				</div>
			</div>
			<div
				onClick={props.closeModal}
				className={`app-modal-overlay${
					props.openModal ? " active" : ""
				}`}
			></div>
		</>,
		document.getElementById("modal-root")
	);
}

export default Modal;
