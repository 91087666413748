/*eslint-disable*/
import unsplash_medicine from "../../assets/images/unsplash_medicine.png";
import unsplash_smoke from "../../assets/images/unsplash_smoke.png";
import unsplash_brain from "../../assets/images/unsplash_brain.png";
import PeriodontitisArticle from "../../assets/pdf/PeriodontitisArticle.pdf";
import SilveiraArticle from "../../assets/pdf/SilveiraArticle.pdf";
import fnagi from "../../assets/pdf/fnagi.pdf";
import woman_with_tooth_brush from "../../assets/images/woman_with_tooth_brush.png";
import man_flossing from "../../assets/images/man_flossing.png";
import gum_disease from "../../assets/images/gum_disease.png";
import tooth_pain from "../../assets/images/tooth_pain.png";
import tooth from "../../assets/images/tooth.png";
import woman_smile from "../../assets/images/woman_smile.png";
import woman_smile2 from "../../assets/images/woman_smile2.png";
import prenancy_test_kit from "../../assets/images/prenancy_test_kit.png";

export const articleData = [
	{
		articleId: 4,
		articleTitle: "TYPES OF TOOTHBRUSHES - CHOOSE THE BEST BRUSH FOR YOU",
		content:
			"Using the right toothbrush can make your daily oral care routine more enjoyable—and more effective. A toothbrush is personal, which is why they're available in a variety of sizes and shapes. If you have a large mouth, you may prefer a full-sized toothbrush head. If you have a small mouth, opt for a compact head. And some adults even prefer youth or child-sized toothbrushes because they find them easier to use for brushing behind the back teeth.",
		timestamp: "",
		imgSrc: woman_with_tooth_brush,
		source: "Oral B",
		pdfSource: "",
		url: "https://oralb.com/en-us/oral-health/why-oral-b/electric-toothbrushes/types-of-toothbrushes/",
	},
	{
		articleId: 5,
		articleTitle: "WHY YOUR GUMS BLEED WHEN FLOSSING",
		content:
			"Do you experience tender gums when flossing or even brushing? Learn what causes bleeding gums when flossing, when it's best to see a dental professional and how to track instances of gum bleeding to help keep your gums in healthy condition.",
		timestamp: "",
		imgSrc: man_flossing,
		source: "Oral B",
		pdfSource: "",
		url: "https://oralb.com/en-us/oral-health/conditions/gums/why-your-gums-bleed-when-flossing/",
	},
	{
		articleId: 6,
		articleTitle: "GUM DISEASE: CAUSES AND TREATMENTS",
		content:
			"Gum disease, also known as Periodontitis, occurs due to bacterial growth in the mouth and can lead to gum deterioration and loosening teeth. It usually starts out as gingivitis, with red and irritated gums, and progresses to a more serious, although fairly rare condition.",
		timestamp: "",
		imgSrc: gum_disease,
		source: "Oral B",
		pdfSource: "",
		url: "https://oralb.com/en-us/oral-health/conditions/gums/gum-disease-causes-and-treatments/",
	},
	{
		articleId: 7,
		articleTitle: "PREGNANCY GINGIVITIS",
		content:
			"Gingivitis occurs when plaque, which contains bacteria, builds up on teeth and produces toxins that irritate the gums. If you have bleeding, red, puffy, sore or inflamed gums, it's probably gingivitis. (From dentalcare.com – “What is Gingivitis?)",
		timestamp: "",
		imgSrc: tooth_pain,
		source: "Oral B",
		pdfSource: "",
		url: "https://oralb.com/en-us/oral-health/life-stages/pregnancy/pregnancy-gingivitis/",
	},
	{
		articleId: 8,
		articleTitle: "What is Gingivitis? Symptoms, Causes, and Treatments",
		content:
			"Gingivitis is a form of gum disease that happens when plaque, a naturally-occurring sticky film containing bacteria, builds up on teeth and causes the inflammation of the surrounding gum tissue. Plaque produces toxins that irritate the gums. This can cause the gums to become inflamed, making them red or puffy, or causing them to bleed. ",
		timestamp: "",
		imgSrc: tooth,
		source: "Crest",
		pdfSource: "",
		url: "https://crest.com/en-us/oral-care-tips/gum-health/gingivitis-symptoms-causes-treatments",
	},
	{
		articleId: 9,
		articleTitle: "Teeth Whitening: How to whiten at home?",
		content:
			"There are many different products that claim to effectively whiten teeth. How do you know which ones are legitimate? We have taken a look at some of the most popular teeth whitening solutions and their ingredients and broken them down in this article to help best understand teeth whitening at home.",
		timestamp: "",
		imgSrc: woman_smile,
		source: "Crest",
		pdfSource: "",
		url: "https://crest.com/en-us/oral-care-tips/teeth-whitening/how-to-whiten-at-home",
	},
	{
		articleId: 10,
		articleTitle: "Stages of Tooth Decay",
		content:
			"There are five stages of tooth decay. We've outlined the common symptoms at each stage so you can look out for early signs to better reverse early decay for healthier, stronger teeth.",
		timestamp: "",
		imgSrc: woman_smile2,
		source: "Crest",
		pdfSource: "",
		url: "https://crest.com/en-us/oral-care-tips/cavities-and-tooth-decay/stages-of-tooth-decay",
	},
	{
		articleId: 11,
		articleTitle: "Dental Care During Pregnancy",
		content:
			"During pregnancy, there is an increased production of hormones like estrogen, progesterone and others. Changes in hormone levels are thought to cause shifts in oral bacteria and change reaction of the body to infection, which often leads to development of gum inflammation also known as gingivitis.",
		timestamp: "",
		imgSrc: prenancy_test_kit,
		source: "dentalcare.com",
		pdfSource: "",
		url: "https://www.dentalcare.com/en-us/pregnancy",
	},
	{
		articleId: 1,
		articleTitle:
			"Comparative Effects of E-Cigarette Aerosol on Periodontium of Periodontitis Patients",
		content:
			"Tobacco use is one of the main causes of periodontitis. E-cigarette are gaining in popularity, and studies are needed to better understand the impact of e-cigarettes on oral health. Periodontitis, also known as gum disease, is a chronic, polymicrobial inflammatory disease affecting the tissue supporting the tooth.",
		timestamp: "2021-09-07",
		// timestamp: "Sep 07, 2021",
		imgSrc: unsplash_medicine,
		source: "Frontiers",
		pdfSource: PeriodontitisArticle,
		url: "https://www.frontiersin.org/articles/10.3389/froh.2021.729144/full",
	},
	{
		articleId: 2,
		articleTitle:
			"Tobacco Use and Incidence of Adverse Oral Health Outcomes Among US Adults in the Population Assessment of Tobacco and Health Study",
		content:
			"Is the use of tobacco products, especially electronic nicotine delivery systems (ENDS), associated with the incidence of oral health outcomes among US adults? Periodontitis, also known as gum disease, is a chronic, polymicrobial inflammatory disease affecting the tissue supporting the tooth. ",
		timestamp: "2022-12-09",
		// timestamp: "Dec 09, 2022",
		imgSrc: unsplash_smoke,
		source: "JAMA Network",
		pdfSource: SilveiraArticle,
		url: "https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2799347",
	},
	{
		articleId: 3,
		articleTitle:
			"The Periodontal Pathogen Fusobacterium nucleatum Exacerbates Alzheimer's Pathogenesis via Specific Pathways",
		content:
			"Alzheimer's Disease (AD) is the most common form of dementia in older adults and has a devastating impact on the patient's quality of life, which creates a significant socio-economic burden for the affected individuals and their families. In recent years, studies have identified a relationship between periodontitis and AD.",
		timestamp: "2022-06-23",
		// timestamp: "June 23, 2022",
		imgSrc: unsplash_brain,
		source: "Frontiers",
		pdfSource: fnagi,
		url: "https://www.frontiersin.org/articles/10.3389/fnagi.2022.912709/full",
	},
];
