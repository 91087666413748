import { createSlice } from "@reduxjs/toolkit";
import { browserStore } from "../utils";

const userInfo = browserStore.get("userInfo");

export const userInfoSlice = createSlice({
	name: "userInfo",
	initialState: {
		isAutherized: userInfo?.isAutherized ? userInfo.isAutherized : false,
		masterId: userInfo?.masterId ? userInfo.masterId : "",
		userId: userInfo?.userId ? userInfo.userId : "",
		practiceId: userInfo?.practiceId ? userInfo.practiceId : "",
		patientId: userInfo?.patientId ? userInfo.patientId : "",
		firstName: userInfo?.firstName ? userInfo.firstName : "",
		lastName: userInfo?.lastName ? userInfo.lastName : "",
		emailId: userInfo?.emailId ? userInfo.emailId : "",
		city: userInfo?.city ? userInfo.city : "",
		state: userInfo?.state ? userInfo.state : "",
		zipCode: userInfo?.zipCode ? userInfo.zipCode : "",
		registrationDate: userInfo?.registrationDate
			? userInfo.registrationDate
			: "",
		apiToken: userInfo?.apiToken ? userInfo.apiToken : "",
		authenticated: userInfo?.authenticated ? userInfo.authenticated : false,
		age: userInfo?.age ? userInfo.age : "",
		cell: userInfo?.cell ? userInfo.cell : "",
		custId: userInfo?.custId ? userInfo.custId : 1,
		email: userInfo?.email ? userInfo.email : "",
		firstVisit: userInfo?.firstVisit ? userInfo.firstVisit : "",
		gender: userInfo?.gender ? userInfo.gender : "",
		guarId: userInfo?.guarId ? userInfo.guarId : "",
		lastVisit: userInfo?.lastVisit ? userInfo.lastVisit : "",
		verificationCode: userInfo?.verificationCode
			? userInfo.verificationCode
			: "",
		publicIP: userInfo?.publicIP ? userInfo.publicIP : "",
		specialty: userInfo?.specialty ? userInfo.specialty : "",
	},
	reducers: {
		addEmailId: (state, action) => {
			state.emailId = action.payload;
		},
		addUserIdVerification: (state, action) => {
			state.userId = action.payload.userId;
			state.verificationCode = action.payload.verificationCode;
		},
		addCell: (state, action) => {
			state.cell = action.payload;
		},
		addUserInfo: (state, action) => {
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.emailId = action.payload.emailId;
			state.apiToken = action.payload.apiToken;
		},
		login: (state, action) => {
			(state.isAutherized = action.payload.isAutherized),
				(state.masterId = action.payload.masterId),
				(state.userId = action.payload.userId),
				(state.practiceId = action.payload.practiceId),
				(state.patientId = action.payload.patientId),
				(state.firstName = action.payload.firstName),
				(state.lastName = action.payload.lastName),
				(state.emailId = action.payload.emailId),
				(state.city = action.payload.city),
				(state.state = action.payload.state),
				(state.zipCode = action.payload.zipCode),
				(state.registrationDate = action.payload.registrationDate),
				(state.apiToken = action.payload.apiToken),
				(state.authenticated = action.payload.authenticated),
				(state.age = action.payload.age),
				(state.cell = action.payload.cell),
				(state.custId = action.payload.custId),
				(state.email = action.payload.email),
				(state.firstVisit = action.payload.firstVisit),
				(state.gender = action.payload.gender),
				(state.guarId = action.payload.guarId),
				(state.lastVisit = action.payload.lastVisit),
				(state.publicIP = action.payload.publicIP),
				(state.specialty = action.payload.specialty);
		},
		updateUserInfo: (state, action) => {
			(state.firstName = action.payload.firstName),
				(state.lastName = action.payload.lastName),
				(state.cell = action.payload.cell),
				(state.emailId = action.payload.emailId);
		},
		updateName: (state, action) => {
			(state.firstName = action.payload.firstName),
				(state.lastName = action.payload.lastName);
		},
	},
});

export const {
	addEmailId,
	addUserIdVerification,
	addCell,
	addUserInfo,
	login,
	updateUserInfo,
	updateName,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
