import { createSlice } from "@reduxjs/toolkit";

export const pageLoaderSlice = createSlice({
	name: "loader",
	initialState: {
		loading: false,
	},
	reducers: {
		setPageLoaderStatus: (state, action) => {
			state.loading = action.payload; // payload should be boolean
		},
	},
});

export const { setPageLoaderStatus } = pageLoaderSlice.actions;

export default pageLoaderSlice.reducer;
