import React, { useState } from "react";
import { ApiCalls } from "../../api/allApiCalls";
import { useSelector } from "react-redux";
import useSetTitle from "../../hooks/useSetTitle";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import sikka_logo from "../../assets/images/sikka_logo_transparent.png";
import Big_spinner from "../../assets/images/Spinner.gif";
import "./confirmappointment.scss";
import Swal from "sweetalert2";
import Trademark from "../Trademark/Trademark";
import ButtonLoader from "../../components/loaders/ButtonLoader";

const ConfirmAppointment = () => {
    useSetTitle("Confirm-Appointments");
    const navigate = useNavigate();
    const { apiToken } = useSelector((state) => state.userInfo);

    const url = window.location.href;
    const parameters = {};
    const fullURL = url.split("?");
    const [loading, setLoading] = useState(false); // State variable to track loading state
    const [buttonDisabled, setButtonDisabled] = useState(false); // State variable to track button disabled state

    const queryParameters = fullURL[1].split("&");

    queryParameters.forEach((parameter) => {
        const firstEqualsIndex = parameter.indexOf("=");
        if (firstEqualsIndex !== -1) {
            const key = parameter.substring(0, firstEqualsIndex);
            const value = parameter.substring(firstEqualsIndex + 1);
            parameters[key] = value;
        }
    });

    const refId = parameters["ReferenceId"];
    const referenceId = encodeURIComponent(refId);
    const calenderPAgeLink = `${window.location.origin}/addtocalendar?ReferenceId=${refId}`;

    // const apptdate = parameters["apptdate"];

    useEffect(() => {
        if (!referenceId || apiToken) return navigate("*");
    }, []);

    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [showSpinner]);

    const [patientData, setPatientData] = useState([]);
    const [practiceLogo, setPracticeLogo] = useState("");
    const [selectedPatId, setSelectedPatId] = useState([]);
    const [apptList, setApptList] = useState([]);
    function formatDate(dateString) {
        const options = {
            day: "2-digit",
            month: "long",
            year: "numeric",
            timeZone: "UTC",
        };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    }

    const handleOnchange = (e) => {
        const value = e.target.value;
        const isValueExists = selectedPatId.includes(value);

        if (isValueExists) {
            // If the value exists, remove it from the array
            const filteredArray = selectedPatId.filter(
                (item) => item !== value
            );
            setSelectedPatId(filteredArray);
        } else {
            // If the value doesn't exist, add it to the array
            setSelectedPatId([...selectedPatId, value]);
        }
    };

    useEffect(() => {
        const fetchPracticeLogo = async () => {
            try {
                let res = await ApiCalls.authenticationGET(
                    `/v2/EmailResponse/PracticeLogo?ReferenceId=${referenceId}`
                );
                setPracticeLogo(res.logoUrl);
            } catch (err) {
                console.log(err);
            }
        };
        fetchPracticeLogo();
        const fetchPatienData = async () => {
            let res = await ApiCalls.authenticationGET(
                `/v2/EmailResponse/PatientData?ReferenceId=${referenceId}`
            );
            setPatientData(res);
        };
        fetchPatienData();
    }, [referenceId]);

    useEffect(() => {
        const Appointmentlist = async () => {
            const res = await ApiCalls.authenticationGET(
                `/v2/EmailResponse/ShowUnconfirmedPatList?ReferenceId=${referenceId}`
            );
            setApptList(res);
        };
        Appointmentlist();
    }, [patientData]);

    const confirmAppointment = async () => {
        setLoading(true);
        setButtonDisabled(true);
        let PatientIds;
        if (selectedPatId.length > 0) {
            PatientIds = selectedPatId.join(",");
        }
        try {
            const res = await ApiCalls.authenticationPOST(
                `/v2/EmailResponse/AppointmentConfirmation?ReferenceId=${referenceId}&SelPatientId=${PatientIds}`
            );
            if (res.isSuccess === true) {
                Swal.fire({
                    icon: "success",
                    text: `${res.message}`,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    icon: "error",
                    message: `${res.message}`,
                    allowOutsideClick: false,
                });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            setButtonDisabled(false);
        }
    };

    return (
        <div>
            {showSpinner ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                        background: `url('${sikka_logo}') center center no-repeat`,
                        backgroundSize: "40%",
                    }}
                >
                    <div style={{ marginTop: "200px" }}>
                        <img
                            src={Big_spinner}
                            alt="Loading..."
                            height={80}
                            width={80}
                        ></img>
                    </div>
                </div>
            ) : (
                <div className="appt-page">
                    <div>
                        <div className="section-2">
                            <div className="practice-logo">
                                {!practiceLogo ? (
                                    <>
                                        <img
                                            src="https://static.vecteezy.com/system/resources/thumbnails/008/957/225/small/female-doctor-avatar-clipart-icon-in-flat-design-vector.jpg"
                                            alt="Image Loading..."
                                            style={{
                                                borderRadius: "50%",
                                                width: "200px",
                                                height: "200px",
                                                padding: "-1rem",
                                            }}
                                        ></img>
                                    </>
                                ) : (
                                    <img
                                        src={practiceLogo}
                                        alt="Image Loading..."
                                        style={{
                                            width: "200px",
                                            height: "200px",
                                            padding: "-1rem",
                                        }}
                                    ></img>
                                )}
                            </div>
                            <div className="conatiner mt-5 footer-line">
                                {apptList[0].patientId === "" ? (
                                    <>
                                        <p>
                                            {apptList[0].message}
                                            {apptList[0].message ==
                                                "Thank you. Our records show the appointment is not active anymore. If you think there is an error, please call us: 8015555555" ||
                                            apptList[0].message ==
                                                "Thank you. Our records show the appointment is not active anymore. If you think there is an error, please call our office." ? (
                                                ""
                                            ) : (
                                                <a href={calenderPAgeLink}>
                                                    Click here!
                                                </a>
                                            )}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <h4 className="practice-logo">
                                            {" "}
                                            Please Confirm Your Appointments!
                                        </h4>
                                        <div className="practice-logo mt-3">
                                            <b>Please mark your calendar</b>{" "}
                                            <a href={calenderPAgeLink}>
                                                Click here!
                                            </a>
                                        </div>
                                        {apptList.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <h5
                                                        className="practice-logo mt-3"
                                                        style={{
                                                            color: "blue",
                                                        }}
                                                    >
                                                        {formatDate(
                                                            item.apptDate
                                                        )}
                                                    </h5>

                                                    <ul className="practice-logo">
                                                        <li key={index}>
                                                            <div className="row">
                                                                <div className="practice-logo col-4">
                                                                    <input
                                                                        type="checkbox"
                                                                        style={{
                                                                            width: "20px",
                                                                        }}
                                                                        value={
                                                                            item.patientId
                                                                        }
                                                                        onChange={
                                                                            handleOnchange
                                                                        }
                                                                    ></input>
                                                                </div>
                                                                <div className="practice-logo col-8">
                                                                    <b>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </b>
                                                                    &nbsp;{":"}
                                                                    &nbsp;
                                                                    {
                                                                        item.apptTime
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                        <div className="practice-logo mt-3">
                                            <button
                                                className="appointment-btn"
                                                disabled={
                                                    selectedPatId.length ===
                                                        0 || buttonDisabled
                                                }
                                                onClick={() =>
                                                    confirmAppointment()
                                                }
                                            >
                                                {loading ? (
                                                    <ButtonLoader />
                                                ) : (
                                                    "Confirm"
                                                )}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div>
                            <Trademark />
                        </div>
                    </div>
                </div>
            )}
            <div></div>
        </div>
    );
};

export default ConfirmAppointment;
