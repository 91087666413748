/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
// import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import { init as initApm } from "@elastic/apm-rum";
import { useSelector } from "react-redux";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import "./styles/App.scss";
import "bootstrap/dist/css/bootstrap.css";
import NotFound from "./components/NotFound";
import NavBar from "./components/navigations/NavBar";
import TreatmentPlans from "./pages/TreatmentPlans/TreatmentPlans";
import PaymentsAndBenefits from "./pages/PaymentsAndBenefits/PaymentsAndBenefits";
import Education from "./pages/Education/Education";
import Settings from "./pages/Settings/Settings";
import FAQ from "./pages/FAQ/FAQ";
import FollowUp from "./pages/Authentication/FollowUp/FollowUp";
import Authentication from "./pages/Authentication/Authentication";
import FollowupSuccess from "./pages/Authentication/FollowUp/FollowupSuccess";
import AdditionalInformation from "./pages/Authentication/AdditionalInformation";
import Verification from "./pages/Authentication/Verification/Verification";
import CreateNewAccount from "./pages/Authentication/CreateNewAccount/CreateNewAccount";
import CreateNewAccountSuccess from "./pages/Authentication/CreateNewAccount/CreateNewAccountSuccess";
import Logout from "./pages/Logout/Logout";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import DrawerNavBar from "./components/navigations/DrawerNavBar";
import LazyLoader from "./components/loaders/LazyLoader";
import SendVerification from "./pages/Authentication/Verification/SendVerification";
// import BrushingHistory from "./pages/BrushingHistory/BrushingHistory";
import Messages from "./pages/Messages/Messages";
import ForgotPassword from "./pages/Authentication/ForgotPassword/ForgotPassword";
import OtpVerification from "./pages/Authentication/ForgotPassword/OtpVerification";
import ResetPassword from "./pages/Authentication/ForgotPassword/ResetPassword";
import ResetPasswordSuccess from "./pages/Authentication/ForgotPassword/ResetPasswordSuccess";
import Dashboard from "./pages/Dashboard/Dashboard";
import ProfileAndSettings from "./pages/ProfileAndSettings/ProfileAndSettings";
import Unsubscribe from "../src/outer-routing/Unsubscribe";
import Schedule from "../src/outer-routing/Schedule";
import ConfirmAppointment from "./outer-routing/confirmappointments/ConfirmAppointment";
import Sikkascore from "./outer-routing/sikkascore/Sikkascore";
import Survey from "./outer-routing/feedback/Survey";
import Icalender from "./outer-routing/generatecalender/Icalender";
import useScrollToTop from "./hooks/useScrollToTop";

initApm({
    serviceName: "PHP-React",
    serverUrl:
        "https://c31d0eacfbec4134950a69ec80e35063.apm.us-east-1.aws.cloud.es.io:443",
    serviceVersion: "1.0.0.0",
});

function App() {
    const { authenticated, apiToken } = useSelector((state) => state.userInfo);
    const { loading } = useSelector((state) => state.pageLoader);
    const targetDivRef = useRef(null);
    const { showUpButton, scrollToTop } = useScrollToTop(targetDivRef);

    return (
        <div className="App">
            {loading && <LazyLoader />}
            {authenticated && apiToken && <NavBar />}

            <main ref={targetDivRef}>
                {authenticated && apiToken && <DrawerNavBar />}

                <ApmRoutes>
                    <Route exact path="login" element={<Login />} />

                    <Route
                        exact
                        path="authentication"
                        element={<Authentication />}
                    />
                    <Route
                        path="authentication/follow-up/success"
                        element={<FollowupSuccess />}
                    />
                    <Route
                        path="authentication/follow-up"
                        element={<FollowUp />}
                    />
                    <Route
                        path="authentication/additional-information"
                        element={<AdditionalInformation />}
                    />
                    <Route
                        path="authentication/send-verification-code"
                        element={<SendVerification />}
                    />
                    <Route
                        path="authentication/verification"
                        element={<Verification />}
                    />
                    <Route
                        path="authentication/create-account"
                        element={<CreateNewAccount />}
                    />
                    <Route
                        path="authentication/create-account/success"
                        element={<CreateNewAccountSuccess />}
                    />
                    <Route path="forgotPassword" element={<ForgotPassword />} />
                    <Route
                        path="forgotPassword/verifyOTP"
                        element={<OtpVerification />}
                    />
                    <Route
                        path="forgotPassword/resetPassword"
                        element={<ResetPassword />}
                    />
                    <Route
                        path="forgotPassword/resetPasswordSuccess"
                        element={<ResetPasswordSuccess />}
                    />
                    <Route
                        exact
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="treatment-plans"
                        element={
                            <ProtectedRoute>
                                <TreatmentPlans />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="payments-and-benefits"
                        element={
                            <ProtectedRoute>
                                <PaymentsAndBenefits />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="messages"
                        element={
                            <ProtectedRoute>
                                <Messages />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="profile-settings"
                        element={
                            <ProtectedRoute>
                                <ProfileAndSettings />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="education"
                        element={
                            <ProtectedRoute>
                                <Education />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="settings"
                        element={
                            <ProtectedRoute>
                                <Settings />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="FAQ"
                        element={
                            <ProtectedRoute>
                                <FAQ />
                            </ProtectedRoute>
                        }
                    />
                    {/* <Route
						path="my-care"
						element={
							<ProtectedRoute>
								<BrushingHistory />
							</ProtectedRoute>
						}
					/> */}
                    <Route path="logout" element={<Logout />} />
                    <Route path="*" element={<NotFound />} />

                    <Route path="unsubscribe" element={<Unsubscribe />} />

                    <Route path="schedule" element={<Schedule />} />
                    <Route path="sikkascore" element={<Sikkascore />} />
                    <Route
                        path="apptconfirmation"
                        element={<ConfirmAppointment />}
                    />
                    <Route path="survey" element={<Survey />} />
                    <Route path="addtocalendar" element={<Icalender />} />
                </ApmRoutes>

                {showUpButton && (
                    <div
                        className="scroll-up-icon-container cursor-pointer"
                        onClick={scrollToTop}
                    >
                        <ArrowCircleRightIcon
                            className="scroll-up-icon"
                            role="button"
                        />
                        <div className="fz-14">Back to top</div>
                    </div>
                )}
            </main>
        </div>
    );
}

export default App;
