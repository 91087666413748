import React, { useState } from "react";
import { useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

function SendVerificationOption({
	setStep,
	selectedOption,
	setSelectedOption,
}) {
	const { maskedEmail, maskedCell } = useSelector(
		(state) => state.authentication
	);
	const [error, setError] = useState("");

	const handleSubmit = () => {
		if (!selectedOption) {
			setError("Please select one of the options above.");
			return;
		}
		if (selectedOption === "Email") setStep(1);
		if (selectedOption === "Text") setStep(2);
	};

	const handleChange = (type) => {
		setSelectedOption(type);
		if (error) setError("");
	};

	return (
		<div>
			<div
				className="border-warning bg-clr-warning p-2 border-radius-6"
				style={{ marginBottom: "2.5rem" }}
			>
				We would like to send the verification code to your email
				id/phone number. Please select one of the below options.
			</div>

			<div
				className="d-flex flex-column gap-4"
				style={{ maxWidth: 400, margin: "0 auto" }}
			>
				<div className="d-flex align-items-center gap-2">
					<input
						type="radio"
						style={{ width: 16, height: 16 }}
						checked={selectedOption === "Email"}
						onChange={() => handleChange("Email")}
					/>
					<label className="d-flex align-items-center gap-1 font-grey01">
						<EmailIcon />
						{maskedEmail}
					</label>
				</div>

				<div className="d-flex align-items-center gap-2">
					<input
						type="radio"
						style={{ width: 16, height: 16 }}
						checked={selectedOption === "Text"}
						onChange={() => handleChange("Text")}
					/>
					<label className="d-flex align-items-center gap-1 font-grey01">
						<PhoneIphoneIcon />
						{maskedCell}
					</label>
				</div>

				<button
					className="button button-primary mt-2"
					onClick={handleSubmit}
				>
					Submit
				</button>

				{error && <div className="error font-destructive">{error}</div>}
			</div>
		</div>
	);
}

export default SendVerificationOption;
