import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { ApiCalls } from "../../api/allApiCalls";
import { VETERINARY } from "../../utils";

const StyledMessageDetails = styled.div`
	border: solid 1px #d3d5db;
	border-radius: 6px;
	padding: 1rem;
	height: calc(100dvh - 156px);
	overflow: auto;
	position: relative;

	.goBack-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 4px;
		color: #681c9a;
		margin-bottom: 1rem;
		position: sticky;
		top: 0;
		background: white;
		width: fit-content;
		padding: 0.25rem 0.75rem 0.25rem 0;
		border-radius: 5px;

		&:hover {
			opacity: 0.9;
		}
	}
`;

function MessageDetails({ selectedMail, handleGoBack }) {
	const { masterId, userId, practiceId, patientId, specialty } = useSelector(
		(state) => state.userInfo
	);
	const { selectedPet } = useSelector((state) => state.petInfo);
	const [data, setData] = useState(null);

	useEffect(() => {
		const fetchDetails = async () => {
			try {
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/MessageDetails",
					{
						masterId,
						userId,
						practiceId,
						patientId:
							specialty === VETERINARY
								? selectedPet.patientId
								: patientId,
						messageId: selectedMail?.messageId,
					}
				);

				setData(res[0]?.message);
			} catch (err) {
				console.log(err);
			}
		};
		fetchDetails();
	}, []);

	return (
		<StyledMessageDetails>
			<div className="goBack-button" onClick={handleGoBack}>
				<ArrowBackIosIcon fontSize="small" />
				Go back
			</div>
			{selectedMail && data && (
				<div className="detail-contents">
					<div className="mb-4">
						<div>
							<span>From:</span> {selectedMail.fromName}
						</div>
						<div>
							<span>To:</span> {selectedMail.toName}
						</div>
						<div>
							<span>Subject:</span> {selectedMail.subject}
						</div>
					</div>
					<div
						dangerouslySetInnerHTML={{
							__html: data,
						}}
					/>
				</div>
			)}
			{!selectedMail || (!data && <div>No Record Available</div>)}
		</StyledMessageDetails>
	);
}

export default MessageDetails;
