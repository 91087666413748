import React from "react";
import { Oval } from "react-loader-spinner";

function CardLoader() {
	return (
		<div
			className="position-absolute d-flex justify-content-center align-items-center start-0 top-0 width-100p height-100p bg-white"
			style={{
				zIndex: 3,
				borderRadius: 6,
			}}
		>
			<Oval
				height={50}
				width={50}
				color="#681c9a"
				visible={true}
				ariaLabel="oval-loading"
				secondaryColor="#f3e1ff"
				strokeWidth={5}
				strokeWidthSecondary={4}
			/>
		</div>
	);
}

export default CardLoader;
