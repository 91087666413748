import React, { useState } from "react";

import "./Settings.scss";
import SettingsProfile from "./SettingsProfile";
import useSetTitle from "../../hooks/useSetTitle";
import IncorrectInfoContact from "../../components/IncorrectInfoContact";

function Settings() {
    const [activeTab, setActiveTab] = useState(0);

    useSetTitle("Settings");

    const handleSwitchTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div style={{ padding: 13 }}>
            <div className="top-bar">
                <IncorrectInfoContact />
            </div>
            <div className="settings-page fz-14">
                <div className="my-4">
                    <h2 className="fz-20 fw-bold">Settings</h2>
                    <div className="fz-14">
                        Manage your account settings and preferences.
                    </div>
                </div>

                <div className="contents-container">
                    <div>
                        <div className="tab-panel">
                            <div
                                className={`tab-panel__item${
                                    activeTab === 0 ? " active" : ""
                                }`}
                                onClick={() => handleSwitchTab(0)}
                            >
                                <div
                                    className="text-start"
                                    style={{ marginLeft: 40 }}
                                >
                                    Profile
                                </div>
                            </div>
                            {/* <div
							className={`tab-panel__item${
								activeTab === 0 ? " active" : ""
							}`}
							onClick={() => handleSwitchTab(0)}
						>
							<div className="title">Profile</div>
						</div>
						<div
							className={`tab-panel__item${
								activeTab === 1 ? " active" : ""
							}`}
							onClick={() => handleSwitchTab(1)}
						>
							<div className="title">Security</div>
						</div>
						<div
							className={`tab-panel__item${
								activeTab === 2 ? " active" : ""
							}`}
							onClick={() => handleSwitchTab(2)}
						>
							<div className="title">Billing</div>
						</div> */}
                            <div
                                className="tab-underline"
                                style={{
                                    left:
                                        activeTab === 0
                                            ? 0
                                            : activeTab === 1
                                            ? 140
                                            : 280,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="setting-contents border-top">
                        <SettingsProfile />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
