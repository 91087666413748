import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import "./ProfileAndSettings.scss";
import { avatarUrl } from "../../api/apiRoot";
import { ApiCalls } from "../../api/allApiCalls";
import { browserStore, formatSocialSecurityNumber } from "../../utils";
import { updateName } from "../../states/userInfoSlice";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import EditIcon from "../../assets/images/EditIcon.svg";

const initialValues = {
	firstName: "",
	lastName: "",
	age: "",
	gender: "",
	cell: "",
	ssn: "",
};

function BasicInformation() {
	const {
		masterId,
		userId,
		practiceId,
		patientId,
		custId,
		emailId,
		firstName,
		lastName,
	} = useSelector((state) => state.userInfo);
	const [data, setData] = useState(initialValues);
	const [update, setUpdate] = useState(false);
	const [userData, setUserData] = useState(initialValues);
	const [isEditing, setIsEditing] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const getPatientData = async () => {
			try {
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/PatientData",
					{
						masterId,
						userId,
						practiceId,
						patientId,
					}
				);
				let gender = res[0].gender;
				if (gender.toLowerCase().includes("f")) {
					gender = "Female";
				} else if (gender.toLowerCase().includes("m")) {
					gender = "Male";
				}
				const dataToStore = {
					...res[0],
					ssn: formatSocialSecurityNumber(res[0].ssn),
					gender,
				};
				setUserData(dataToStore);
				setData(dataToStore);
			} catch (err) {
				console.log(err);
			}
		};

		getPatientData();
	}, [update]);

	const handleOnchange = (e) => {
		const name = e.target.name;
		let value = e.target.value;

		if (name === "cell") {
			value = value.replace(/[^0-9]/g, "").slice(0, 10);
		}
		if (name === "age") {
			value = value.replace(/[^0-9]/g, "").slice(0, 2);
		}

		setUserData({ ...userData, [name]: value });
	};

	const handleBasicInformation = async () => {
		try {
			const res = await ApiCalls.authPutAPI("/v2/Patient/PatientData", {
				masterId,
				userId,
				custId,
				patientId,
				practiceId,
				firstName: userData.firstName,
				lastName: userData.lastName,
				age: userData.age,
				gender: userData.gender,
				email: emailId,
				phoneNumber: userData.cell,
				ssn: userData.ssn,
			});

			if (res.isSuccess) {
				setUpdate(!update);
				Swal.fire({
					text: "Profile Updated Successfully!",
					icon: "success",
				});
				// update name in the menu bar and sessionStorage
				if (
					firstName !== userData.firstName ||
					lastName !== userData.lastName
				) {
					const oldUserInfo = browserStore.get("userInfo");
					browserStore.set("userInfo", {
						...oldUserInfo,
						firstName: userData.firstName,
						lastName: userData.lastName,
					});
					dispatch(
						updateName({
							firstName: userData.firstName,
							lastName: userData.lastName,
						})
					);
				}
			} else {
				Swal.fire({
					text: "Something wrong happened. Please try again later",
					icon: "error",
				});
			}
			trackAcvitity(
				itemType.SETTINGS,
				itemName.UPDATE_PROFILE_INFO,
				"Profile & Settings >> Edit 'Basic Information' >> Save"
			);
		} catch (err) {
			console.log(err);
		} finally {
			setIsEditing(false);
		}
	};

	const handleCancel = () => {
		setIsEditing(false);
		setUserData(data);
	};

	return (
		<div>
			<div className="profile-header">
				<h2>Basic Information</h2>
				<div
					className="d-flex align-items-center"
					role="button"
					onClick={() => setIsEditing(true)}
					hidden={isEditing ? true : false}
				>
					<img
						src={EditIcon}
						style={{ height: 20, marginRight: 5 }}
					/>
					Edit
				</div>
			</div>
			<div className="d-flex flex-wrap" style={{ gap: 32 }}>
				<div className="avatar">
					<img
						alt="user avatar"
						src={avatarUrl(userData.firstName)}
						className="user-avatar"
						style={{
							height: 100,
							width: 100,
							borderRadius: "100%",
							boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
						}}
					/>
				</div>
				<div className="inputs-container flex-1 min-width-350">
					<div className="input-flex-item-1">
						<label>First Name</label>
						<input
							disabled={isEditing ? false : true}
							value={userData.firstName}
							name="firstName"
							onChange={handleOnchange}
						/>
					</div>
					<div className="input-flex-item-2">
						<label>Last Name</label>
						<input
							disabled={isEditing ? false : true}
							value={userData.lastName}
							name="lastName"
							onChange={handleOnchange}
						/>
					</div>
					<div className="input-flex-item-3">
						<div className="flex-g">
							<label>Age</label>
							<input
								disabled={isEditing ? false : true}
								value={userData.age}
								name="age"
								onChange={handleOnchange}
							/>
						</div>
						<div className="flex-g">
							<label>Gender</label>
							<select
								disabled={isEditing ? false : true}
								value={userData.gender}
								name="gender"
								onChange={handleOnchange}
							>
								<option value="Female">Female</option>
								<option value="Male">Male</option>
							</select>
						</div>
					</div>

					<div className="input-flex-item-5">
						<label>Phone Number</label>
						<input
							disabled={isEditing ? false : true}
							value={userData.cell}
							name="cell"
							onChange={handleOnchange}
						/>
					</div>
					<div className="input-flex-item-6">
						<label>Social Security Number</label>
						<input
							disabled={isEditing ? false : true}
							value={userData.ssn}
							name="ssn"
							onChange={handleOnchange}
						/>
					</div>

					{isEditing && (
						<div
							className="d-flex"
							style={{
								gap: 24,
								height: 36,
								alignSelf: "flex-end",
							}}
						>
							<button
								className="button button-primary"
								type="submit"
								onClick={handleBasicInformation}
							>
								Save Changes
							</button>
							<button
								className="button button-secondary"
								onClick={handleCancel}
							>
								Cancel
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default BasicInformation;
