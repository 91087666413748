import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { process } from "@progress/kendo-data-query";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import "./Messages.scss";
import useSetTitle from "../../hooks/useSetTitle";
import MessagesTabPanel from "./MessagesTabPanel";
import ComposeMailForm from "./ComposeMailForm";
import { ApiCalls } from "../../api/allApiCalls";
import MessagesTable from "./MessagesTable";
import { INBOX, SENT_MAIL, initTableState } from "./messagesHelpers";
import MessageDetails from "./MessageDetails";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";
import IncorrectInfoContact from "../../components/IncorrectInfoContact";
import { formatISOtoShortMonthDate } from "../../utils";
//import SaigeButton from "../../components/SAIGE/SaigeButton";
import { VETERINARY } from "../../utils";
import PetSelect from "../../components/selects/PetSelect";

function Messages() {
    const { masterId, userId, practiceId, patientId, specialty } = useSelector(
        (state) => state.userInfo
    );
    const { selectedPet } = useSelector((state) => state.petInfo);
    const [openComposeMailModal, setOpenComposeMailModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0); // 0 - Inbox, 1 - Sent mail
    const [selectedMail, setSelectedMail] = useState(null); // Selected mail to see details
    const [searchTerm, setSearchTerm] = useState("");

    // INBOX STATES
    const [inboxes, setInboxes] = useState([]); // Original data from API
    const [inboxData, setInboxData] = useState(process([], initTableState)); // Current Page data
    const [inboxState, setInboxState] = useState(initTableState); // Table state
    const [inboxCheckAll, setInboxCheckAll] = useState(false); // Check all inboxes of current page
    const [selectedInboxCount, setSelectedInboxCount] = useState(0); // Selected inbox count in the current page
    const [loadingInbox, setLoadingInbox] = useState(true);
    const [updateInbox, setUpdateInbox] = useState(false);
    // SENT MAIL STATES
    const [sentMails, setSentMails] = useState([]); // Original data from API
    const [sentMailData, setSentMailData] = useState(
        process([], initTableState)
    );
    const [sentMailState, setSentMailState] = useState(initTableState); // Table state
    const [sentMailCheckAll, setSentMailCheckAll] = useState(false); // Check all Sent Mail of current page
    const [selectedSentMailCount, setSelectedSentMailCount] = useState(0); // Selected Sent Mail count in the current page
    const [loadingSentMail, setLoadingSentMail] = useState(true);
    const [updateSentMail, setUpdateSentMail] = useState(false);

    // Set Page Title
    useSetTitle("Messages");

    // Check all the rows to see if all of them are selected. If so, update inboxCheckAll state to true, else false
    const updateInboxAllCheckbox = (dataArray) => {
        const nonSelected = dataArray.find((item) => !item.selected);
        if (!nonSelected && dataArray.length > 0) {
            setInboxCheckAll(true);
        } else {
            setInboxCheckAll(false);
        }
    };
    // Check all the rows to see if all of them are selected. If so, update inboxCheckAll state to true, else false
    const updateSentMailAllCheckbox = (dataArray) => {
        const nonSelected = dataArray.find((item) => !item.selected);
        if (!nonSelected && dataArray.length > 0) {
            setSentMailCheckAll(true);
        } else {
            setSentMailCheckAll(false);
        }
    };

    // FETCH INBOX
    useEffect(() => {
        const fetchInbox = async () => {
            try {
                setLoadingInbox(true);
                const res = await ApiCalls.authGetAPI(
                    "/v2/Patient/CommunicationData",
                    {
                        masterId,
                        userId,
                        practiceId,
                        patientId:
                            specialty === VETERINARY
                                ? selectedPet.patientId
                                : patientId,
                        userType: "dentist",
                    }
                );
                const formatedData = res.map((i) => ({
                    ...i,
                    selected: false,
                    formattedDate: formatISOtoShortMonthDate(i.date),
                }));
                setInboxes(formatedData); // original data
                const tableData = process(formatedData, {
                    ...initTableState,
                    take: inboxState.take,
                });
                setInboxData(tableData); // table data
                setInboxState((prev) => ({
                    ...initTableState,
                    take: prev.take,
                }));
                updateInboxAllCheckbox(tableData.data);
            } catch (err) {
                console.log(err);
            } finally {
                setLoadingInbox(false);
            }
        };

        if (specialty === VETERINARY && !selectedPet) return;
        fetchInbox();
    }, [updateInbox, selectedPet]);

    // FETCH SENT MAIL
    useEffect(() => {
        const fetchSentMail = async () => {
            try {
                setLoadingSentMail(true);
                const res = await ApiCalls.authGetAPI(
                    "/v2/Patient/CommunicationData",
                    {
                        masterId,
                        userId,
                        practiceId,
                        patientId:
                            specialty === VETERINARY
                                ? selectedPet.patientId
                                : patientId,
                        userType: "patient",
                    }
                );
                const formatedData = res.map((i) => ({
                    ...i,
                    selected: false,
                    formattedDate: formatISOtoShortMonthDate(i.date),
                }));
                setSentMails(formatedData); // original data
                const tableData = process(formatedData, {
                    ...initTableState,
                    take: sentMailState.take,
                });
                setSentMailData(tableData); // table data
                setSentMailState((prev) => ({
                    ...initTableState,
                    take: prev.take,
                }));
                updateSentMailAllCheckbox(tableData.data);
            } catch (err) {
                console.log(err);
            } finally {
                setLoadingSentMail(false);
            }
        };

        if (specialty === VETERINARY && !selectedPet) return;
        fetchSentMail();
    }, [updateSentMail, selectedPet]);

    // Count the selected conversations in the current page
    useEffect(() => {
        let recount = 0;
        inboxData.data.forEach((item) => {
            if (item.selected) {
                recount += 1;
            }
        });
        setSelectedInboxCount(recount);
    }, [inboxData]);

    useEffect(() => {
        let recount = 0;
        sentMailData.data.forEach((item) => {
            if (item.selected) {
                recount += 1;
            }
        });
        setSelectedSentMailCount(recount);
    }, [sentMailData]);

    // When user sort asc/desc in the table
    const onDataStateChange = (e, mailType) => {
        // update the table data
        if (mailType === INBOX) {
            const updatedTable = process(inboxes, e.dataState);
            setInboxData(updatedTable);
            setInboxState(e.dataState);
            updateInboxAllCheckbox(updatedTable.data); // update ALL
        } else {
            const updatedTable = process(sentMails, e.dataState);
            setSentMailData(updatedTable);
            setSentMailState(e.dataState);
            updateSentMailAllCheckbox(updatedTable.data); // update ALL
        }
    };

    // When user clicks on ALL in the header
    const handleCheckAll = (mailType) => {
        // Update table data && original data
        switch (mailType) {
            case INBOX: {
                const updatedTable = inboxData.data.map((item) => ({
                    ...item,
                    selected: inboxCheckAll ? false : true,
                }));
                setInboxData((prev) => ({ ...prev, data: updatedTable }));
                setInboxes((prev) =>
                    prev.map((item) => {
                        const updatedItem = updatedTable.find(
                            (i) => i.messageId === item.messageId
                        );
                        if (updatedItem) {
                            return { ...item, selected: updatedItem.selected };
                        } else return item;
                    })
                );
                setInboxCheckAll(!inboxCheckAll);
                break;
            }
            case SENT_MAIL: {
                const updatedTable = sentMailData.data.map((item) => ({
                    ...item,
                    selected: sentMailCheckAll ? false : true,
                }));
                setSentMailData((prev) => ({ ...prev, data: updatedTable }));
                setSentMails((prev) =>
                    prev.map((item) => {
                        const updatedItem = updatedTable.find(
                            (i) => i.messageId === item.messageId
                        );
                        if (updatedItem) {
                            return { ...item, selected: updatedItem.selected };
                        } else return item;
                    })
                );
                setSentMailCheckAll(!sentMailCheckAll);
                break;
            }
        }
    };

    // When user clicks on a checkbox in a specific row
    const handleMailCheck = (messageId, mailType) => {
        // Update original inbox data && table data
        switch (mailType) {
            case INBOX: {
                let updatedInboxes = inboxes.map((i) => {
                    if (i.messageId === messageId) {
                        return { ...i, selected: !i.selected };
                    }
                    return i;
                });
                const updatedTable = process(updatedInboxes, inboxState);
                setInboxes(updatedInboxes);
                setInboxData(updatedTable);
                updateInboxAllCheckbox(updatedTable.data); // update ALL
                break;
            }
            case SENT_MAIL: {
                let updatedSentMails = sentMails.map((i) => {
                    if (i.messageId === messageId) {
                        return { ...i, selected: !i.selected };
                    }
                    return i;
                });
                const updatedTable = process(updatedSentMails, sentMailState);
                setSentMails(updatedSentMails);
                setSentMailData(updatedTable);
                updateSentMailAllCheckbox(updatedTable.data); // update ALL
                break;
            }
        }
    };

    const deleteMessages = async (originalData, tableData, mailType) => {
        const deleteAPICall = async (messageIds) => {
            const res = await ApiCalls.authDeleteAPI(
                "/v2/Patient/CommunicationData",
                {
                    masterId,
                    userId,
                    patientId:
                        specialty === VETERINARY
                            ? selectedPet.patientId
                            : patientId,
                    practiceId,
                    messageId: messageIds.join(),
                }
            );
            trackAcvitity(
                itemType.MESSAGES,
                itemName.DELETE_MESSAGES,
                "Messages page >> Select messages >> Click Delete icon >> Confirm to delete message/s"
            );
            return res;
        };
        // if the total selected is larger than current page's selected, ask user to delete current page only or all selected
        let totalSelected = [];
        let currentSelected = [];
        originalData.forEach((i) => {
            if (i.selected) totalSelected.push(i.messageId);
        });
        tableData?.data?.forEach((i) => {
            if (i.selected) currentSelected.push(i.messageId);
        });
        if (totalSelected.length > currentSelected.length) {
            Swal.fire({
                icon: "question",
                text: `Would you like to delete only the conversations on the current page (${currentSelected.length} items) or all the selected conversations (${totalSelected.length} items)?`,
                showConfirmButton: true,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "All",
                denyButtonText: "Current page",
                cancelButtonText: "Dismiss",
            }).then(async (res) => {
                let result = null;
                if (res.isConfirmed) {
                    result = await deleteAPICall(totalSelected);
                } else if (res.isDenied) {
                    result = await deleteAPICall(currentSelected);
                } else {
                    return;
                }
                if (
                    !result?.isSuccess ||
                    result?.message?.toLowerCase() !== "successful"
                ) {
                    Swal.fire({
                        icon: "error",
                        text: "Something wrong happened. Please try again later.",
                    });
                }
                if (mailType === INBOX) {
                    setUpdateInbox(!updateInbox);
                } else {
                    setUpdateSentMail(!updateSentMail);
                }
            });
        } else {
            Swal.fire({
                icon: "warning",
                text: "Click 'Confirm' to delete the selected conversations.",
                showCancelButton: true,
                cancelButtonText: "Dismiss",
                showConfirmButton: true,
                confirmButtonText: "Confirm",
            }).then(async (res) => {
                if (res.isConfirmed) {
                    const result = await deleteAPICall(currentSelected);
                    if (
                        !result?.isSuccess ||
                        result?.message?.toLowerCase() !== "successful"
                    ) {
                        Swal.fire({
                            icon: "error",
                            text: "Something wrong happened. Please try again later.",
                        });
                    }
                    if (mailType === INBOX) {
                        setUpdateInbox(!updateInbox);
                    } else {
                        setUpdateSentMail(!updateSentMail);
                    }
                }
            });
        }
    };

    const handleRemove = (mailType) => {
        if (mailType === INBOX) {
            deleteMessages(inboxes, inboxData, mailType);
        } else {
            deleteMessages(sentMails, sentMailData, mailType);
        }
    };

    // When user clicks on "Select all conversations in Inbox/Sent Mail"
    const selectAllConversations = (mailType, action) => {
        // update inboxes and the current page data
        if (mailType === INBOX) {
            const updatedInboxes = inboxes.map((i) => ({
                ...i,
                selected: action === "select" ? true : false,
            }));
            const updatedTable = process(updatedInboxes, inboxState);
            setInboxes(updatedInboxes);
            setInboxData(updatedTable);
            updateInboxAllCheckbox(updatedTable.data);
        } else {
            const updatedSentMails = sentMails.map((i) => ({
                ...i,
                selected: action === "select" ? true : false,
            }));
            const updatedTable = process(updatedSentMails, sentMailState);
            setSentMails(updatedSentMails);
            setSentMailData(updatedTable);
            updateSentMailAllCheckbox(updatedTable.data);
        }
    };

    // When user clicks on a row to see the message contents
    const handleClickRow = (messageId) => {
        setSelectedMail(messageId);
    };

    useEffect(() => {
        setSearchTerm("");
    }, [activeTab]);

    useEffect(() => {
        const state =
            activeTab === 0 ? { ...inboxState } : { ...sentMailState };
        state["filter"] = {
            filters: [
                {
                    logic: "and",
                    filters: [
                        {
                            field: activeTab === 0 ? "fromName" : "toName",
                            operator: "contains",
                            value: searchTerm,
                        },
                    ],
                },
                {
                    logic: "and",
                    filters: [
                        {
                            field: "subject",
                            operator: "contains",
                            value: searchTerm,
                        },
                    ],
                },
                {
                    logic: "and",
                    filters: [
                        {
                            field: "formattedDate",
                            operator: "contains",
                            value: searchTerm,
                        },
                    ],
                },
            ],
            logic: "or",
        };
        state["skip"] = 0;
        state["take"] = 20;

        // Update table data and state
        const newInboxData = process(inboxes, state);
        setInboxData(newInboxData);
        setInboxState(state);
        updateInboxAllCheckbox(newInboxData.data);

        const newSentMailData = process(sentMails, state);
        setSentMailData(newSentMailData);
        setSentMailState(state);
        updateSentMailAllCheckbox(newSentMailData.data);
    }, [searchTerm]);

    return (
        <div style={{ padding: 13 }}>
            <div className="top-bar">
                {/* <SaigeButton /> */}
                <IncorrectInfoContact />
            </div>
            <div className="messages-page">
                <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                    <h1
                        className="font-black fw-bold mt-2"
                        style={{ fontSize: 25 }}
                    >
                        Your Conversations
                    </h1>
                    <button
                        className="button button-primary"
                        onClick={() => {
                            setOpenComposeMailModal(true);
                            trackAcvitity(
                                itemType.MESSAGES,
                                itemName.OPEN_COMPOSE_MAIL_MODAL,
                                "Messages page >> Click 'Compose Mail' button to open the modal"
                            );
                        }}
                    >
                        {selectedMail ? "Reply" : "Compose Mail"}
                    </button>
                </div>

                {specialty === VETERINARY && (
                    <div className="mb-4">
                        <PetSelect />
                    </div>
                )}

                {!selectedMail && (
                    <div className="fz-16">
                        {/* TAB PANEL */}
                        <div
                            className="d-flex flex-wrap justify-content-between align-items-center"
                            style={{ rowGap: 16 }}
                        >
                            <MessagesTabPanel
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                            <div
                                className="position-relative search-bar"
                                style={{ width: "min(100%, 500px)" }}
                            >
                                <SearchIcon
                                    style={{
                                        position: "absolute",
                                        left: 6,
                                        top: 6,
                                    }}
                                />
                                <input
                                    placeholder="Search by any keyword..."
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    style={{ paddingLeft: 35 }}
                                />
                                {searchTerm && (
                                    <ClearIcon
                                        style={{
                                            color: "#681c9a",
                                            position: "absolute",
                                            right: 6,
                                            top: 6,
                                        }}
                                        className="clearIcon"
                                        onClick={() => setSearchTerm("")}
                                    />
                                )}
                            </div>
                        </div>

                        {/* TABLE */}
                        <div className="mt-3">
                            <div className="position-relative border border-radius-6">
                                {activeTab === 0 && (
                                    <MessagesTable
                                        selectedCount={selectedInboxCount}
                                        handleRemove={handleRemove}
                                        data={inboxData}
                                        onDataStateChange={onDataStateChange}
                                        state={inboxState}
                                        handleMailCheck={handleMailCheck}
                                        checkAll={inboxCheckAll}
                                        handleCheckAll={handleCheckAll}
                                        mailType={INBOX}
                                        loading={loadingInbox}
                                        selectAllConversations={
                                            selectAllConversations
                                        }
                                        originalData={inboxes}
                                        handleClickRow={handleClickRow}
                                    />
                                )}
                                {activeTab === 1 && (
                                    <MessagesTable
                                        selectedCount={selectedSentMailCount}
                                        handleRemove={handleRemove}
                                        data={sentMailData}
                                        onDataStateChange={onDataStateChange}
                                        state={sentMailState}
                                        handleMailCheck={handleMailCheck}
                                        checkAll={sentMailCheckAll}
                                        handleCheckAll={handleCheckAll}
                                        mailType={SENT_MAIL}
                                        loading={loadingSentMail}
                                        selectAllConversations={
                                            selectAllConversations
                                        }
                                        originalData={sentMails}
                                        handleClickRow={handleClickRow}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {selectedMail && (
                    <MessageDetails
                        selectedMail={selectedMail}
                        handleGoBack={() => setSelectedMail(null)}
                    />
                )}

                {openComposeMailModal && (
                    <ComposeMailForm
                        openModal={openComposeMailModal}
                        closeModal={() => setOpenComposeMailModal(false)}
                        updateSentMail={() =>
                            setUpdateSentMail(!updateSentMail)
                        }
                    />
                )}
            </div>
        </div>
    );
}

export default Messages;
