import { configureStore } from "@reduxjs/toolkit";
import userInfoReducer from "./userInfoSlice";
import authReducer from "./authSlice";
import pageLoaderReducer from "./pageLoaderSlice";
import divLoaderReducer from "./divLoaderSlice";
import practiceInfoReducer from "./practiceInfoSlice";
import petInfoSlice from "./petInfoSlice";

export default configureStore({
    reducer: {
        userInfo: userInfoReducer,
        authentication: authReducer,
        pageLoader: pageLoaderReducer,
        divLoader: divLoaderReducer,
        practiceInfo: practiceInfoReducer,
        petInfo: petInfoSlice,
    },
});
