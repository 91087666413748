import React from "react";
import { Navigate } from "react-router-dom";
import { browserStore } from "../utils";

function ProtectedRoute({ children }) {
	const isAuthenticated =
		browserStore.get("userInfo")?.apiToken &&
		browserStore.get("userInfo")?.patientId &&
		browserStore.get("userInfo")?.authenticated;

	if (!isAuthenticated) {
		return <Navigate to="/login" replace />;
	} else {
		return children;
	}
}

export default ProtectedRoute;
