/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./AdditionalInformation.scss";
import sikka_logo_transparent from "../../assets/images/sikka_logo_transparent.png";
import {
	// MULTIPLE_MATCHES,
	// NO_MATCH,
	// SINGLE_MATCH,
	storeRecordMatchingStatus,
} from "../../states/authSlice";
import { addEmailId } from "../../states/userInfoSlice";
import useSetTitle from "../../hooks/useSetTitle";

function AdditionalInformation() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { recordsMatched } = useSelector((state) => state.authentication);
	const [inputValues, setInputValues] = useState({
		email: "",
		phone: "",
		SSN: "",
	});
	const [matched, setMatched] = useState(null);

	useSetTitle("Authentication");

	useEffect(() => {
		// When the component mounted, check if the authentication status is "Multiple matches".
		// If not, navigate back to authentication
		// if (recordsMatched !== MULTIPLE_MATCHES)
		// 	navigate("/authentication", { replace: true });
	}, []);

	const handleChange = () => {};
	const handleSubmit = (e) => {
		e.preventDefault();
		// if one matched >> verification page
		// dispatch(addEmailId("jennifer@gmail.com"));
		// dispatch(storeRecordMatchingStatus(SINGLE_MATCH));
		// navigate("/authentication/verification");

		// if NOT matched >> Followup page
		// dispatch(storeRecordMatchingStatus(NO_MATCH));
		// navigate("/authentication/follow-up");
	};

	const handleClickFolloup = () => {
		navigate("/follow-up");
	};

	return (
		<div className="additional-info-page">
			<div className="additional-info-content-wrapper">
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 36, marginBottom: "1.5rem" }}
				/>
				<div className="font-purple my-3">
					Looks like we need a little more information
				</div>
				<form className="additional-info-form" onSubmit={handleSubmit}>
					<div className="additional-info-form__input">
						<label>Enter Your Email ID *</label>
						<input
							name="email"
							placeholder="Email ID"
							value={inputValues.email}
							onChange={handleChange}
						/>
					</div>
					<div className="additional-info-form__input">
						<label>Enter Your Contact Number *</label>
						<input
							name="phone"
							placeholder="Phone Number"
							value={inputValues.phone}
							onChange={handleChange}
						/>
					</div>
					<div className="additional-info-form__input">
						<label>Enter Your SSN *</label>
						<input
							name="ssn"
							placeholder="Social Security Number"
							value={inputValues.email}
							onChange={handleChange}
						/>
					</div>

					<button
						className="button button-primary mt-2 width-100p"
						type="submit"
					>
						Submit
					</button>
				</form>
			</div>
		</div>
	);
}

export default AdditionalInformation;
