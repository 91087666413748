import React from "react";
import PieChart, { Series, Size } from "devextreme-react/pie-chart";
import { AdaptiveLayout } from "devextreme-react/chart";

import "./PatientFinances.scss";
import useFetchFinances from "../../../hooks/useFetchFinances";
import { DENTAL, toCurrencyConverter } from "../../../utils";
import CardLoader from "../../../components/loaders/CardLoader";
import { useSelector } from "react-redux";

// amount paid: "#681c9a"
// benefits available "#f7b500"
// balance remaining "#EBEFF7"
const colors = ["#681c9a", "#f7b500", "#EBEFF7"];
function FinancialsChartCenterTemplate(total) {
	return (
		<svg>
			<circle cx="100" cy="100" fill="#eee"></circle>
			<text
				className="fw-bold"
				textAnchor="middle"
				x="100"
				y="120"
				style={{ fontSize: 18, fill: "#494949" }}
			>
				<tspan x="100" dy="25px">
					{toCurrencyConverter(total)}
				</tspan>
			</text>
		</svg>
	);
}

function PatientFinances() {
	const { specialty } = useSelector((state) => state.userInfo);
	const [loading, data] = useFetchFinances();

	return (
		<div className="PatientFinances p-3 d-flex flex-column height-100p position-relative">
			{loading && <CardLoader />}
			<div className="PatientFinances-container d-flex gap-5 align-items-center justify-content-center">
				<PieChart
					id="patient-profile-financials-chart"
					type="doughnut"
					palette={data ? colors : ["#f5f8ff"]}
					dataSource={
						data
							? [
									{
										name: "Amount Paid",
										val: data.amountPaid,
									},
									{
										name: "Benefits Available",
										val: data.benefitsAvailable,
									},
									{
										name: "Balance Remaining",
										val: data.balanceRemaining,
									},
							  ]
							: [
									{
										name: "",
										val: 0,
									},
							  ]
					}
					innerRadius={0.7}
					centerRender={() =>
						FinancialsChartCenterTemplate(
							data ? data.procedureTotal : 0
						)
					}
				>
					<AdaptiveLayout height={180} width={180} />
					<Size height={data ? 280 : 200} width={260} />
					<Series argumentField="name"></Series>
				</PieChart>

				<div className="PatientFinances-details">
					{data && (
						<div style={{ color: "#848484", lineHeight: 2 }}>
							<div className="legend-item">
								<div className="legend-block amt-paid"></div>
								Amount Paid:{" "}
								{toCurrencyConverter(data.amountPaid)}
							</div>
							{specialty === DENTAL && (
								<div className="legend-item">
									<div className="legend-block benefits-remain"></div>
									Benefits Available:{" "}
									{toCurrencyConverter(
										data.benefitsAvailable
									)}
								</div>
							)}
							<div className="legend-item">
								<div className="legend-block balance"></div>
								Balance Remaining:{" "}
								{toCurrencyConverter(data.balanceRemaining)}
							</div>
						</div>
					)}
					{!data && <div>No record available</div>}
				</div>
			</div>
		</div>
	);
}

export default PatientFinances;
