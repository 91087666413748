import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import Modal from "../modals/Modal";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import { useNavigate } from "react-router-dom";

function LogoutNavLink({ drawer }) {
	const [openModal, setOpenModal] = useState(false);
	const navigate = useNavigate();

	const handleLogout = () => {
		trackAcvitity(
			itemType.LOGOUT,
			itemName.LOGOUT,
			"Click on Logout in menu bar >> Confirm Logout"
		);
		navigate("/logout");
		setOpenModal(false);
	};

	return (
		<>
			<a
				className={`${drawer ? "nav-item" : ""}`}
				onClick={() => setOpenModal(true)}
				role="button"
			>
				<LogoutIcon />
				Logout
			</a>
			{openModal && (
				<Modal
					openModal={openModal}
					closeModal={() => setOpenModal(false)}
				>
					<div className="px-4 py-2">
						<h2
							className="fz-20 mb-4 text-center"
							style={{ color: "#242424" }}
						>
							Are you sure you want to logout?
						</h2>
						<div className="d-flex justify-content-center gap-3">
							<button
								className="button button-primary"
								onClick={handleLogout}
							>
								Yes, I am sure
							</button>
							<button
								className="button button-secondary"
								onClick={() => setOpenModal(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
}

export default LogoutNavLink;
