import React from "react";
import InfoIcon from "@mui/icons-material/Info";

import heartIcon from "../../assets/images/heartIcon.svg";
import handIcon from "../../assets/images/handIcon.svg";

import { CustomTooltip } from "./customeTooltip";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import { DENTAL } from "../../utils";

const SikkaScoresTooltipContent = (
	<div className="d-flex flex-column gap-4" style={{ fontSize: "13px" }}>
		<div className="d-flex align-items-start">
			<img alt="" src={heartIcon} style={{ width: "50px" }} />
			<div className="mt-2 ms-4">
				<h4 className="fz-16 text-dark">Health Risk Score:</h4>
				<div style={{ color: "#414d55" }}>
					Studies have found that there is a direct correlation
					between your oral health and your overall physical health.
					This score represents what your overall health risk is based
					on your dental history. The closer a score is to 100, the
					higher overall health risk, which may impact certain things,
					like the ability to receive life insurance. You can work to
					lower your health risk score by practicing consistent oral
					hygiene and following your dentist&apos;s recommended
					treatment plans.
				</div>
			</div>
		</div>

		<div className="d-flex align-items-start">
			<img alt="" src={handIcon} style={{ width: "50px" }} />
			<div className="mt-2 ms-4">
				<h4 className="fz-18 text-dark">Economic Well-Being Score:</h4>
				<div style={{ color: "#414d55" }}>
					This score represents how consistent you are at paying for
					treatment within a 60 day time frame (based on your payment
					history). A score closer to 100 shows there is a higher
					likelihood you will pay own time. Having a high economic
					well being score can be helpful for practices when making
					decisions around offering payment plans and financing
					options.
				</div>
			</div>
		</div>
	</div>
);
const PPEWSTooltipContent = (
	<div className="d-flex flex-column gap-4" style={{ fontSize: "13px" }}>
		<div className="d-flex align-items-start">
			<div className="mt-2 ms-4">
				<h4 className="fz-18 text-dark">
					Pet Parent&apos;s Economic Well-Being Score (PPEWS):
				</h4>
				<div style={{ color: "#414d55" }}>
					PPEWS represents a pet parent&apos;s economic well-being
					score. Based on a range between 0 and 100, PPEWS predicts
					the likelihood a pet parent will pay the full balance for
					their most recent visit within 60 days. A higher score
					indicates a higher chance a pet parent will pay within that
					timeframe.
				</div>
			</div>
		</div>
	</div>
);

function SikkaScoresTooltip({ specialty }) {
	return (
		<CustomTooltip
			title={
				specialty === DENTAL
					? SikkaScoresTooltipContent
					: PPEWSTooltipContent
			}
		>
			<InfoIcon
				className="font-purple ms-2"
				fontSize="small"
				onMouseEnter={() =>
					trackAcvitity(
						itemType.DESCRIPTION,
						itemName.HOVER_SIKKA_SCORE_TOOLTIP,
						"Home >> Hover on Sikka Score tooltip"
					)
				}
			/>
		</CustomTooltip>
	);
}

export default SikkaScoresTooltip;
