import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { CustomTooltip } from "./customeTooltip";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";

function HealthConditionsTooltip() {
	const HealthConditionsContent = (
		<div className="d-flex align-items-start" style={{ fontSize: "13px" }}>
			<div className="mt-2 ms-4">
				<h4 className="fz-18 text-dark">Health Conditions:</h4>
				<div style={{ color: "#414d55" }}>
					This ai tool identifies pre-existing health conditions a
					patient may have based on their dental history and clinical
					notes. These conditions are merely suggested based on
					findings in the clinical notes, and not to be taken as an
					actual diagnosis. Confirmation of these conditions can only
					be done by the Doctor.
				</div>
			</div>
		</div>
	);

	return (
		<CustomTooltip title={HealthConditionsContent}>
			<InfoIcon
				className="font-purple ms-2"
				fontSize="small"
				onMouseEnter={() =>
					trackAcvitity(
						itemType.DESCRIPTION,
						itemName.HOVER_HEALTH_CONDITIONS_TOOLTIP,
						"Home >> Hover one Health Conditions Tooltip"
					)
				}
			/>
		</CustomTooltip>
	);
}

export default HealthConditionsTooltip;
