export const petInformationData = [
	{
		petName: "Spook",
		species: "dog",
		breed: "Domestic",
		age: 10,
		gender: "Male",
	},
	{
		petName: "Lacey",
		species: "cat",
		breed: "Domestic long hair",
		age: 3,
		gender: "Female",
	},
];

export const weightTrackingData = [
	{
		weight: 12,
		unit: "lbs",
		date: "10/13/2023",
	},
	{
		weight: 10,
		unit: "lbs",
		date: "10/10/2023",
	},
	{
		weight: 11,
		unit: "lbs",
		date: "09/02/2023",
	},
];

export const vaccinesData = [
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Expired",
	},
	{
		pet: "Spook",
		vaccine: "FeLV (1yr)",
		date: "09/02/2023",
		status: "Expiring soon",
	},
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Vacinated",
	},
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Vacinated",
	},
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Vacinated",
	},
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Vacinated",
	},
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Vacinated",
	},
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Vacinated",
	},
	{
		pet: "Spook",
		vaccine: "Rabies (1yr)",
		date: "09/02/2023",
		status: "Vacinated",
	},
];

import brushing_sessions_1 from "./assets/images/oral_B/brushing_sessions_1.png";
import brushing_sessions_2 from "./assets/images/oral_B/brushing_sessions_2.png";
import brushing_sessions_3 from "./assets/images/oral_B/brushing_sessions_3.png";
import brushing_sessions_4 from "./assets/images/oral_B/brushing_sessions_4.png";
import brushing_sessions_5 from "./assets/images/oral_B/brushing_sessions_5.png";
import brushing_sessions_6 from "./assets/images/oral_B/brushing_sessions_6.png";
import brushing_sessions_7 from "./assets/images/oral_B/brushing_sessions_7.png";
import brushing_sessions_8 from "./assets/images/oral_B/brushing_sessions_8.png";

export const brushingSessionsData = [
	{
		date: "4/6/2023",
		src: brushing_sessions_1,
	},
	{
		date: "4/5/2023",
		src: brushing_sessions_2,
	},
	{
		date: "4/4/2023",
		src: brushing_sessions_3,
	},
	{
		date: "4/3/2023",
		src: brushing_sessions_4,
	},
	{
		date: "4/2/2023",
		src: brushing_sessions_5,
	},
	{
		date: "4/1/2023",
		src: brushing_sessions_6,
	},
	{
		date: "3/31/2023",
		src: brushing_sessions_7,
	},
	{
		date: "3/30/2023",
		src: brushing_sessions_8,
	},
];

export const pets = [
	{ petName: "Spook" },
	{ petName: "Spook" },
	{ petName: "Spook" },
	{ petName: "Spook" },
];

export const inboxData = [
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "John Doe Dr",
		subject: "Online Appoinment Request",
		date: "2022-03-13",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
	{
		from: "Vijay Sikka",
		subject: "Online Appoinment Request",
		date: "2022-03-12",
	},
];

export const faqData = [
	{
		question:
			"Q - Question one related to the topic or specific industry would go here in maximum of one or two lines?",
		answer: "A - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
	},
	{
		question:
			"Q - Question one related to the topic or specific industry would go here in maximum of one or two lines?",
		answer: "A - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
	},
	{
		question:
			"Q - Question one related to the topic or specific industry would go here in maximum of one or two lines?",
		answer: "A - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
	},
	{
		question:
			"Q - Question one related to the topic or specific industry would go here in maximum of one or two lines?",
		answer: "A - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
	},
];

export const treatmentPlanData = [
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Not Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Not Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
	{
		provider: "20-Dr. Jones DDH",
		procCode: 4910,
		date: "Aug 10, 2023",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
		fromTooth: 0,
		toTooth: 0,
		amount: 1023,
		status: "Scheduled",
	},
];

export const transactionHistoryData = [
	{
		date: "2022-07-22",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 155,
	},
	{
		date: "2022-07-22",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 190,
	},
	{
		date: "2022-07-05",
		paymentType: "Ins",
		description: "Anniversity Coupon Discount $50.00",
		amount: 50,
	},
	{
		date: "2022-02-11",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 250.75,
	},
	{
		date: "2022-02-08",
		paymentType: "Pay",
		description: "Cash Payment' Thank You",
		amount: 10,
	},
	{
		date: "2022-01-11",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 250.75,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 510.7,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 344.8,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 239,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 441,
	},
	{
		date: "2021-12-31",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 242,
	},
	{
		date: "2021-12-31",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 239,
	},
	{
		date: "2021-12-17",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 89,
	},
	{
		date: "2021-12-17",
		paymentType: "Ins",
		description: "Anniversity Coupon Discount $50.00",
		amount: 50,
	},
	{
		date: "2021-08-10",
		paymentType: "Ins",
		description: "Anniversity Coupon Discount $50.00",
		amount: 50,
	},
	{
		date: "2021-03-09",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 150,
	},
	{
		date: "2021-03-09",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 186,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 128,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 148.4,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 16,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 174,
	},
	{
		date: "2021-01-23",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 184,
	},
	{
		date: "2020-12-28",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 40,
	},
	{
		date: "2020-07-24",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 177.6,
	},
	{
		date: "2020-07-18",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 191,
	},
	{
		date: "2022-07-22",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 155,
	},
	{
		date: "2022-07-22",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 190,
	},
	{
		date: "2022-07-05",
		paymentType: "Ins",
		description: "Anniversity Coupon Discount $50.00",
		amount: 50,
	},
	{
		date: "2022-02-11",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 250.75,
	},
	{
		date: "2022-02-08",
		paymentType: "Pay",
		description: "Cash Payment' Thank You",
		amount: 10,
	},
	{
		date: "2022-01-11",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 250.75,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 510.7,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 344.8,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 239,
	},
	{
		date: "2022-01-07",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 441,
	},
	{
		date: "2021-12-31",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 242,
	},
	{
		date: "2021-12-31",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 239,
	},
	{
		date: "2021-12-17",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 89,
	},
	{
		date: "2021-12-17",
		paymentType: "Ins",
		description: "Anniversity Coupon Discount $50.00",
		amount: 50,
	},
	{
		date: "2021-08-10",
		paymentType: "Ins",
		description: "Anniversity Coupon Discount $50.00",
		amount: 50,
	},
	{
		date: "2021-03-09",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 150,
	},
	{
		date: "2021-03-09",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 186,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 128,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 148.4,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 16,
	},
	{
		date: "2021-02-16",
		paymentType: "Ins",
		description: "Insurance Adjustment",
		amount: 174,
	},
	{
		date: "2021-01-23",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 184,
	},
	{
		date: "2020-12-28",
		paymentType: "Pay",
		description: "Check Payment' Thank You",
		amount: 40,
	},
	{
		date: "2020-07-24",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 177.6,
	},
	{
		date: "2020-07-18",
		paymentType: "Ins",
		description: "Insurance Payment",
		amount: 191,
	},
];

export const timelineData = [
	{
		item: "Patient's Last Appointment",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
		date: "Jan 01, 2222",
	},
	{
		item: "Patient's Last Appointment",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
		date: "Jan 01, 2222",
	},
	{
		item: "Patient's Last Appointment",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
		date: "Jan 01, 2222",
	},
	{
		item: "Patient's Last Appointment",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
		date: "Jan 01, 2222",
	},
	{
		item: "Patient's Last Appointment",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
		date: "Jan 01, 2222",
	},
	{
		item: "Patient's Last Appointment",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
		date: "Jan 01, 2222",
	},
];

export const procsData = [
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
		status: "Not Scheduled",
	},
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
		status: "Not Scheduled",
	},
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
		status: "Scheduled",
	},
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
		status: "Scheduled",
	},
];

export const proceduresDoneData = [
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
	},
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
	},
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
	},
	{
		procCode: 4910,
		procName: "Periodic Dental Evaluation",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		date: "Aug 01, 3333",
	},
];

export const guarantorData = [
	{
		firstName: "John",
		lastName: "Doe",
		email: "jdoe1634@gmail.com",
		phone: "253-222-1111",
		address: "Third St, apt #144, San Jose, CA 90000",
	},
	{
		firstName: "John",
		lastName: "Doe",
		email: "jdoe1634@gmail.com",
		phone: "253-222-1111",
		address: "Third St, apt #144, San Jose, CA 90000",
	},
];

export const educationData = [
	{
		headline: "Education Article Headline",
		date: "Jan 31, 2222",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
	{
		headline: "Education Article Headline",
		date: "Jan 31, 2222",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
	{
		headline: "Education Article Headline",
		date: "Jan 31, 2222",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
	{
		headline: "Education Article Headline",
		date: "Jan 31, 2222",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
	{
		headline: "Education Article Headline",
		date: "Jan 31, 2222",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
	{
		headline: "Education Article Headline",
		date: "Jan 31, 2222",
		description:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
];

export const settingsProfileData = {
	username: "JenniferBridge",
	password: "JenniferBridge",
	ssn: "###-##-####",
	practiceName: "Smile Dental",
	practiceNumber: "408-111-1111",
	practiceAddress: "1234 Street, San Jose, CA 90000",
};
