import React from "react";
import { formatISOtoShortMonthDate } from "../../../utils";

function SelectedCell({ dataItem, field, handleClickRow }) {
	return (
		<td
			className={`${
				dataItem.selected === true ? "bg-clr-light-purple " : ""
			}`}
			onClick={() => handleClickRow(dataItem)}
		>
			{dataItem[field]}
			{(field === "fromName" || field === "toName") && (
				<>
					<br />
					<span className="sm-date">
						{formatISOtoShortMonthDate(dataItem["date"])}
					</span>
				</>
			)}
		</td>
	);
}

export default SelectedCell;
