import React from "react";
import Modal from "./Modal";
import Swal from "sweetalert2";

function HIPPARulesModal({
	openModal,
	closeModal,
	formValues,
	setFormValues,
	setFormErrors,
}) {
	return (
		<Modal
			openModal={openModal}
			closeModal={() => {
				if (formValues.checked) {
					setFormErrors((prev) => ({ ...prev, checked: false }));
					closeModal();
				} else {
					Swal.fire({
						showCancelButton: true,
						cancelButtonText: "Close anyway",
						text: "Please check the box at the bottom of the document",
					}).then((res) => {
						if (res.isDismissed) {
							closeModal();
						}
					});
				}
			}}
		>
			<div
				className="p-3"
				style={{ maxHeight: "80dvh", overflowY: "auto" }}
			>
				<h2 className="fz-18 text-center mb-4">
					HIPAA Authorization Document
				</h2>
				<div className="fz-14">
					I authorize any health plan, physician, health care
					professional, hospital, clinic, laboratory, pharmacy or
					pharmacy benefit manager, insurance or reinsurance company,
					medical practitioner, medical facility, health information
					exchange, electronic health record provider, or other health
					care provider that has provided payment, treatment or
					services to me or on my behalf within the past 10 years (“My
					Providers”) to disclose protected health information under
					the Health Insurance Portability and Accountability Act of
					1996 (“HIPAA”) concerning me or my minor children to Sikka
					Software Corporation, and any of its subsidiaries. I further
					authorize the disclosure of my protected health information
					to Sikka Software Corporation, and other third party service
					providers that assist in making my information accessible to
					me. Protected health information includes: my entire medical
					record or medical history, which specifically includes any
					information regarding the diagnosis or treatment of mental
					illness (excluding psychotherapy notes), use or abuse of
					tobacco, alcohol, or drugs, prescription history, HIV/AIDS,
					sexually transmitted diseases, and genetic information and
					test results (to the extent permitted by law). Protected
					health information is to be disclosed under this
					Authorization so that Sikka Software Corporation can
					disclose my personal dental information to me as the patient
					of a practice on Sikka Patient Portal platform. This
					authorization shall remain in force for 24 months following
					the date of my signature below. I understand that I have the
					right to revoke this authorization in writing at any time,
					by sending a written request or revocation to the medical
					records/health information department at My Providers and/or
					to The Insurance Companies at their addresses listed on the
					attached page, and that such revocation will be effective as
					to each of My Providers when it is received by the Provider.
					I understand that a revocation is not effective to the
					extent that Sikka Software Corporation has already relied on
					this authorization to disclose or use protected health
					information about me. I understand that any information that
					is disclosed pursuant to this authorization may be
					re-disclosed and no longer covered by certain federal rules
					governing privacy and confidentiality of health information.
					I understand that if I refuse to sign this authorization
					Sikka Software Corporation may not be able to provide my
					medical information to me through their products or
					services. I acknowledge that I have received a copy of this
					authorization. I understand that a copy of this
					authorization shall be as valid as the Original.
				</div>
				<div className="d-flex gap-2 align-items-center mt-3 fz-14">
					<input
						type="checkbox"
						style={{ width: 16 }}
						checked={formValues.checked}
						onChange={() =>
							setFormValues((prev) => ({
								...prev,
								checked: !formValues.checked,
							}))
						}
					/>
					I acknowledge that I have reviewed the above information
				</div>
			</div>
		</Modal>
	);
}

export default HIPPARulesModal;
