import axios from "axios";
import { browserName } from "react-device-detect";

import { authHeader } from "./authHeader";
import { browserStore } from ".";
import { API_ROOT } from "../api/apiRoot";

const requestBody = () => {
    const userInfo = browserStore.get("userInfo");

    return {
        masterId: userInfo?.masterId,
        userId: userInfo?.userId,
        patientId: userInfo?.patientId,
        practiceId: userInfo?.practiceId,
        ipAddress: userInfo?.publicIP,
        domainName: userInfo?.specialty,
        accessBrowser: browserName,
        url: window.location.href,
    };
};

export const trackAcvitity = (itemType, itemName, itemFlow) => {
    if (window.location.href.includes("https://patientportal.sikkasoft.com")) {
        const url = API_ROOT + "/v2/Visit/VisitData";

        const config = {
            method: "post",
            headers: authHeader(),
            url,
            data: {
                ...requestBody(),
                itemType,
                itemName,
                itemFlow,
            },
        };

        axios(config)
            .then((res) => {}) // eslint-disable-line
            .catch((err) => console.log(err));
    }
};

export const itemType = {
    NAVIGATION: "Navigation",
    AUTHENTICATION: "Authentication",
    EXPORT_DATA: "Export Data",
    SUPPORT: "Support",
    MESSAGES: "Messages",
    USER_PROFILE: "User Profile",
    SETTINGS: "Settings",
    SCHEDULING: "Scheduling",
    DESCRIPTION: "Description",
    HEALTH_CONDITIONS: "Health Conditions",
    EDUCATION_ARTICLES: "Education Articles",
    LOGOUT: "Logout",
    PRACTICE_INFO: "Practice Info",
    PET: "Pet",
    SAIGE: "Saige",
    NEW_ACCOUNT_ACTIVATION: "User Account Activation",
    ONBOARDING: "Onboarding",
    UNSUBSCRIBE_EMAIL: "User Email unsubscription",
    EMAILRESPONSE_MAKE_APPOINTEMENT: "Schedule Appointment",
    EMAILRESPONSE_SURVEY: "User Feedback",
    EMAILRESPONSE_SIKKASCORE_AUTHORISE: "Authorisation Of Sikka-Score",
};

export const itemName = {
    NAVIGATE: "Navigate to ",
    LOGIN: "Login",
    EXPORT_EXCEL: "Export to Excel",
    OPEN_SUPPORT_MODAL: "Click 'support' link",
    CLICK_SUPPORT_EMAIL_LINK: "Click 'support@sikka.ai' email link",
    DELETE_MESSAGES: "Delete Messages",
    OPEN_COMPOSE_MAIL_MODAL: "Open 'Compose Mail' modal",
    SEND_MAIL: "Compose a mail and click 'Send Message' button",
    CLICK_EDIT_USER_PROFILE: "Click Edit icon to edit User Profile",
    EDIT_USER_PROFILE: "Edit User Profile",
    CLICK_ADD_NEW_FAMILY_MEMBER: "Click Plus icon to add a new family member",
    ADD_NEW_FAMILY_MEMBER: "Add a new family member",
    REMOVE_FAMILY_MEMBER: "Remove a family member",
    OPEN_INSURANCE_CARD_MODAL: "Open New Insurance Card form modal",
    UPDATE_PROFILE_INFO: "Update Profile Info",
    CLICK_MAKE_APPOINTMENT: "Click 'Make Appointment' button",
    MAKE_APPOINTMENT: "Make an appointment",
    HOVER_SIKKA_SCORE_TOOLTIP: "Hover Sikka Score Tooltip",
    HOVER_HEALTH_CONDITIONS_TOOLTIP: "Hover Health Condition Tooltip",
    CLICK_EDIT_HEALTH_CONDITIONS: "Click Edit icon to edit Health Conditions",
    EDIT_HEALTH_CONDITIONS: "Edit Health Conditions",
    CLICK_EDUCATION_ARTICLE_LINK: "Click Education Article external link",
    HOVER_FINANCES_TOOLTIP: "Hover Finances Tooltip",
    HOVER_ACCOUNTS_RECEIVABLE_TOOLTIP: "Hover Accounts Receivable Tooltip",
    FILTER_EDUCATION_ARTICLES: "Filter Education Articles",
    LOGOUT: "Logout",
    UPDATE_PRACTICE_INFO: "Update Practice Info",
    CHANGE_PASSWORD: "Click Edit icon to Change Password",
    DELETE_INSURANCE_CARD: "Click Delete icon to remove Insurance Card",
    EDIT_PET_INFO: "Save changes after editing Pet Info",
    PET_SELECTION: "Select a pet from the dropdown menu",
    SEARCH_BY_SAIGE: "Click on/Hit Enter to see search result from SAIGE ",
    CLICK_ADD_INSURANCE_CARD: "Click on 'Upload Insurance Card' button",
    HOVER_PPP_TOOLTIP: "Hover on Progressive Procedure Prediction Tooltip",
    COMPLETE_CREATING_NEW_ACCOUNT: "User successfully created new account",
    NAVIGATE_TO_HOME_PAGE_AFTER_CREATING_ACCOUNT:
        "Navigate to Home page after successfully create a new account",
    ONBOARDING_POPUP_NEXT_CLICK: "Click 'Next' arrow in onboarding popup",
    ONBOARDING_POPUP_CIRCLE_CLICK: "Click 'circle' in onboarding popup",
    CLOSE_ONBOARDING_POPUP: "Click 'Close' in Onboarding popup",
    UPLOAD_PET_PICTURE: "Uploaded Pet Picture",
    DELELTE_PET_PICTURE: "Deleted Pet Picture",
    UNSUBSCRIBE_EMAIL: "Remove Email Subscription of User",
    EMAILRESPONSE_MAKE_APPOINTEMENT: "Click on Link and Make Appointment",
    EMAILRESPONSE_SURVEY: "Click on link and  Submit survey ",
    EMAILRESPONSE_SIKKASCORE_AUTHORISE:
        "Click ON YES Button of EMAIL Template ",
};
