import { createSlice } from "@reduxjs/toolkit";
import { browserStore } from "../utils";

const petList = browserStore.get("petList");
const selectedPet = browserStore.get("selectedPet");

export const petInfoSlice = createSlice({
	name: "petInfo",
	initialState: {
		petList: petList ? petList : [],
		selectedPet: selectedPet ? selectedPet : null,
	},
	reducers: {
		addPetList: (state, action) => {
			state.petList = action.payload;
		},
		updateSelectedPet: (state, action) => {
			state.selectedPet = action.payload;
		},
	},
});

export const { addPetList, updateSelectedPet } = petInfoSlice.actions;

export default petInfoSlice.reducer;
