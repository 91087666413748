import { useEffect } from "react";
import { useSelector } from "react-redux";
import { VETERINARY } from "../utils";

function useSetTitle(title) {
	const { specialty } = useSelector((state) => state.userInfo);

	useEffect(() => {
		if (title === "Treatment Plans" && specialty === VETERINARY) {
			document.title = "Estimates - Patient Portal";
		} else {
			document.title = title + " - Patient Portal";
		}
	}, []);
	return;
}

export default useSetTitle;
