import React, { useState } from "react";

import "./FollowUp.scss";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { ApiCalls } from "../../../api/allApiCalls";
import Swal from "sweetalert2";
import { browserStore } from "../../../utils";
import useSetTitle from "../../../hooks/useSetTitle";

function FollowUp() {
	const { showNoMatchMessage } = useSelector((state) => state.authentication);
	const navigate = useNavigate();

	const [inputValues, setInputValues] = useState({
		practiceName: "",
		practiceNumber: "",
		practiceAddress: "",
		patientEmail: "",
	});
	const [loading, setLoading] = useState(false);

	useSetTitle("Authentication");

	const handleChange = (e) => {
		setInputValues((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			// validate email
			// Starts with one or more characters that are uppercase letters, lowercase letters, digits, period, underscore, plus, or hyphen.
			// Followed by the at symbol (@).
			// Followed by one or more characters that are uppercase letters, lowercase letters, digits, period, or hyphen.
			// Followed by a period.
			// Ends with two or more characters that are uppercase letters or lowercase letters
			if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
					inputValues.patientEmail
				)
			) {
				Swal.fire({ text: "Invalid email" });
				return;
			}

			setLoading(true);
			const res = await ApiCalls.authenticationPOST(
				"/v2/SignUp/PracticeFollowUpData",
				{
					practiceName: inputValues.practiceName,
					practiceNumber: inputValues.practiceNumber,
					practiceAddress: inputValues.practiceAddress,
					patientEmail: inputValues.patientEmail.toLowerCase(),
				}
			);

			if (res?.isSuccess && res?.message === "Successful") {
				browserStore.set("follow-up-success", true);
				navigate("success");
			} else {
				Swal.fire({
					icon: "error",
					text: "Something went wrong. Please try again",
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="authentication-form-page">
			<div
				className="follow-up-content-wrapper form-content-wrapper"
				style={{ maxWidth: 700 }}
			>
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 36, margin: "1.5rem" }}
				/>

				<div className="font-purple fw-bold text-center mb-4">
					Follow Up Form
				</div>

				{showNoMatchMessage && (
					<div className="error-message mb-4 py-2 px-3">
						Unfortunately we could not find a match. Please fill out
						this form so we can work on making your profile
						available to you.
					</div>
				)}

				<form className="follow-up-form" onSubmit={handleSubmit}>
					<div className="follow-up-form__input">
						<label>Practice Name *</label>
						<input
							name="practiceName"
							required
							placeholder="Enter Practice Name"
							value={inputValues.practiceName}
							onChange={handleChange}
						/>
					</div>
					<div className="follow-up-form__input">
						<label>Practice Address *</label>
						<input
							name="practiceAddress"
							required
							placeholder="Enter Practice Address"
							value={inputValues.practiceAddress}
							onChange={handleChange}
						/>
					</div>

					<div className="follow-up-form__input">
						<label>Phone Number *</label>
						<input
							name="practiceNumber"
							required
							placeholder="Enter Phone Number"
							value={inputValues.practiceNumber}
							onChange={handleChange}
						/>
					</div>
					<div className="follow-up-form__input">
						<label>Your Preferred Email *</label>
						<input
							name="patientEmail"
							type="email"
							required
							placeholder="Email Address"
							value={inputValues.patientEmail}
							onChange={handleChange}
						/>
					</div>

					<button
						className="button button-primary mt-2 width-100p"
						type="submit"
						disabled={loading ? true : false}
					>
						{loading ? <ButtonLoader /> : "Submit"}
					</button>
				</form>
			</div>
		</div>
	);
}

export default FollowUp;
