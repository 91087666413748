import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./unsubscribe.scss";
import Swal from "sweetalert2";
import { itemName, itemType, trackAcvitity } from "../utils/activityTracking";
import { ApiCalls } from "../api/allApiCalls";
import sikka_prime_logo from "../assets/images/sikkaPrimeLogoHalfWhite.png";
import { useNavigate } from "react-router-dom";
import Trademark from "./Trademark/Trademark";
const Unsubscribe = () => {
    const currentUrl = window.location.href;
    const { apiToken } = useSelector((state) => state.userInfo);
    const navigate = useNavigate("");
    const parts = currentUrl.split("ReferenceId=");
    useEffect(() => {
        if (!parts[1] || apiToken) return navigate("*");
    }, []);
    const referenceIdHere = parts[1];
    const [unsubscribeData, setUnsubscribeData] = useState({
        message: "",
    });

    const handleOnchange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setUnsubscribeData({ ...unsubscribeData, [name]: value });
    };

    const submitHandle = () => {
        if (referenceIdHere.length > 0) {
            Swal.fire({
                text: "Are you sure you want to unsubscribe from our email service?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                customClass: {
                    confirmButton: "swal-button swal-button--confirm",
                    cancelButton: "swal-button swal-button--cancel",
                    title: "swal-title swal-title--title",
                },
                buttonsStyling: false,
                confirmButtonColor: "#681c9a",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const res = await ApiCalls.authenticationPOST(
                            "/v2/EmailResponse/EmailUnsubscribe",
                            {
                                referenceId: referenceIdHere,
                                message: unsubscribeData.message,
                                classType: "Sikka-Prime",
                            }
                        );

                        if (res?.isSuccess === true) {
                            Swal.fire({
                                icon: "success",
                                text: ` ${res.message}`,
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: `Something went wrong. Please try again later!`,
                            });
                        }

                        trackAcvitity(
                            itemType.UNSUBSCRIBE_EMAIL,
                            itemName.UNSUBSCRIBE_EMAIL,
                            "unsubscribe page >> Click submit >>result 'remove user to subscribe list'"
                        );
                    } catch (err) {
                        console.log(err);
                    } finally {
                        setUnsubscribeData({
                            ...unsubscribeData,
                            message: "",
                        });
                    }
                }
            });
        }
    };

    return (
        <div>
            <div className="unsubscribe-page">
                <div className="shadow-page mt-4">
                    <div className="home-link">
                        <img
                            height="30"
                            width="200"
                            style={{ marginLeft: "20px" }}
                            src={sikka_prime_logo}
                        ></img>
                    </div>

                    <div className="container mt-4  headings">
                        <span className="unsubscribe-heading-1 mt-3">
                            Cancel Email &nbsp;
                            <span
                                style={{
                                    color: "#681c9a",
                                    fontWeight: "bolder",
                                }}
                            >
                                Subscription
                            </span>
                            <span
                                style={{
                                    color: "#681c9a",
                                    fontWeight: "bolder",
                                }}
                            >
                                !
                            </span>
                        </span>
                    </div>
                    <div className=" p-4">
                        <div className=" mb-2 text">Description:</div>
                        <textarea
                            className="description-textarea"
                            maxLength="200"
                            onChange={handleOnchange}
                            value={unsubscribeData.message}
                            name="message"
                        ></textarea>
                    </div>

                    <hr className="hr_line" />

                    <div className="row mt-4 mb-4">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <div className="button-subscribe-flex-1">
                                <button
                                    className="Subscribe_Submit-btn mb-4"
                                    onClick={() => submitHandle()}
                                >
                                    submit
                                </button>

                                <button
                                    className="Subscribe_close-btn mb-4"
                                    onClick={() => (window.location.href = "/")}
                                >
                                    cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4"></div>
            </div>
            <Trademark />
        </div>
    );
};

export default Unsubscribe;
