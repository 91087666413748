import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./DrawerNavBar.scss";
import { menu } from "./menu";
import sikka_logo_transparent from "../../assets/images/sikka_logo_transparent.png";
import LogoutNavLink from "./LogoutNavLink";
import NavLinks from "./NavLinks";

function DrawerNavBar() {
	const location = useLocation();
	const [openMenu, setOpenMenu] = useState(false);
	const [pageName, setPageName] = useState("/");

	useEffect(() => {
		const page = menu.find((i) => i.path === location.pathname);
		if (page) {
			setPageName(page.title);
		} else {
			setPageName("Patient Portal");
		}
	}, [location]);

	return (
		<div className="sm-navigations" onMouseLeave={() => setOpenMenu(false)}>
			<div className="d-flex justify-content-between align-items-center width-100p">
				<MenuIcon
					className="menu-burger pb-1 me-2"
					role="button"
					style={{ fontSize: 30 }}
					onClick={() => setOpenMenu(true)}
				/>
				<h1 className="fz-16 my-0 py-0 font-black fw-bold">
					{pageName}
				</h1>
				<div className="flex-1 text-end pb-2">
					<img src={sikka_logo_transparent} style={{ height: 16 }} />
				</div>
			</div>

			<div className={`drawer-navbar ${openMenu ? "show-nav" : ""}`}>
				<ArrowBackIcon
					onClick={() => setOpenMenu(false)}
					style={{
						color: "#ffffff",
						fontSize: 28,
						marginTop: "1rem",
						marginLeft: "1rem",
					}}
					role="button"
				/>

				<div className="drawer-navbar__links mt-2 offcanvas-body">
					<NavLinks />
					<LogoutNavLink drawer={true} />
				</div>
			</div>
		</div>
	);
}

export default DrawerNavBar;
