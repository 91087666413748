import React from "react";
import GuarantorInformation from "./GuarantorInformation";
import FamilyMemberInformation from "./FamilyMemberInformation";

function AdditionalInformation() {
	return (
		<div>
			<div className="profile-header">
				<h2>Additional Information</h2>
			</div>
			<div className="d-flex flex-column" style={{ gap: 32 }}>
				<GuarantorInformation />
				<FamilyMemberInformation />
			</div>
		</div>
	);
}

export default AdditionalInformation;
