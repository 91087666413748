import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import Error from "../assets/images/Error.png";
import useSetTitle from "../hooks/useSetTitle";

const StyledNotFound = styled.div`
	position: fixed;
	width: 100vw;
	height: 100dvh;
	background: #ffffff;
	top: 0;
	left: 0;
	margin: 0 auto;

	.not-found-container {
		border: solid red 1px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		text-align: center;
	}

	a {
		background: #681c9a;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: #ffffff !important;
		padding: 0.75rem 2rem;
		border-radius: 6px;
	}
`;

function NotFound() {
	useSetTitle("Not Found");
	return (
		<StyledNotFound>
			<div className="not-found-container p-3">
				<img alt="" src={Error} style={{ maxWidth: 400 }} />
				<h1 className="fw-bold mb-2" style={{ fontSize: 28 }}>
					Oops 404!
				</h1>
				<div
					className="font-gray fw-bold mb-2"
					style={{ fontSize: 22 }}
				>
					Page Not Found
				</div>
				<div className="font-gray mb-3">
					The page you are looking for does not exist or an other
					error occurred.
					<br />
					The requested URL was not found.
				</div>
				<NavLink to="/">Home Page</NavLink>
			</div>
		</StyledNotFound>
	);
}

export default NotFound;
