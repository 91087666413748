import React from "react";
import {
	Chart,
	Series,
	Size,
	CommonSeriesSettings,
	Legend,
	Tooltip,
	ValueAxis,
	Label,
	AdaptiveLayout,
} from "devextreme-react/chart";

import "./AccountsReceivable.scss";
import useFetchAccountsReceivable from "../../../hooks/useFetchAccountsReceivable";
import CardLoader from "../../../components/loaders/CardLoader";
import { DENTAL, toCurrencyConverter } from "../../../utils";
import { useSelector } from "react-redux";

const dentalSeries = [
	{ value: "insurance", name: "Insurance Covered" },
	{ value: "guarantor", name: "Guarantor" },
];

const vetSeries = [{ value: "guarantor", name: "Guarantor" }];

function AccountsReceivable() {
	const { specialty } = useSelector((state) => state.userInfo);
	const [loading, data] = useFetchAccountsReceivable();
	const customizeText = (e) => {
		return `${e.value.toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0,
		})}`;
	};

	const customizeTooltip = (e) => {
		return {
			text: `${toCurrencyConverter(e.valueText)}`,
		};
	};

	return (
		<div className="px-3 pt-4 ar-container position-relative d-flex justify-content-center align-items-center">
			{loading && <CardLoader />}

			<Chart
				id="barGraph"
				dataSource={data}
				palette={["#681c9a", "#F7B500"]}
			>
				<Size height={300} />
				<CommonSeriesSettings argumentField="period" type="bar" />
				{specialty === DENTAL
					? dentalSeries.map((item) => (
							<Series
								key={item.value}
								valueField={item.value}
								name={item.name}
							/>
					  ))
					: vetSeries.map((item) => (
							<Series
								key={item.value}
								valueField={item.value}
								name={item.name}
							/>
					  ))}
				<ValueAxis>
					<Label customizeText={customizeText} />
				</ValueAxis>
				<Legend
					verticalAlignment="bottom"
					horizontalAlignment="center"
					itemTextPosition="bottom"
				/>
				<AdaptiveLayout height={100} width={180} />
				<Tooltip enabled={true} customizeTooltip={customizeTooltip} />
			</Chart>
		</div>
	);
}

export default AccountsReceivable;
