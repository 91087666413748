import { createSlice } from "@reduxjs/toolkit";

export const practiceInfoSlice = createSlice({
	name: "userInfo",
	initialState: {
		practiceLogoURL: "",
		practiceID: "",
		custID: "",
		practiceName: " ",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		phone: "",
		practiceEmail: "",
		brandingText: "",
		password:"",
	},
	reducers: {
           

		addPracticeInfo: (state, action) => {
			(state.practiceLogoURL = action.payload.practiceLogoURL),
				(state.practiceID = action.payload.practiceID),
				(state.custID = action.payload.custID),
				(state.practiceName = action.payload.practiceName),
				(state.address1 = action.payload.address1),
				(state.address2 = action.payload.address2),
				(state.city = action.payload.city),
				(state.state = action.payload.state),
				(state.zip = action.payload.zip),
				(state.phone = action.payload.phone),
				(state.practiceEmail = action.payload.practiceEmail),
				(state.brandingText = action.payload.brandingText),
				(state.password=action.payload.password);

		},
		updatePracticeInfo: (state, action) => {
			(state.practiceName = action.payload.practiceName),
				(state.phone = action.payload.phone),
				(state.address1 = action.payload.address1),
				(state.password=action.payload.password);
		},
	},
});

export const { addPracticeInfo, updatePracticeInfo } =
	practiceInfoSlice.actions;

export default practiceInfoSlice.reducer;
