import React from "react";

function MessagesTabPanel({ activeTab, setActiveTab }) {
	return (
		<div className="messages-page__tab-panel">
			<div
				className={`tab-item${activeTab === 0 ? " active" : ""}`}
				role="button"
				onClick={() => setActiveTab(0)}
			>
				Inbox
			</div>
			<div
				className={`tab-item${activeTab === 1 ? " active" : ""}`}
				role="button"
				onClick={() => setActiveTab(1)}
			>
				Sent Mail
			</div>
			<div
				className="bottom-line"
				style={{ left: activeTab === 0 ? 0 : 142 }}
			></div>
		</div>
	);
}

export default MessagesTabPanel;
