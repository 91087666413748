import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiCalls } from "../../../api/allApiCalls";
import {
	resetAuth,
	setShowNoMatchMessage,
	updateAuthData,
} from "../../../states/authSlice";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import Swal from "sweetalert2";

function VerifyEmail() {
	const { authToken, maskedEmail } = useSelector(
		(state) => state.authentication
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [attemptLeft, setAttemptLeft] = useState(3);
	const [inputValue, setInputValue] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!authToken || !maskedEmail) {
			navigate("/authentication");
		}
	}, []);

	const handleSubmit = async () => {
		setError("");
		// validate input
		if (!inputValue) {
			setError("Email is required");
			return;
		}

		try {
			setLoading(true);
			// send request to API. if wrong, subtract attempt
			const res = await ApiCalls.authenticationPOST(
				"/v2/SignUp/ValidateEmailOrCell",
				{
					authToken,
					email: inputValue,
					cell: "",
					mediaType: "Email",
				}
			);

			// If Email matched, send request CODE, save info and navigate to Verification Screen
			if (
				Array.isArray(res) &&
				res[0]?.authToken &&
				res[0]?.masterId &&
				res[0]?.patientId &&
				res[0]?.practiceId
			) {
				const result = await ApiCalls.authenticationPOST(
					"/v2/SignUp/SendVerificationCode",
					{
						authToken: res[0].authToken,
						masterId: res[0].masterId,
						practiceId: res[0].practiceId,
						patientId: res[0].patientId,
						email: res[0].email,
						cell: res[0].cell,
						mediaType: "email",
					}
				);

				if (result?.isSuccess && result?.authToken) {
					dispatch(
						updateAuthData({
							masterId: res[0].masterId,
							custId: res[0].custId,
							practiceId: res[0].practiceId,
							patientId: res[0].patientId,
							email: res[0].email,
							cell: res[0].cell,
							mediaType: "Email",
						})
					);
					navigate("/authentication/verification");
				} else {
					Swal.fire({
						icon: "error",
						text: "Verification Failed. Re-enter code or click the link below if you wish to restart the process",
						footer: `<a href="${document.location.origin}/authentication">Restart the process</a>`,
					});
				}
			} else {
				// If Email does not match, subtract attempt
				const remaining = attemptLeft - 1;
				if (remaining === 0) {
					// If there is no attempt left, clear all data in Reducer and Navigate to Follow-up page
					dispatch(resetAuth());
					dispatch(setShowNoMatchMessage());
					navigate("/authentication/follow-up");
				} else {
					setError(`Invalid email. ${remaining} attempts left.`);
					setAttemptLeft(remaining);
					setInputValue("");
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<div className="border-radius-6 border-warning bg-clr-warning p-2 px-3 mb-4">
				Please verify the complete email address
			</div>
			<div className="d-flex flex-column gap-4">
				<div className="d-flex flex-column align-items-start">
					<label className="mb-1">Enter Your Email ID *</label>
					<input
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						placeholder={maskedEmail}
					/>
				</div>
				<button
					className="button button-primary width-100p"
					onClick={handleSubmit}
					disabled={loading ? true : false}
				>
					{loading ? <ButtonLoader /> : "Submit"}
				</button>
			</div>
			{error && <div className="font-destructive mt-1">{error}</div>}
		</div>
	);
}

export default VerifyEmail;
