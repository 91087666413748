export const INBOX = "Inbox";
export const SENT_MAIL = "Sent Mail";

export const initTableState = {
	skip: 0,
	take: 20,
	sort: [
		{
			field: "date",
			dir: "desc",
		},
	],
	filter: null,
};
