import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Calendar from "react-calendar";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";

import "./AppointmentCalendar.scss";
import "react-calendar/dist/Calendar.css";
import { ApiCalls } from "../../api/allApiCalls";
import {
    formatISOtoShortMonthDate,
    formateDateObjToShortMonth,
} from "../../utils";

const formatDate = (dateObj) => {
    const dateStr = dateObj.toLocaleString("en-US");

    const splitted = dateStr.split(",");
    const [m, d, y] = splitted[0].split("/");

    return `${y}-${m.padStart(2, "0")}-${d.padStart(2, "0")}`;
};

function AppointmentCalendar({
    selectedDate,
    slots,
    setSlots,
    appointment,
    selectedSlots,
    setSelectedSlots,
    setSelectedDate,
}) {
    const { masterId, practiceId, userId, patientId } = useSelector(
        (state) => state.userInfo
    );
    const [isMounted, setIsMounted] = useState(false);
    const [minDate, setMinDate] = useState(new Date());
    const divRef = useRef(null);
    const [height, setHeight] = useState("");
    const slotContainer = useRef(null);
    const [formattedSelectedDate, setFormattedSelectedDate] = useState("");

    // Resize the Slots container to fit with the height of the Calendar
    useEffect(() => {
        if (!divRef.current) return;

        const resizeObserver = new ResizeObserver(() => {
            if (divRef.current.offsetHeight !== height) {
                setHeight(divRef.current.offsetHeight + 2);
            }
        });

        resizeObserver.observe(divRef.current);

        return function cleanup() {
            resizeObserver.disconnect();
        };
    }, [divRef.current]);

    // Scroll to top whenever the Slots Available data changes
    useEffect(() => {
        if (slotContainer?.current) {
            slotContainer.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [slots]);

    const fetchSlots = async (date) => {
        try {
            const dateString = formatDate(date);
            const res = await ApiCalls.authGetAPI(
                "/v2/Patient/PracticeOpenSlots",
                {
                    masterId,
                    practiceId,
                    userId,
                    patientId,
                    apptDate: dateString,
                    procedureType: appointment.treatment,
                    providerId: appointment.doctor,
                }
            );
            if (res[0]?.time) setSlots(res[0]?.time);
        } catch (err) {
            console.log(err);
        }
    };

    // When the user chooses a Doctor and click "Make appointment", the Calendar shows up.
    // When this component mounted, fetch Slots data with current date
    // Else, when user select another date, fetch slots data based on the selected Date
    useEffect(() => {
        if (!isMounted) {
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            setMinDate(tomorrow);
            setSelectedDate(tomorrow);
            setIsMounted(true);
        } else {
            fetchSlots(selectedDate);
        }
        setFormattedSelectedDate(formatDate(selectedDate));
    }, [selectedDate]);

    // If the use chooses another doctor/treatment, fetch Slots data based on the selected date
    useEffect(() => {
        if (!isMounted) return;
        fetchSlots(selectedDate);
    }, [appointment.doctor, appointment.treatment]);

    const handleSelectTime = (time) => {
        if (selectedSlots.length === 3) {
            return;
        }
        // Check if the slot is already selected.
        // If not, add time and date to SelectedSlots state
        const found = selectedSlots.find(
            (i) => i.date === formattedSelectedDate && i.time === time
        );
        if (found) return;
        const newList = [
            ...selectedSlots,
            {
                time,
                date: formattedSelectedDate,
            },
        ];
        newList.sort((a, b) => {
            const dateA = new Date(`${a.date} ${a.time}`);
            const dateB = new Date(`${b.date} ${b.time}`);
            return dateA - dateB;
        });
        setSelectedSlots(newList);
    };

    const handleRemoveAppt = (appt) => {
        const found = selectedSlots.find(
            (i) => i.time === appt.time && i.date === appt.date
        );
        if (found) {
            const newList = [...selectedSlots].filter((i) => i !== found);
            newList.sort((a, b) => {
                const dateA = new Date(`${a.date} ${a.time}`);
                const dateB = new Date(`${b.date} ${b.time}`);
                return dateA - dateB;
            });
            setSelectedSlots(newList);
        }
    };

    return (
        <div className="appointmentCalendar">
            <div className="d-flex flex-wrap" style={{ gap: 32 }}>
                <div>
                    <h3>Select Your Preferrable Date & Time</h3>
                    <div ref={divRef}>
                        <Calendar
                            onChange={(d) => setSelectedDate(d)}
                            value={selectedDate}
                            minDate={minDate}
                        />
                    </div>
                </div>
                <div>
                    <h3>
                        Slots Available
                        <span className="fw-normal ms-2">
                            [{formateDateObjToShortMonth(selectedDate)}]
                        </span>
                    </h3>

                    <div className="position-relative">
                        <div className="overflow-blur"></div>
                        <div
                            className="slots-available"
                            style={{
                                maxHeight: height,
                            }}
                            ref={slotContainer}
                        >
                            <div className="slots-container pb-3">
                                {slots.map((s, i) => (
                                    <button
                                        className={`timeButton${
                                            selectedSlots.find(
                                                (item) =>
                                                    item.time === s.time &&
                                                    item.date ===
                                                        formattedSelectedDate
                                            )
                                                ? " booked"
                                                : ""
                                        }`}
                                        key={i}
                                        onClick={() => handleSelectTime(s.time)}
                                    >
                                        {s.time}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="selected-slots-container">
                <h3>Selected Appointments</h3>
                <ul className="selected-slots">
                    {selectedSlots.map((i) => (
                        <li
                            style={{ listStyleType: "none" }}
                            key={`${i.date}${i.time}`}
                        >
                            <span className="date">
                                <EventIcon />
                                {formatISOtoShortMonthDate(i.date)}
                            </span>
                            <span className="time">
                                <ScheduleIcon />
                                {i.time}
                            </span>
                            <span
                                className="remove-appt"
                                role="button"
                                onClick={() => handleRemoveAppt(i)}
                            >
                                Remove
                            </span>
                        </li>
                    ))}

                    {selectedSlots.length === 0 && (
                        <li>No slot selected yet.</li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default AppointmentCalendar;
