import React, { useState } from "react";
import { useSelector } from "react-redux";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MedicationIcon from "@mui/icons-material/Medication";
import "./Procedures.scss";
import useFetchUpcomingProcedures from "../../hooks/useFetchUpcomingProcedures";
import useFetchPreviousProcedures from "../../hooks/useFetchPreviousProcedures";
import { formatDateShortMonth, formatTime } from "../../utils/index";
import CardLoader from "../../components/loaders/CardLoader";
import NoItemsCart from "../../assets/images/NoItemsCart.png";
import MakeAppointmentModal from "./MakeAppointmentModal";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";

import OverflowBlur from "../../components/OverflowBlur";
import RequestAppointmentButton from "./RequestAppointmentButton";
function Procedures() {
    const { specialty } = useSelector((state) => state.userInfo);
    const [previousProcLoading, pastProcdata] = useFetchPreviousProcedures();

    const [upcomingApptLoading, upcomingApptData] =
        useFetchUpcomingProcedures();
    const [openApptModal, setOpenApptModal] = useState(false);

    const handleClick = () => {
        setOpenApptModal(true);
        trackAcvitity(
            itemType.SCHEDULING,
            itemName.CLICK_MAKE_APPOINTMENT,
            "Home >> Click on 'Make Appointment' button in Procedures section"
        );
    };

    return (
        <div
            className="your-procedures bg-white position-relative"
            id="db-your-procedures-id"
        >
            <div
                className="your-procedures__header d-flex justify-content-between align-items-center"
                style={{ marginBottom: 24, height: 40 }}
            >
                <h2 style={{ marginTop: 8 }}>Procedures</h2>
                {upcomingApptData.length > 0 && (
                    <RequestAppointmentButton handleClick={handleClick} />
                )}
            </div>
            <div className="your-procedures__main">
                {/* APPOINTMENTS */}
                <div className="your-procedures__main--item your-procedures-item-1">
                    <h3>Upcoming Appointments</h3>
                    <div className="flex-1 position-relative">
                        <div className="overflow-blur"></div>
                        <div
                            className={`flex-1 upcoming-appt d-flex flex-column position-relative pb-3 ${
                                specialty === "Dental"
                                    ? "max-height-452"
                                    : "max-height-500"
                            }`}
                            style={{ gap: 12 }}
                        >
                            {upcomingApptLoading && <CardLoader />}
                            {!upcomingApptLoading &&
                                upcomingApptData.map((item, idx) => (
                                    <div
                                        key={idx}
                                        className="d-flex"
                                        style={{
                                            gap: 20,
                                            paddingInline: 20,
                                            paddingBlock: 14,
                                            backgroundColor: "#FEF9FF",
                                            borderRadius: 6,
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: 70,
                                                wordBreak: "break-word",
                                            }}
                                            className="fw-bold"
                                        >
                                            {item.procCode}
                                        </div>
                                        <div className="flex-1">
                                            <div
                                                className="fw-bold"
                                                style={{
                                                    marginBottom: 12,
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                {item.procName}
                                            </div>
                                            <div
                                                className="d-flex align-items-center"
                                                style={{ gap: 18 }}
                                            >
                                                <div className="timestamp">
                                                    <DateRangeIcon className="icon" />{" "}
                                                    {formatDateShortMonth(
                                                        item.procDate
                                                    )}
                                                </div>
                                                <div className="timestamp">
                                                    <AccessTimeIcon className="icon" />{" "}
                                                    {formatTime(item.procTime)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {upcomingApptData.length === 0 && (
                                <div
                                    className="d-flex flex-column align-items-center border-radius-6"
                                    style={{
                                        backgroundColor: "#FEF9FF",
                                        padding: 24,
                                    }}
                                >
                                    <div style={{ marginBottom: 18 }}>
                                        You have no scheduled appointments
                                    </div>
                                    <RequestAppointmentButton
                                        handleClick={handleClick}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* PAST PROCEDURES */}
                <div className="your-procedures__main--item your-procedures-item-2 d-flex flex-column justify-content-between">
                    <h3>Past Procedures</h3>
                    <div className="position-relative flex-1 border-radius-6 card-border">
                        <OverflowBlur />
                        <div
                            className={`position-relative height-100p ${
                                specialty === "Dental"
                                    ? "max-height-452"
                                    : "max-height-500"
                            }`}
                            style={{
                                paddingLeft: 16,
                                paddingRight: 22,
                                paddingBlock: 14,
                                overflowY: "auto",
                            }}
                        >
                            {previousProcLoading && <CardLoader />}

                            {pastProcdata.map((item, idx) => (
                                <div
                                    key={idx}
                                    className="timeline-flex-item proc-done-flex-item"
                                >
                                    <div className="timeline-description">
                                        <CheckCircleIcon
                                            style={{
                                                height: 14,
                                                width: 14,
                                                color: "#681c9a",
                                                marginRight: "0.75rem",
                                                marginTop: 3,
                                            }}
                                        />
                                        <div>
                                            <div>
                                                {item.procCode} -{" "}
                                                {item.procName}
                                            </div>
                                            {item.lastName || item.firstName ? (
                                                <div>
                                                    <MedicationIcon
                                                        style={{
                                                            color: "#681c9a",
                                                        }}
                                                    />
                                                    {item.firstName}&nbsp;
                                                    {item.lastName}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div className="timestamp">
                                        <DateRangeIcon className="icon" />{" "}
                                        {formatDateShortMonth(item.procDate)}
                                    </div>
                                </div>
                            ))}

                            {pastProcdata.length === 0 && (
                                <div className="d-flex flex-column justify-content-center align-items-center height-100p">
                                    <img
                                        alt=""
                                        src={NoItemsCart}
                                        style={{ maxHeight: 220 }}
                                    />
                                    <div>No past procedures available</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {openApptModal && (
                <MakeAppointmentModal
                    openModal={openApptModal}
                    closeModal={() => setOpenApptModal(false)}
                />
            )}
        </div>
    );
}

export default Procedures;
