import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "../CreateNewAccount/CreateNewAccount.scss";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import { initialErrObj, validatePassword } from "../../../utils";
import { useSelector } from "react-redux";
import PasswordValidation from "../../../components/PasswordValidation";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { ApiCalls } from "../../../api/allApiCalls";
import PasswordStrengthMeter from "../../../components/PasswordStrengthMeter";
import useSetTitle from "../../../hooks/useSetTitle";

function ResetPassword() {
	const { emailId, userId, verificationCode } = useSelector(
		(state) => state.userInfo
	);
	const navigate = useNavigate();
	const [inputValues, setInputValues] = useState({
		password: "",
		passwordConfirm: "",
	});
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [passwordConfirmVisibility, setPasswordConfirmVisibility] =
		useState(false);
	const [passwordErr, setPasswordErr] = useState(initialErrObj);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	useSetTitle("Forgot Password");

	/**
	 * Whenever the user type in new password or confirm the new password,
	 * validate if it satisfies the condition of password
	 */
	useEffect(() => {
		setPasswordErr(
			validatePassword(inputValues.password, inputValues.passwordConfirm)
		);
	}, [inputValues.password, inputValues.passwordConfirm]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			// Validate password
			if (Object.values(passwordErr).includes(true)) {
				setError("Invalid password");
				setTimeout(() => {
					setError("");
				}, 3000);
				return;
			}
			// Reset Password
			const res = await ApiCalls.authenticationPOST(
				"/v2/Password/ChangePassword",
				{
					userId,
					verificationCode,
					email: emailId,
					password: inputValues.passwordConfirm,
				}
			);

			if (res?.isSuccess && res?.message === "Successful") {
				navigate("/forgotPassword/resetPasswordSuccess");
			} else {
				setError("Something went wrong. Please contact us for support");
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		// every time user enters password/confirm password, validate the inputs
		setInputValues((prev) => ({
			...prev,
			[e.target.name]: e.target.value.replaceAll(" ", ""),
		}));
	};

	return (
		<div className="login-form-page">
			<div className="form-content-wrapper">
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ maxWidth: 200 }}
				/>
				<div
					className="font-black fw-bold text-center mb-4"
					style={{ fontSize: "20px" }}
				>
					Reset Password
				</div>

				{!error && (
					<div
						className="mb-4 border-success bg-clr-success py-2 px-3 border-radius-4"
						style={{ maxWidth: 470, textAlign: "center" }}
					>
						Your OTP code has been verified!
					</div>
				)}
				{error && (
					<div
						className="error-message mb-4 py-2 px-3"
						style={{ maxWidth: 470, textAlign: "center" }}
					>
						{error}
					</div>
				)}
				<div>
					<form className="login-form mb-3" onSubmit={handleSubmit}>
						<div className="login__input position-relative">
							<label>Enter Your Password *</label>
							<input
								name="password"
								required
								type={`${
									passwordVisibility ? "text" : "password"
								}`}
								placeholder="Password"
								value={inputValues.password}
								onChange={handleChange}
							/>
							<div
								className="position-absolute"
								style={{ right: 7, bottom: 26 }}
							>
								{!passwordVisibility ? (
									<VisibilityIcon
										className="font-purple"
										onClick={() =>
											setPasswordVisibility(true)
										}
									/>
								) : (
									<VisibilityOffIcon
										className="font-gray"
										onClick={() =>
											setPasswordVisibility(false)
										}
									/>
								)}
							</div>
						</div>
						<PasswordStrengthMeter
							password={inputValues.password}
						/>
						<div className="align-self-start mb-3">
							<PasswordValidation passwordErr={passwordErr} />
						</div>
						<div className="login__input position-relative">
							<label>Confirm Your Password *</label>
							<input
								name="passwordConfirm"
								required
								type={`${
									passwordConfirmVisibility
										? "text"
										: "password"
								}`}
								placeholder="Password"
								value={inputValues.passwordConfirm}
								onChange={handleChange}
							/>
							<div
								className="position-absolute"
								style={{ right: 7, bottom: 26 }}
							>
								{!passwordConfirmVisibility ? (
									<VisibilityIcon
										className="font-purple"
										onClick={() =>
											setPasswordConfirmVisibility(true)
										}
									/>
								) : (
									<VisibilityOffIcon
										className="font-gray"
										onClick={() =>
											setPasswordConfirmVisibility(false)
										}
									/>
								)}
							</div>
						</div>

						<button
							className="button button-primary mt-2 width-100p"
							type="submit"
							disabled={loading ? true : false}
						>
							{loading ? <ButtonLoader /> : "Reset Password"}
						</button>
					</form>
				</div>
			</div>
			<div className="company-copyright">
				© {new Date().getFullYear()} Sikka Software Corporation, The
				Practice Optimizer Company®. All Rights Reserved.
			</div>
		</div>
	);
}

export default ResetPassword;
