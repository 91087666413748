import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { PDFDocument } from "pdf-lib";

import "./CreateNewAccount.scss";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import hippaaPDF from "../../../assets/pdf/HIPPAA_Authorization.pdf";
import {
	DENTAL,
	VETERINARY,
	browserStore,
	initialErrObj,
	setPetList,
	validatePassword,
} from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../states/userInfoSlice";
import PasswordValidation from "../../../components/PasswordValidation";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { ApiCalls } from "../../../api/allApiCalls";
import Swal from "sweetalert2";
import axios from "axios";
import useSetTitle from "../../../hooks/useSetTitle";
import { addPetList } from "../../../states/petInfoSlice";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../../utils/activityTracking";

function CreateNewAccount() {
	const {
		authToken,
		masterId,
		custId,
		practiceId,
		patientId,
		firstName,
		lastName,
		cell,
		birthDate,
		ssn,
		authCode,
		base64Data,
	} = useSelector((state) => state.authentication);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [inputValues, setInputValues] = useState({
		email: "",
		password: "",
		passwordConfirm: "",
	});
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [passwordConfirmVisibility, setPasswordConfirmVisibility] =
		useState(false);
	const [passwordErr, setPasswordErr] = useState(initialErrObj);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	useSetTitle("Authentication");

	useEffect(() => {
		if (!authToken || !authCode) {
			navigate("/authentication");
		}
	}, []);

	const footer = `<a href="${document.location.origin}/login">Login</a>`;

	/**
	 * Whenever the user type in new password or confirm the new password,
	 * validate if it satisfies the condition of password
	 */
	useEffect(() => {
		setPasswordErr(
			validatePassword(inputValues.password, inputValues.passwordConfirm)
		);
	}, [inputValues.password, inputValues.passwordConfirm]);

	const pdfBytesSigned = async () => {
		//Formatter to format the date to mm/dd/yyyy
		const formatter = new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			timeZone: "UTC",
		});
		const pdfBytes = await fetch(hippaaPDF).then((res) =>
			res.arrayBuffer()
		);

		const pdfDoc = await PDFDocument.load(pdfBytes);
		const form = pdfDoc.getForm();
		form.getTextField("Name of Patient Please Print").setText(
			firstName + " " + lastName
		);

		form.getTextField("Date of Birth").setText(
			formatter.format(new Date(birthDate + "T00:00:00Z"))
		);
		form.getTextField("Date").setText(formatter.format(new Date()));

		const signatureField = form.getTextField(
			"Signature of Patient or Personal Representative"
		);

		const signatureImage = await pdfDoc.embedPng(base64Data);
		signatureField.setImage(signatureImage);
		form.flatten();

		const signedPdfBytes = await pdfDoc.save();
		const base64Pdf = btoa(
			new Uint8Array(signedPdfBytes).reduce(
				(data, byte) => data + String.fromCharCode(byte),
				""
			)
		);
		return base64Pdf;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			// Validate password
			if (Object.values(passwordErr).includes(true)) {
				setError("Invalid password");
				setTimeout(() => {
					setError("");
				}, 3000);
				return;
			}

			// validate email
			// Starts with one or more characters that are uppercase letters, lowercase letters, digits, period, underscore, plus, or hyphen.
			// Followed by the at symbol (@).
			// Followed by one or more characters that are uppercase letters, lowercase letters, digits, period, or hyphen.
			// Followed by a period.
			// Ends with two or more characters that are uppercase letters or lowercase letters
			if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
					inputValues.email
				)
			) {
				setError("Invalid email");
				setTimeout(() => {
					setError("");
				}, 3000);
				return;
			}

			const email = inputValues.email.toLowerCase();
			const hippaaPDFSigned = await pdfBytesSigned();
			// Create new account
			const res = await ApiCalls.authenticationPOST(
				"/v2/SignUp/CreateAccount",
				{
					authToken,
					authCode,
					masterId,
					custId,
					practiceId,
					patientId,
					firstName,
					lastName,
					email,
					cell,
					birthDate,
					ssn,
					password: inputValues.password,
					hipaaPdfBase64: hippaaPDFSigned,
				}
			);

			if (res?.isSuccess && res?.message === "successful") {
				// Login
				let publicAddress = "";
				await axios
					.get("https://ipwho.is")
					.then((response) => {
						publicAddress = response?.data?.ip;
					})
					.catch((error) => {
						console.log(error);
					});
				const result = await ApiCalls.login({
					emailId: email,
					password: inputValues.password,
					publicIP: publicAddress,
				});

				if (result?.apiToken?.token && result?.userId) {
					browserStore.set("userInfo", {
						apiToken: result.apiToken.token,
					});
					// Get additional data
					const patientData = await ApiCalls.authGetAPI(
						"/v2/Patient/PatientData",
						{
							masterId: result.masterId,
							userId: result.userId,
							practiceId: result.practiceId,
							patientId: result.patientId,
						}
					);
					if (patientData.length === 0) {
						Swal.fire({
							icon: "error",
							text: "Your account has been successfully created. However, we could not log you in. Please try the link below to login",
							footer,
						});
						return;
					}

					// Fetch pet data
					if (result.masterId.includes("V")) {
						const petData = await ApiCalls.authGetAPI(
							"/v2/Patient/PetData",
							{
								masterId: result.masterId,
								userId: result.userId,
								practiceId: result.practiceId,
								guarId: patientData[0].guarId,
							}
						);
						const petDataToStore = setPetList(petData);
						browserStore.set("petList", petDataToStore); // Store data in sessionStorage
						dispatch(addPetList(petDataToStore));
					}

					// If the email and password are valid, save userInfo to reducer + sessionStorage
					// Then, navigate to homepage
					const dataToStore = {
						...result,
						apiToken: result.apiToken.token,
						authenticated: true,
						...patientData[0],
						publicIP: publicAddress,
						specialty: result.masterId.includes("D")
							? DENTAL
							: VETERINARY,
					};
					browserStore.set("userInfo", dataToStore); // Store data in sessionStorage
					dispatch(login(dataToStore));

					trackAcvitity(
						itemType.NEW_ACCOUNT_ACTIVATION,
						itemName.COMPLETE_CREATING_NEW_ACCOUNT,
						"User entered email, password to create new account >> SUCCESS"
					);

					navigate("success");
				} else {
					Swal.fire({
						icon: "error",
						text: "Your account has been successfully created. However, we could not log you in. Please try the link below to login",
						footer,
					});
				}
			} else if (
				res?.message ===
				"You have already activated your account.Thank you for registering. Now you can login to PatientHomePage"
			) {
				Swal.fire({
					text: "You have already activated your account. Click the link below to log in",
					footer,
				});
			} else {
				Swal.fire({
					icon: "error",
					text: "Something went wrong. Please contact us for support",
					footer: "<a href='mailto:support@sikka.ai'>support@sikka.ai</a>",
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		// every time user enters password/confirm password, validate the inputs
		setInputValues((prev) => ({
			...prev,
			[e.target.name]: e.target.value.replaceAll(" ", ""),
		}));
	};

	return (
		<div className="authentication-form-page">
			<div className="form-content-wrapper">
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ maxWidth: 200 }}
				/>
				<h3 className="authentication-page-header">
					Create Your Account
				</h3>

				<div
					className="mb-4 border-success bg-clr-success py-2 px-3 border-radius-6"
					style={{ maxWidth: 400 }}
				>
					Congratulations! You are a authenticated and verified
					patient in our database. Create your account now
				</div>

				<div>
					<form
						className="create-account-form mb-3"
						onSubmit={handleSubmit}
					>
						<div className="create-account-form__input">
							<label>Enter Your Email *</label>
							<input
								name="email"
								type="email"
								required
								placeholder="Email"
								value={inputValues.email}
								onChange={handleChange}
							/>
						</div>

						<div className="create-account-form__input password-input">
							<label>Enter Your Password *</label>
							<input
								name="password"
								required
								type={`${
									passwordVisibility ? "text" : "password"
								}`}
								placeholder="Password"
								value={inputValues.password}
								onChange={handleChange}
							/>
							<div className="visibility-icon">
								{!passwordVisibility ? (
									<VisibilityOutlinedIcon
										style={{ color: "#0496ff" }}
										onClick={() =>
											setPasswordVisibility(true)
										}
									/>
								) : (
									<VisibilityOffOutlinedIcon
										style={{ color: "#414d55" }}
										onClick={() =>
											setPasswordVisibility(false)
										}
									/>
								)}
							</div>
						</div>

						<div className="create-account-form__input password-input">
							<label>Confirm Your Password *</label>
							<input
								name="passwordConfirm"
								required
								type={`${
									passwordConfirmVisibility
										? "text"
										: "password"
								}`}
								placeholder="Password"
								value={inputValues.passwordConfirm}
								onChange={handleChange}
							/>
							<div className="visibility-icon">
								{!passwordConfirmVisibility ? (
									<VisibilityOutlinedIcon
										style={{ color: "#0496ff" }}
										onClick={() =>
											setPasswordConfirmVisibility(true)
										}
									/>
								) : (
									<VisibilityOffOutlinedIcon
										style={{ color: "#414d55" }}
										onClick={() =>
											setPasswordConfirmVisibility(false)
										}
									/>
								)}
							</div>
						</div>

						<div className="align-self-start mb-3">
							<PasswordValidation passwordErr={passwordErr} />
						</div>

						<button
							className="button button-primary mt-2 width-100p"
							type="submit"
							disabled={loading ? true : false}
						>
							{loading ? <ButtonLoader /> : "Create Account"}
						</button>
						{error && (
							<div className="font-destructive">{error}</div>
						)}
					</form>
				</div>

				<div className="fw-bold">
					Existing User?{" "}
					<a
						style={{
							color: "#681c9a",
							textDecoration: "underline",
						}}
						href={`${window.location.origin}/login`}
					>
						Login
					</a>{" "}
					now
				</div>
			</div>
		</div>
	);
}

export default CreateNewAccount;
