import React from "react";
import { useSelector } from "react-redux";

import "./NavBar.scss";
import sikka_logo_transparent from "../../assets/images/sikka_logo_transparent.png";
import { avatarUrl } from "../../api/apiRoot";
import LogoutNavLink from "./LogoutNavLink";
import NavLinks from "./NavLinks";

function NavBar() {
    const { firstName, lastName, emailId } = useSelector(
        (state) => state.userInfo
    );

    return (
        <div className="navbar-container">
            <div className="navbar-wrapper position-relative">
                <div className="navbar-top text-center pt-4">
                    <img
                        alt="user avatar"
                        src={avatarUrl(firstName)}
                        className="border-radius-100p user-avatar"
                        style={{
                            height: 100,
                            width: 100,
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                    />
                    <div className="fw-bold fz-20 mt-2 font-black">
                        {firstName} {lastName}
                    </div>
                    <div
                        className="font-gray"
                        style={{ wordBreak: "break-word" }}
                    >
                        {emailId}
                    </div>
                </div>

                <div className="navbar-links py-4 position-relative">
                    <div
                        id="overlay-onboard-id"
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 77,
                            height: 134,
                            width: 240,
                            backgroundColor: "transparent",
                            opacity: 0,
                            zIndex: -1,
                        }}
                    ></div>
                    <NavLinks />
                    <LogoutNavLink />
                </div>

                <div className="navbar-footer">
                    <img
                        alt="Sikka.ai"
                        src={sikka_logo_transparent}
                        style={{ width: 120 }}
                    />
                    <div className="text-center">Patient Portal Dashboard</div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
