import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";

import "./Login.scss";
import sikka_logo_transparent from "../../assets/images/sikka_logo_transparent.png";
import { browserStore, setPetList } from "../../utils";
import { ApiCalls } from "../../api/allApiCalls";
import Swal from "sweetalert2";
import { login } from "../../states/userInfoSlice";
import { setPageLoaderStatus } from "../../states/pageLoaderSlice";
import useSetTitle from "../../hooks/useSetTitle";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";
import { addPetList } from "../../states/petInfoSlice";

const initialInputValues = {
    emailId: "",
    password: "",
};

function Login() {
    const { authenticated, apiToken } = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [inputValues, setInputValues] = useState(initialInputValues);
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState("");

    // Direct user to homepage if already logged in
    useEffect(() => {
        if (authenticated && apiToken) navigate("/");
    }, []);

    useSetTitle("Login");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            let publicIP = "";
            await axios
                .get("https://ipwho.is")
                .then((res) => {
                    publicIP = res?.data?.ip;
                })
                .catch((error) => {
                    console.log(error);
                });

            const res = await ApiCalls.login({
                emailId: inputValues.emailId,
                password: inputValues.password,
                publicIP,
            });

            if (!res?.apiToken?.token || !res?.userId) {
                setError("Invalid email Id or password.");
                setInputValues(initialInputValues);
            } else {
                browserStore.set("userInfo", { apiToken: res.apiToken.token });
                // Get additional data
                const patientData = await ApiCalls.authGetAPI(
                    "/v2/Patient/PatientData",
                    {
                        masterId: res.masterId,
                        userId: res.userId,
                        practiceId: res.practiceId,
                        patientId: res.patientId,
                    }
                );

                if (Array.isArray(patientData) && patientData.length === 0) {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again later or contact us for support",
                        footer: "<a href='mailto:support@sikka.ai'>support@sikka.ai</a>",
                    });
                    return;
                }

                // Fetch pet data
                if (res.masterId.includes("V")) {
                    const petData = await ApiCalls.authGetAPI(
                        "/v2/Patient/PetData",
                        {
                            masterId: res.masterId,
                            userId: res.userId,
                            practiceId: res.practiceId,
                            guarId: patientData[0].guarId,
                        }
                    );
                    const petDataToStore = setPetList(petData);
                    browserStore.set("petList", petDataToStore); // Store data in sessionStorage
                    dispatch(addPetList(petDataToStore));
                }

                // If the email and password are valid, save userInfo to reducer + sessionStorage
                // Then, navigate to homepage
                dispatch(setPageLoaderStatus(true));
                setTimeout(() => {
                    dispatch(setPageLoaderStatus(false));
                }, 300);
                const dataToStore = {
                    ...res,
                    apiToken: res.apiToken.token,
                    authenticated: true,
                    ...patientData[0],
                    publicIP,
                    specialty: res.specialty,
                };
                browserStore.set("userInfo", dataToStore); // Store data in sessionStorage
                dispatch(login(dataToStore));

                trackAcvitity(
                    itemType.AUTHENTICATION,
                    itemName.LOGIN,
                    "Login Page >> Click 'Login' button >> Navigate to Home page"
                );
                navigate("/");
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        setInputValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <div className="login-form-page">
            <div className="form-content-wrapper">
                <img
                    alt=""
                    className="sikka-logo"
                    src={sikka_logo_transparent}
                    style={{ height: 36 }}
                />
                <h1 className="login-page-header" style={{ fontSize: 30 }}>
                    Login
                </h1>

                <div
                    style={{ maxWidth: 300 }}
                    className="d-flex flex-column justify-content-center align-items-center"
                >
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="login-form__input">
                            <label>Enter Your Email Id *</label>
                            <input
                                name="emailId"
                                required
                                placeholder="Email Id"
                                value={inputValues.emailId}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="login-form__input position-relative">
                            <label>Enter Your Password *</label>
                            <input
                                name="password"
                                type={`${visible ? "text" : "password"}`}
                                required
                                placeholder="Password"
                                value={inputValues.password}
                                onChange={handleChange}
                            />
                            <div
                                className="position-absolute"
                                style={{ right: 7, bottom: 26 }}
                            >
                                {visible ? (
                                    <VisibilityOffIcon
                                        className="font-gray"
                                        onClick={() => setVisible(false)}
                                    />
                                ) : (
                                    <VisibilityIcon
                                        className="font-purple"
                                        onClick={() => setVisible(true)}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className="font-purple"
                            role="button"
                            onClick={() => navigate("/forgotPassword")}
                        >
                            Forgot Password?
                        </div>{" "}
                        <button
                            className="button button-primary mt-3 width-100p"
                            type="submit"
                            disabled={loading ? true : false}
                        >
                            {loading ? (
                                <RotatingLines
                                    strokeColor="#ffffff"
                                    strokeWidth="4"
                                    width="22"
                                    visible={true}
                                />
                            ) : (
                                "Login"
                            )}
                        </button>
                        {error && (
                            <div className="mt-2 font-destructive text-center">
                                {error}
                            </div>
                        )}
                    </form>

                    <div className="fw-bold mt-3">
                        Don&apos;t have an account?{" "}
                        <span
                            className="font-purple"
                            role="button"
                            onClick={() => navigate("/authentication")}
                        >
                            Create
                        </span>{" "}
                        now
                    </div>
                </div>
            </div>

            <div className="company-copyright">
                © {new Date().getFullYear()} Sikka Software Corporation, The
                Practice Optimizer Company®. All Rights Reserved.
            </div>
        </div>
    );
}

export default Login;
