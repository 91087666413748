import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import SendVerificationOption from "./SendVerificationOption";
import VerifyCell from "./VerifyCell";
import VerifyEmail from "./VerifyEmail";
import useSetTitle from "../../../hooks/useSetTitle";

/**
 * when the page is mounted, check if user is logged in.
 * if yes, navigate to homepage
 * it not, check if there is an email or cell phone in reducer
 * if yes, stay in the page
 * else, navigate to authentication page
 */
function SendVerification() {
	const { authToken, maskedEmail, maskedCell } = useSelector(
		(state) => state.authentication
	);
	const navigate = useNavigate();
	const [selectedOption, setSelectedOption] = useState("");
	const [step, setStep] = useState(null); // 0 - ask user to choose email/phone, 1 - verify email, 2 - verify phone

	useSetTitle("Authentication");

	useEffect(() => {
		if (!authToken && (!maskedEmail || !maskedCell)) {
			// if there is no AuthToken and either email or cell >> Authentication Form
			navigate("/authentication");
		}
		// If there are both email and cell, stay in the page
		if (maskedEmail && maskedCell) {
			setStep(0);
			return;
		}
		// If there is only email
		if (maskedEmail && !maskedCell) {
			setStep(1);
			return;
		}

		// If there is only cell
		if (!maskedEmail && maskedCell) {
			setStep(2);
			return;
		}
	}, []);

	return (
		<div className="authentication-form-page">
			<div className="form-content-wrapper" style={{ maxWidth: 700 }}>
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 48, marginBottom: "3rem" }}
				/>

				{step === 0 && (
					<SendVerificationOption
						setStep={setStep}
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
					/>
				)}
				{step === 1 && <VerifyEmail />}
				{step === 2 && <VerifyCell />}
			</div>
		</div>
	);
}

export default SendVerification;
