import React from "react";

function CheckboxCell(props) {
	return (
		<td
			className={`checkbox-cell ${
				props.dataItem["selected"] === true
					? "bg-clr-light-purple "
					: ""
			}`}
			onClick={() => props.handleClickRow(props.dataItem)}
		>
			<input
				type="checkbox"
				style={{ marginTop: 5 }}
				checked={props.dataItem[props.field]}
				onChange={() =>
					props.handleCheck(
						props.dataItem["messageId"],
						props.mailType
					)
				}
				onClick={(e) => e.stopPropagation()}
			/>
		</td>
	);
}

export default CheckboxCell;
