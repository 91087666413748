import React, { useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import "./FollowUp.scss";
import ReadingFigure from "../../../assets/images/ReadingFigure.png";
import { useNavigate } from "react-router-dom";
import { browserStore } from "../../../utils";

function FollowupSuccess() {
	const navigate = useNavigate();

	useEffect(() => {
		if (!browserStore.get("follow-up-success")) {
			navigate("/not-found");
		}
	}, []);
	const handleGoBack = () => {
		sessionStorage.removeItem("follow-up-success");
		navigate("/authentication");
	};

	return (
		<div className="follow-up-page">
			<div
				className="follow-up-content-wrapper p-3 d-flex flex-column justify-content-center align-items-center text-center"
				style={{ maxWidth: 700 }}
			>
				<img src={ReadingFigure} style={{ width: 400 }} />

				<div className="mb-4 mt-5">
					Thank you for submitting the follow up form.
					<br />
					We will to get back to you with updates.
				</div>

				{/* <div className="my-4">
					Congratulations! You have successfully submitted the
					followup form.
				</div>

				<div className="font-purple">
					We will work with your dentist to make this information
					<br />
					available to you, please check back at a later time.
				</div> */}

				<div className="d-flex justify-content-center mt-3">
					<div
						role="button"
						className="d-flex justify-content-center align-items-center text-center goback-btn"
						style={{ color: "#681c9a", fontSize: 16 }}
						onClick={handleGoBack}
					>
						<ArrowBackIosIcon
							style={{ color: "#681c9a", fontSize: 16 }}
						/>
						Go Back
					</div>
				</div>
			</div>
		</div>
	);
}

export default FollowupSuccess;
