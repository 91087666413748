import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Joyride from "react-joyride";

import "./Dashboard.scss";
import EducationArticles from "./EducationArticles";
import PaymentsOverview from "./PaymentsOverview";
import Procedures from "./Procedures";
import SikkaScore from "./SikkaScore";
import HealthConditions from "./HealthConditions";
import PPP from "./PPP";
import useSetTitle from "../../hooks/useSetTitle";
import { DENTAL, VETERINARY, browserStore } from "../../utils";
import PetSelect from "../../components/selects/PetSelect";
import VaccinesAndTreatments from "./VaccinesAndTreatments";
import IncorrectInfoContact from "../../components/IncorrectInfoContact";
import {
    getJoyrideSteps,
    onboardingCallback,
    onboardingStyles,
} from "./dashboardHelpers";

function Dashboard() {
    const { firstName, specialty } = useSelector((state) => state.userInfo);
    const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);
    useSetTitle("Dashboard");

    useEffect(() => {
        if (window.innerWidth > 900 && browserStore.get("first_time_user")) {
            setIsJoyrideRunning(true);
            sessionStorage.removeItem("first_time_user");
        }
    }, []);

    return (
        <div className="db-page position-relative">
            {isJoyrideRunning && (
                <Joyride
                    steps={getJoyrideSteps(specialty, firstName)}
                    run={isJoyrideRunning}
                    continuous
                    scrollToFirstStep
                    showSkipButton
                    showProgress
                    callback={(data) =>
                        onboardingCallback(data, setIsJoyrideRunning)
                    }
                    styles={onboardingStyles}
                />
            )}
            <div className="top-bar">
                <h1 style={{ fontSize: 28 }} className="fw-bold font-purple">
                    Welcome to your Patient Dashboard, {firstName}!
                </h1>
                <div className="topbar-end-items d-flex align-items-center">
                    {/* <SaigeButton /> */}
                    <IncorrectInfoContact />
                </div>
            </div>
            <PetSelect />

            <div
                className="db-page__main"
                style={{ marginBottom: 32, marginTop: 20 }}
            >
                <div
                    className="db-page__main--left-col d-flex flex-column"
                    style={{ gap: 32 }}
                >
                    <PaymentsOverview />
                    <Procedures />
                </div>
                <div className="db-page__main--right-col d-flex flex-column justify-content-between">
                    {specialty === DENTAL && (
                        <h2 style={{ marginBottom: 32 }}>AI Tools</h2>
                    )}
                    <div
                        className="d-flex flex-column justify-content-between flex-1"
                        style={{ gap: 32 }}
                    >
                        <SikkaScore />
                        {specialty === DENTAL && <PPP />}
                        {specialty === VETERINARY && (
                            <>
                                {/* <WeightTracking /> */}
                                <VaccinesAndTreatments />
                            </>
                        )}
                        <HealthConditions />
                    </div>
                </div>
            </div>

            {specialty === DENTAL && <EducationArticles />}
        </div>
    );
}

export default Dashboard;
