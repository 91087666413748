import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DatePicker } from "antd";
import SignatureCanvas from "react-signature-canvas";
import Swal from "sweetalert2";

import "./AuthenticationForm.scss";
import { setAuthData, setShowNoMatchMessage } from "../../states/authSlice";
import sikka_logo_transparent from "../../assets/images/sikka_logo_transparent.png";
import { DENTAL, VETERINARY, convertBase64 } from "../../utils";
import { ApiCalls } from "../../api/allApiCalls";
import axios from "axios";
import ButtonLoader from "../../components/loaders/ButtonLoader";
import HIPPARulesModal from "../../components/modals/HIPPARulesModal";

const initErrors = {
	ssn: false,
	signature: false,
	checked: false,
};

const initState = {
	firstName: "",
	lastName: "",
	birthDate: "",
	ssn: "",
	checked: false,
	specialty: DENTAL,
	petName: "",
};

function AuthenticationForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [formValues, setFormValues] = useState(initState);
	const [formErrors, setFormErrors] = useState(initErrors);
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	let sigCanvas = {};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Validate SSN, HIPPA and signature
		const errObj = { ...initErrors };
		// SSN
		if (formValues.ssn && (formValues.ssn < 1000 || formValues.ssn > 10000))
			errObj["ssn"] = true;
		// Check HIPPA
		if (!formValues.checked) errObj["checked"] = true;
		// Signature
		if (sigCanvas.isEmpty()) errObj["signature"] = true;
		if (Object.values(errObj).includes(true)) {
			setFormErrors(errObj);
			return;
		}
		setFormErrors(initErrors);

		try {
			setLoading(true);
			const signatureBase64 = convertBase64(sigCanvas.toDataURL());
			let publicIp = "";
			await axios
				.get("https://ipwho.is")
				.then((res) => {
					publicIp = res?.data?.ip;
				})
				.catch((error) => {
					console.log(error);
				});

			const res = await ApiCalls.authenticationPOST(
				"/v2/SignUp/PatientVerification",
				{
					firstName: formValues.firstName.trim(),
					lastName: formValues.lastName.trim(),
					petName: formValues.petName.trim(),
					birthDate: formValues.birthDate,
					ssn: formValues.ssn,
					base64Data: signatureBase64,
					publicIp,
					machineGUID: "",
					specialty: formValues.specialty,
				}
			);

			// MATCHED >> Navigate to "send-verification-code"
			if (
				Array.isArray(res) &&
				res[0]?.authToken &&
				(res[0]?.email || res[0]?.cell)
			) {
				dispatch(
					setAuthData({
						authToken: res[0]?.authToken,
						maskedEmail: res[0]?.email,
						maskedCell: res[0]?.cell,
						firstName: formValues.firstName,
						lastName: formValues.lastName,
						ssn: formValues.ssn,
						birthDate: formValues.birthDate,
						base64Data: signatureBase64,
					})
				);

				// should we store in browser?
				navigate("send-verification-code");
			} else if (
				Array.isArray(res) &&
				res[0]?.authToken &&
				!res[0]?.email &&
				!res[0]?.cell
			) {
				Swal.fire({
					icon: "error",
					text: "Sorry, we couldn't find your email or cell phone on file. Please contact your provider to update your information before creating a new account.",
				});
			} else {
				// NO MATCH >> Navigate to Follow up
				dispatch(setShowNoMatchMessage());
				navigate("follow-up");
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "specialty") {
			setFormValues({ ...initState, specialty: value });
		} else {
			setFormValues((prev) => ({ ...prev, [name]: value }));
		}
	};

	const handleChangeDate = (date, dateString) => {
		setFormValues((prev) => ({ ...prev, birthDate: dateString }));
	};

	return (
		<div className="authentication-form">
			<div className="authentication-form-wrapper">
				<div className="d-flex justify-content-center">
					<img
						className="sikka-logo"
						alt="Sikka.ai"
						src={sikka_logo_transparent}
					/>
				</div>
				<h1 className="mb-4">Authentication Form</h1>

				<form onSubmit={handleSubmit}>
					<div className="authentication-form__personal-info">
						<div className="personal-info-flex-item">
							<label>Specialty</label>
							<select
								value={formValues.specialty}
								onChange={handleChange}
								name="specialty"
							>
								<option value={DENTAL}>{DENTAL}</option>
								<option value={VETERINARY}>{VETERINARY}</option>
							</select>
						</div>
						<div className="personal-info-flex-item"></div>

						<div className="personal-info-flex-item">
							<label>Enter Your First Name *</label>
							<input
								name="firstName"
								required
								placeholder="First Name"
								value={formValues.firstName}
								onChange={handleChange}
							/>
						</div>
						<div className="personal-info-flex-item">
							<label>Enter Your Last Name *</label>
							<input
								name="lastName"
								required
								placeholder="Last Name"
								value={formValues.lastName}
								onChange={handleChange}
							/>
						</div>
						{formValues.specialty === VETERINARY && (
							<div className="personal-info-flex-item">
								<label>Enter Your Pet Name</label>
								<input
									name="petName"
									placeholder="Pet Name"
									value={formValues.petName}
									onChange={handleChange}
								/>
							</div>
						)}
						<div className="personal-info-flex-item">
							<label>
								{formValues.specialty === DENTAL
									? "Enter Your Birthdate *"
									: "Enter Your Pet's Birthdate"}
							</label>
							<div className="position-relative">
								<input
									name="birthDate"
									required={
										formValues.specialty === DENTAL
											? true
											: false
									}
									className="birthdate-input"
									placeholder="mm/dd/yyyy"
									value={formValues.birthDate}
									onChange={handleChange}
									type="date"
									format="MM/DD/YYYY"
								/>
								<DatePicker
									required={
										formValues.specialty === DENTAL
											? true
											: false
									}
									format={"YYYY-MM-DD"}
									onChange={handleChangeDate}
								/>
							</div>
						</div>
						{formValues.specialty === DENTAL && (
							<div className="personal-info-flex-item">
								<label>
									Enter the last 4-digits of Your SSN
									{formErrors.ssn && (
										<span className="font-destructive fz-12 ms-2">
											Invalid SSN
										</span>
									)}
								</label>
								<input
									className={`${
										formErrors.ssn ? "input-error" : ""
									}`}
									name="ssn"
									type="number"
									placeholder="Enter the last 4-digits"
									value={formValues.ssn}
									onChange={handleChange}
								/>
							</div>
						)}
					</div>

					<div className="mt-3 mb-5">
						<div
							className={`mb-3 font-purple${
								formErrors.checked ? " font-destructive" : ""
							}`}
						>
							Please{" "}
							<span
								className="cursor-pointer text-underline"
								onClick={() => {
									setOpenModal(true);
								}}
							>
								review
							</span>{" "}
							and sign the HIPAA Authorization Document. By
							signing, you acknowledge that you have read and
							understood the rules and agree to abide by them.
						</div>
						<div>
							Your Signature Here *{" "}
							{formErrors.signature && (
								<span className="font-destructive fz-12">
									Signature is required
								</span>
							)}
						</div>
						<div
							className={`canvas-container border-radius-6 card-border position-relative${
								formErrors.signature ? " input-error" : ""
							}`}
							style={{ width: 500 }}
						>
							<SignatureCanvas
								penColor="black"
								canvasProps={{
									width: 500,
									height: 200,
									className: "sigCanvas",
								}}
								ref={(ref) => {
									sigCanvas = ref;
								}}
							/>
							<div
								className="font-purple position-absolute bottom-0"
								style={{ right: 5 }}
								role="button"
								onClick={() => sigCanvas.clear()}
							>
								Clear
							</div>
						</div>
					</div>

					<div className="my-5 btn-container">
						<button
							className="button button-primary d-flex align-items-center gap-3 justify-content-center"
							type="submit"
							disabled={loading ? true : false}
							style={{ width: 133 }}
						>
							{loading ? <ButtonLoader /> : "Submit"}
						</button>
					</div>
				</form>
			</div>

			{openModal && (
				<HIPPARulesModal
					openModal={openModal}
					closeModal={() => setOpenModal(false)}
					formValues={formValues}
					setFormValues={setFormValues}
					setFormErrors={setFormErrors}
				/>
			)}
		</div>
	);
}

export default AuthenticationForm;
