import React from "react";
import { Popover } from "antd";

function TransactionDescriptionCell(props) {
	return (
		<td className="treatment-plans-description-cell">
			<Popover
				placement="right"
				content={props.dataItem[props.field]}
				overlayStyle={{
					width: "200px",
				}}
			>
				<div>
					<p className="m-0">{props.dataItem[props.field]}</p>
				</div>
			</Popover>
		</td>
	);
}

export default TransactionDescriptionCell;
