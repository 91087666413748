import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

import "./PaymentOverview.scss";
import NoDocuments from "../../assets/images/NoDocuments.png";
import { DENTAL, VETERINARY, toCurrencyConverter } from "../../utils";
import { CustomTooltip } from "../../components/tooltips/customeTooltip";
import { ApiCalls } from "../../api/allApiCalls";
import { useSelector } from "react-redux";

const descriptions = [
    {
        title: "Amount Due",
        text: "The amount that is owed to the practice by the patient/guarantor.",
    },
    {
        title: "Benefits Available",
        text: "The amount of remaining benefits the patient can use to put towards upcoming treatment or existing balances.",
    },
    {
        title: "Amount Paid",
        text: "The amount the patient has paid towards their total expenses.",
    },
    {
        title: "Total Expenses",
        text: "The total combined cost of all the patient’s procedures.",
    },
];

function PaymentsOverview() {
    const { masterId, userId, practiceId, custId, patientId, specialty } =
        useSelector((state) => state.userInfo);
    const { selectedPet } = useSelector((state) => state.petInfo);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const PaymentsOverviewTooltipContent = (
        <div className="d-flex align-items-start" style={{ fontSize: "13px" }}>
            <div className="mt-2 ms-4 d-flex flex-column gap-4">
                {descriptions.map((i) => {
                    if (
                        specialty === VETERINARY &&
                        i.title === "Benefits Available"
                    )
                        return (
                            <div
                                key={i.title}
                                style={{ display: "none" }}
                            ></div>
                        );
                    return (
                        <div key={i.title}>
                            <h4 className="fz-16 text-dark">{i.title}:</h4>
                            <div style={{ color: "#414d55" }}>{i.text}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await Promise.all([
                    ApiCalls.authGetAPI("/v2/Patient/PatientFinancialData", {
                        masterId,
                        userId,
                        practiceId,
                        custId,
                        patientId:
                            specialty === DENTAL
                                ? patientId
                                : selectedPet.patientId,
                    }),
                    ApiCalls.authGetAPI("/v2/Patient/AccountsReceivableData", {
                        masterId,
                        userId,
                        practiceId,
                        custId,
                        patientId:
                            specialty === DENTAL
                                ? patientId
                                : selectedPet.patientId,
                    }),
                ]);

                const { amtLess30, amt30to60, amt60to90, amt90Above } =
                    res[1].guarantor[0];
                let amountDue = amtLess30 + amt30to60 + amt60to90 + amt90Above;

                setData({
                    amountDue,
                    benefitsAvailable: res[0][0].benefitsAvailable,
                    amountPaid: res[0][0].amountPaid,
                    expenses: res[0][0].procedureTotal,
                });
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        if (specialty === VETERINARY && !selectedPet) return;
        fetchData();
    }, [selectedPet]);

    return (
        <div
            className="db-payment-overview bg-white position-relative"
            id="db-payment-overview-id"
        >
            <div
                className="d-flex align-items-center"
                style={{ marginBottom: 24, height: 40 }}
            >
                <h2 style={{ marginTop: 8 }}>Payments Overview</h2>
            </div>

            <div className="position-relative payment-overview">
                {loading && (
                    <div
                        style={{
                            height: 108,
                            zIndex: 1,
                            borderRadius: 6,
                        }}
                        className="position-absolute bg-white top-0 start-0 width-100p d-flex align-items-center justify-content-center"
                    >
                        <Oval
                            height={50}
                            width={50}
                            color="#681c9a"
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#f3e1ff"
                            strokeWidth={5}
                            strokeWidthSecondary={4}
                        />
                    </div>
                )}
                {data && (
                    <CustomTooltip title={PaymentsOverviewTooltipContent}>
                        <div className="payment-overview-container">
                            <div className="payment-item payment-item-1">
                                <div className="title">Amount Due</div>
                                <div className="amount">
                                    {toCurrencyConverter(data.amountDue)}
                                </div>
                                <div className="description">
                                    Owed by Guarantor
                                </div>
                            </div>
                            {specialty === "Dental" && (
                                <div className="payment-item payment-item-2">
                                    <div className="title">
                                        Benefits Available
                                    </div>
                                    <div className="amount">
                                        {toCurrencyConverter(
                                            data.benefitsAvailable
                                        )}
                                    </div>
                                    <div className="description">
                                        Your unused benefits
                                    </div>
                                </div>
                            )}
                            <div className="payment-item payment-item-3">
                                <div className="title">Amount Paid</div>
                                <div className="amount">
                                    {toCurrencyConverter(data.amountPaid)}
                                </div>
                                <div className="description">
                                    Amount you&apos;ve paid
                                </div>
                            </div>
                            <div className="payment-item payment-item-4">
                                <div className="title">Total Expenses</div>
                                <div className="amount">
                                    {toCurrencyConverter(data.expenses)}
                                </div>
                                <div className="description">
                                    Expenses calculated til{" "}
                                    {new Date().toLocaleString("en-US", {
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </div>
                            </div>
                        </div>
                    </CustomTooltip>
                )}
                {!data && !loading && (
                    <div
                        style={{ height: 111 }}
                        className="d-flex flex-column align-items-center width-100p"
                    >
                        <img
                            alt=""
                            src={NoDocuments}
                            style={{ maxHeight: 76 }}
                        />
                        <div className="fz-14">No Record Available</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PaymentsOverview;
