import React, { useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Col } from "@progress/kendo-react-grid";
import { Collapse } from "antd";
const { Panel } = Collapse;
import { CSVLink } from "react-csv";

import "./TreatmentPlans.scss";
import TreatmentPlansDescriptionCell from "./TreatmentPlansDescriptionCell";
import TreatmenPlansStatusCell from "./TreatmenPlansStatusCell";
import NoDocument from "../../assets/images/NoDocument.png";
import { ApiCalls } from "../../api/allApiCalls";
import { useSelector } from "react-redux";
import CardLoader from "../../components/loaders/CardLoader";
import {
    DENTAL,
    VETERINARY,
    formatISOtoShortMonthDate,
    toCurrencyConverter,
} from "../../utils";
import useSetTitle from "../../hooks/useSetTitle";
import AmountCell from "../../components/tables/AmountCell";
import DateCell from "../../components/tables/DateCell";
import Download from "../../assets/images/Download.png";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";
import IncorrectInfoContact from "../../components/IncorrectInfoContact";
//import SaigeButton from "../../components/SAIGE/SaigeButton";
import PetSelect from "../../components/selects/PetSelect";

export const initTableState = {
    skip: 0,
    take: 20,
    sort: [
        {
            field: "creationDate", // latest date shows up on top
            dir: "desc",
        },
    ],
    filter: null,
};

function TreatmentPlans() {
    const { masterId, userId, practiceId, custId, patientId, specialty } =
        useSelector((state) => state.userInfo);
    const { selectedPet } = useSelector((state) => state.petInfo);
    const [treatmentPlanData, setTreatmentPlanData] = useState([]);
    const [data, setData] = useState(process([], initTableState));
    const [state, setState] = useState(initTableState);
    const [loading, setLoading] = useState(true);
    const [csvData, setCsvData] = useState([]);

    useSetTitle("Treatment Plans");

    useEffect(() => {
        const fetchTreatmentPlanData = async () => {
            try {
                setLoading(true);
                const res = await ApiCalls.authGetAPI(
                    "/v2/Patient/PendingTreatmentPlanData",
                    {
                        masterId,
                        userId,
                        practiceId,
                        custId,
                        patientId:
                            specialty === VETERINARY
                                ? selectedPet.patientId
                                : patientId,
                    }
                );

                const updatedRes = res.map((item, idx) => ({
                    ...item,
                    orderNum: idx + 1,
                }));

                setTreatmentPlanData(updatedRes);
                setData(process(updatedRes, state));

                let formattedRes = {};

                if (specialty === DENTAL) {
                    formattedRes = res.map((item) => ({
                        "Provider Name": item.providerName,
                        "Procedure Code": item.procedureCode,
                        "Creation Date": formatISOtoShortMonthDate(
                            item.creationDate
                        ),
                        Description: item.description,
                        "From Tooth": item.fromTooth,
                        "To Tooth": item.toTooth,
                        Amount: toCurrencyConverter(item.amount),
                        Status: item.status,
                    }));
                } else if (specialty === VETERINARY) {
                    formattedRes = res.map((item) => ({
                        "Provider Name": item.providerName,
                        "Procedure Code": item.procedureCode,
                        "Creation Date": formatISOtoShortMonthDate(
                            item.creationDate
                        ),
                        Description: item.description,
                        Amount: toCurrencyConverter(item.amount),
                        Status: item.status,
                    }));
                }

                setCsvData(formattedRes);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        if (specialty === VETERINARY && !selectedPet) return;
        fetchTreatmentPlanData();
    }, [selectedPet]);

    const dataStateChange = (event) => {
        setData(process(treatmentPlanData, event.dataState));
        setState(event.dataState);
    };

    return (
        <div style={{ padding: 13 }}>
            <div className="top-bar">
                {/* <SaigeButton /> */}
                <IncorrectInfoContact />
            </div>
            <div className="treatment-page">
                <div className="position-relative mt-4 mb-3">
                    <h2 className="fz-20 fw-bold">
                        {specialty === DENTAL
                            ? "Pending Treatment Plans"
                            : "Estimates"}
                    </h2>

                    <PetSelect />

                    <CSVLink
                        className="download-treatment-plan-icon position-absolute d-flex align-items-center gap-2"
                        filename={
                            specialty === DENTAL
                                ? "Treatment_Plans.csv"
                                : "Estimates.csv"
                        }
                        data={csvData}
                        onClick={() => {
                            trackAcvitity(
                                itemType.EXPORT_DATA,
                                itemName.EXPORT_EXCEL,
                                "Treatment Plans page >> Click download icon >> Download Excel"
                            );
                        }}
                        style={{
                            right: 0,
                            bottom: 0,
                            textDecoration: "none",
                            color: "#681c9a",
                        }}
                    >
                        <span>
                            Download{" "}
                            {specialty === DENTAL
                                ? "Treatment Plan"
                                : "Estimates"}
                        </span>
                        <img
                            style={{ width: 25, height: 25 }}
                            alt="Download Excel"
                            src={Download}
                            role="button"
                        />
                    </CSVLink>
                </div>

                <div
                    className="treatment-plans-tbl-container position-relative"
                    style={{ overflowY: "hidden", overflowX: "auto" }}
                >
                    {loading && <CardLoader />}
                    {data.data.length !== 0 && (
                        <>
                            <Grid
                                data={data}
                                onDataStateChange={dataStateChange}
                                {...state}
                                pageable={{
                                    buttonCount: 4,
                                    pageSizes: [10, 20, 50],
                                }}
                                resizable={true}
                                sortable={true}
                            >
                                <Col title="#" field="orderNum" width={42} />
                                <Col title="Provider" field="providerName" />
                                <Col
                                    title="Creation Date"
                                    field="creationDate"
                                    cell={DateCell}
                                    width={150}
                                />
                                <Col title="Proc Code" field="procedureCode" />
                                <Col
                                    title="Descripton"
                                    field="description"
                                    cell={TreatmentPlansDescriptionCell}
                                />
                                {specialty === DENTAL && (
                                    <Col title="From Tooth" field="fromTooth" />
                                )}
                                {specialty === DENTAL && (
                                    <Col title="To Tooth" field="toTooth" />
                                )}
                                <Col
                                    title="Amount"
                                    field="amount"
                                    cell={AmountCell}
                                />
                                <Col
                                    title="Status"
                                    field="status"
                                    width="136"
                                    cell={TreatmenPlansStatusCell}
                                />
                            </Grid>

                            <div className="sm-treatment-plan-details">
                                <Collapse defaultActiveKey={["1"]}>
                                    {treatmentPlanData.map((item, idx) => (
                                        <Panel
                                            header={
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="fw-bold">
                                                        {item.procedureCode}
                                                    </div>
                                                    <div>
                                                        {formatISOtoShortMonthDate(
                                                            item.creationDate
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                            key={idx + 1}
                                        >
                                            <div className="d-flex flex-column gap-3">
                                                <div className="d-flex gap-5 justify-content-between flex-wrap">
                                                    <div>
                                                        <span className="fw-bold">
                                                            Proc Code:{" "}
                                                        </span>
                                                        {item.procedureCode}
                                                    </div>
                                                    <div className="flex-1">
                                                        <span className="fw-bold">
                                                            Provider:{" "}
                                                        </span>
                                                        {item.providerName}
                                                    </div>
                                                </div>

                                                <div>{item.description}</div>

                                                <div className="d-flex gap-5 justify-content-between flex-wrap">
                                                    <div>
                                                        <span className="fw-bold">
                                                            From Tooth:{" "}
                                                        </span>
                                                        {item.fromTooth}
                                                    </div>
                                                    <div>
                                                        <span className="fw-bold">
                                                            To Tooth:{" "}
                                                        </span>
                                                        {item.toTooth}
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                    <div>
                                                        <span className="fw-bold">
                                                            Amount:{" "}
                                                        </span>
                                                        {toCurrencyConverter(
                                                            item.amount
                                                        )}
                                                    </div>
                                                    <div
                                                        className="px-3 py-1 border-radius-6"
                                                        style={{
                                                            backgroundColor: `${
                                                                item.status ===
                                                                "Scheduled"
                                                                    ? "#fef9ff"
                                                                    : "#fefaf3"
                                                            }`,
                                                            border: `solid 1px ${
                                                                item.status ===
                                                                "Scheduled"
                                                                    ? "#681c9a"
                                                                    : "#efa10b"
                                                            }`,
                                                            color: `${
                                                                item.status ===
                                                                "Scheduled"
                                                                    ? "#681c9a"
                                                                    : "#efa10b"
                                                            }`,
                                                        }}
                                                    >
                                                        {item.status}
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                        </>
                    )}

                    {data.data.length === 0 && (
                        <div
                            className="no-treatment-plan d-flex flex-column justify-content-center align-items-center text-center p-3"
                            style={{ minHeight: "calc(100dvh - 134px)" }}
                        >
                            <img
                                alt=""
                                src={NoDocument}
                                style={{ width: "30%" }}
                            />

                            <div className="fz-18 fw-bold mb-2 font-black">
                                No Treatment Plan For You
                            </div>
                            <div className="fz-16 font-gray">
                                To start with your treatment plan, contact your
                                practice
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TreatmentPlans;
