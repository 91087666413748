import React from "react";
import { formatISOtoShortMonthDate } from "../../../utils";

function MessagesDateCell(props) {
	return (
		<td
			className={`${
				props.dataItem["selected"] === true
					? "bg-clr-light-purple "
					: ""
			}`}
			onClick={() => props.handleClickRow(props.dataItem)}
		>
			{formatISOtoShortMonthDate(props.dataItem[props.field])}
		</td>
	);
}

export default MessagesDateCell;
