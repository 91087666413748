import React from "react";

import "./PaymentsAndBenefits.scss";
import AccountsReceivable from "./accountsReceivable/AccountsReceivable";
import PatientFinances from "./patientFinances/PatientFinances";
import TransactionHistory from "./transactionHistory/TransactionHistory";
import useSetTitle from "../../hooks/useSetTitle";
import IncorrectInfoContact from "../../components/IncorrectInfoContact";
import FinancesTooltip from "../../components/tooltips/FinancesTooltip";
import AccountsReceivableTooltip from "../../components/tooltips/AccountsReceivableTooltip";
//import SaigeButton from "../../components/SAIGE/SaigeButton";
import PetSelect from "../../components/selects/PetSelect";

function PaymentsAndBenefits() {
    useSetTitle("Payment And Benefits");

    return (
        <div style={{ padding: 13 }}>
            <div className="top-bar">
                {/* <SaigeButton /> */}
                <IncorrectInfoContact />
            </div>
            <div className="pb-page">
                <PetSelect />

                <div className="pb-grid pt-4">
                    <div className="pb-grid-item pb-grid-item-1">
                        <h3>
                            Finances
                            <FinancesTooltip />
                        </h3>
                        <div className="pb-card">
                            <PatientFinances />
                        </div>
                    </div>

                    <div className="pb-grid-item pb-grid-item-2">
                        <h3>
                            Accounts Receivable
                            <AccountsReceivableTooltip />
                        </h3>
                        <div className="pb-card">
                            <AccountsReceivable />
                        </div>
                    </div>

                    <div className="pb-grid-item pb-grid-item-3 d-flex flex-column justify-content-between position-relative">
                        <h3>Transaction History</h3>
                        <TransactionHistory />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentsAndBenefits;
