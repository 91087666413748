import React, { useEffect, useState } from "react";
import { ApiCalls } from "../../api/allApiCalls";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSetTitle from "../../hooks/useSetTitle";
import outlook from "../../assets/images/Microsoft_Outlook-Logo.wine.png";
import googlecalender from "../../assets/images/Google-Calendar-Logo.png";
import "./icalender.scss";
import Trademark from "../Trademark/Trademark";
import sikka_logo from "../../assets/images/sikka_logo_transparent.png";
import Big_spinner from "../../assets/images/Spinner.gif";

const Icalender = () => {
    const [practiceLogo, setPracticeLogo] = useState("");
    useSetTitle("Calendar");
    const currentUrl = window.location.href;
    const { apiToken } = useSelector((state) => state.userInfo);
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(true);
    const [referenceId, setReferenceId] = useState(null);
    const [defaultUI, setDefaultUI] = useState(false);
    const [datas, setData] = useState([]);
    const [showDate, setShowDate] = useState("");

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const url = window.location.href;
        const parameters = {};
        const fullURL = url.split("?");

        const queryParameters = fullURL[1].split("&");

        // queryParameters.forEach((parameter) => {
        //     const [key, value] = parameter.split("=");
        //     parameters[key] = value;
        // });
        queryParameters.forEach((parameter) => {
            const firstEqualsIndex = parameter.indexOf("=");
            if (firstEqualsIndex !== -1) {
                const key = parameter.substring(0, firstEqualsIndex);
                const value = parameter.substring(firstEqualsIndex + 1);
                parameters[key] = value;
            }
        });

        // const referenceId = parameters["ReferenceId"];
        // const sid = parameters["sid"];

        if (!parameters["ReferenceId"] || apiToken) {
            return navigate("*");
        }
        setReferenceId(encodeURIComponent(parameters["ReferenceId"]));
    }, [currentUrl, apiToken, navigate]);

    useEffect(() => {
        if (!referenceId) return;

        const fetchData = async () => {
            try {
                const res = await ApiCalls.authenticationGET(
                    `/v2/EmailResponse/AddToCalendarData?ReferenceId=${referenceId}`
                );
                console.log(res);
                setData(res);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();

        const fetchPracticeLogo = async () => {
            try {
                const res = await ApiCalls.authenticationGET(
                    `/v2/EmailResponse/PracticeLogo?ReferenceId=${referenceId}`
                );
                setPracticeLogo(res.logoUrl);
            } catch (err) {
                console.error(err);
            }
        };

        fetchPracticeLogo();
    }, [referenceId]);

    function gUrl(googleUrl) {
        window.open(`${googleUrl}`);
    }
    useEffect(() => {
        if (!datas[0]?.googleUrl && !datas[0]?.outLook) {
            setDefaultUI(true);
        } else {
            setDefaultUI(false);
        }
    }, [datas]);

    useEffect(() => {
        if (datas[0]?.outLookUrl) {
            let oUrl = datas[0].outLookUrl;
            let queryParams = {};
            const parameter = oUrl.slice(1).split("&");
            parameter.forEach((param) => {
                const [key, value] = param.split("=");
                queryParams[key] = decodeURIComponent(
                    value.replace(/\+/g, " ")
                );
            });

            // Assuming queryParams.startD is in the format "YYYYMMDDTHHMMSS"
            const dateString = queryParams.startD;
            const year = dateString.slice(0, 4);
            const month = dateString.slice(4, 6);
            const day = dateString.slice(6, 8);
            const hours = dateString.slice(9, 11);
            const minutes = dateString.slice(11, 13);
            const seconds = dateString.slice(13, 15);

            // Creating a new Date object
            const formattedDate = new Date(
                year,
                month - 1,
                day,
                hours,
                minutes,
                seconds
            );

            // Options for formatting the date
            const options = {
                month: "long",
                day: "numeric",
                year: "numeric",
            };

            // Converting and logging the formatted date
            const convertedDate = formattedDate.toLocaleDateString(
                "en-US",
                options
            );
            setShowDate(convertedDate);
        }
    }, [datas]);

    // Looping through the parameters and storing them in an object

    const generateICSCalendar = () => {
        if (datas[0].outLookUrl.length === 0) {
            return navigate("*");
        }
        let oUrl = datas[0].outLookUrl;

        let queryParams = {};
        const parameter = oUrl.slice(1).split("&");
        parameter.forEach((param) => {
            const [key, value] = param.split("=");
            queryParams[key] = decodeURIComponent(value);
        });
        // Define the variables for demo purposes
        function convertDateString(inputDateString) {
            // Parse the input date string
            const year = parseInt(inputDateString.substr(0, 4), 10);
            const month = parseInt(inputDateString.substr(4, 2), 10) - 1; // Months are zero-based in JavaScript Dates
            const day = parseInt(inputDateString.substr(6, 2), 10);
            const hours = parseInt(inputDateString.substr(9, 2), 10);
            const minutes = parseInt(inputDateString.substr(11, 2), 10);
            const seconds = parseInt(inputDateString.substr(13, 2), 10);

            // Create a new Date object using the parsed values
            const parsedDate = new Date(
                year,
                month,
                day,
                hours,
                minutes,
                seconds
            );
            const date = new Date(parsedDate);

            // Check if the parsed date is valid
            if (isNaN(date.getTime())) {
                console.error(`Invalid date string: ${inputDateString}`);
                throw new Error("Invalid date string");
            }

            // Create a custom formatted date string
            const formattedDate = `${date.getUTCFullYear()}-${padZero(
                date.getUTCMonth() + 1
            )}-${padZero(date.getUTCDate())}T${padZero(
                date.getUTCHours()
            )}:${padZero(date.getUTCMinutes())}:${padZero(
                date.getUTCSeconds()
            )}:00Z`;

            return formattedDate;
        }

        // Helper function to pad zeros
        function padZero(num) {
            return num.toString().padStart(2, "0");
        }

        const apptStartDate = `${convertDateString(queryParams.startD)}`;
        const apptEndDate = `${convertDateString(queryParams.endD)}`;
        const emailSubject = `${queryParams.subject}`;
        const location = `${queryParams.location}`;
        const emailDescription = `${queryParams.description}`;

        // Create the iCalendar content string
        const icalContent = `
BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VEVENT
SUMMARY:${emailSubject}
DTSTART:${apptStartDate}
DTEND:${apptEndDate}
LOCATION:${location}
DESCRIPTION:${emailDescription}
STATUS:CONFIRMED
SEQUENCE:3
BEGIN:VALARM
TRIGGER:-PT10M
DESCRIPTION:Pickup Reminder
ACTION:DISPLAY
END:VALARM
END:VEVENT
END:VCALENDAR
`;

        return icalContent;
    };

    const handleDownload = () => {
        const icalContentString = generateICSCalendar();

        // Create a Blob from the icalContentString
        const blob = new Blob([icalContentString], {
            type: "text/calendar;charset=utf-8",
        });

        // Create a download link and trigger the click event
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Schedule_${new Date().toISOString()}.ics`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {showSpinner ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                        background: `url('${sikka_logo}') center center no-repeat`,
                        backgroundSize: "40%",
                    }}
                >
                    <div style={{ marginTop: "200px" }}>
                        <img
                            src={Big_spinner}
                            alt="Loading..."
                            height={80}
                            width={80}
                        />
                    </div>
                </div>
            ) : (
                <div className="calendar-page">
                    <div style={{ textAlign: "center" }}>
                        <img
                            style={{ width: "200px", height: "200px" }}
                            src={practiceLogo}
                            alt="Practice Logo"
                        ></img>
                    </div>
                    {defaultUI ? (
                        <p style={{ textAlign: "center" }}>
                            Thank you. Our records show the appointment is not
                            active anymore. If you think there is an error,
                            please call us: 8015555555
                        </p>
                    ) : (
                        <>
                            <div
                                style={{ textAlign: "center" }}
                                className="mt-3"
                            >
                                <h5>
                                    Please add below scheduled appointment
                                    details to your calendar!
                                </h5>
                                <h5 style={{ color: "blue" }}>{showDate}</h5>

                                {datas.map((data, index) => (
                                    <div key={index}>
                                        <div
                                            className="row "
                                            style={{
                                                width: "60%",
                                                margin: "auto",
                                                backgroundColor: "#e9ecef",
                                                padding: "10px",
                                            }}
                                        >
                                            <div className="col-5">
                                                <b>
                                                    {data.name}&nbsp;:&nbsp;
                                                    {data.time}
                                                </b>
                                            </div>
                                            <div className="col-3">
                                                <img
                                                    style={{ width: "50px" }}
                                                    src={outlook}
                                                    alt="Outlook"
                                                />
                                                <span
                                                    onClick={handleDownload}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "blueviolet",
                                                    }}
                                                >
                                                    Outlook
                                                </span>
                                            </div>
                                            <div className="col-4">
                                                <img
                                                    style={{ width: "50px" }}
                                                    src={googlecalender}
                                                    alt="Google Calendar"
                                                />
                                                <span
                                                    onClick={() =>
                                                        gUrl(data.googleUrl)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "blueviolet",
                                                    }}
                                                >
                                                    Google Calendar
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    <Trademark />
                </div>
            )}
        </>
    );
};

export default Icalender;
