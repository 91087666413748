import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { CustomTooltip } from "./customeTooltip";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";

function FinancesTooltip() {
	const FinancesContent = (
		<div className="d-flex align-items-start" style={{ fontSize: "13px" }}>
			<div className="mt-2 ms-4">
				<h4 className="fz-18 text-dark">Finances:</h4>
				<div style={{ color: "#414d55" }}>
					Here you can see the total cost of all the procedures you
					have had with your practice, along with how much of that
					balance you have paid off and what could be paid off with
					benefits you still have available.
				</div>
			</div>
		</div>
	);

	return (
		<CustomTooltip title={FinancesContent}>
			<InfoIcon
				className="font-purple ms-2"
				fontSize="small"
				onMouseEnter={() =>
					trackAcvitity(
						itemType.DESCRIPTION,
						itemName.HOVER_FINANCES_TOOLTIP,
						"Payment & Benefits page >> Hover on Finances tooltip"
					)
				}
			/>
		</CustomTooltip>
	);
}

export default FinancesTooltip;
