import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import styled from "styled-components";

const StyledPasswordValidation = styled.div`
	.no-err {
		color: #0fa90f;
	}
`;

const checkIcon = <CheckIcon fontSize="small" style={{ marginRight: 5 }} />;

function PasswordValidation({ passwordErr }) {
	return (
		<StyledPasswordValidation>
			<div
				className={`password-condition-item ${
					!passwordErr.min ? "no-err" : ""
				}`}
			>
				{checkIcon}
				At least 12 characters
			</div>
			<div
				className={`password-condition-item ${
					!passwordErr.max ? "no-err" : ""
				}`}
			>
				{checkIcon}
				At max 40 characters
			</div>
			<div
				className={`password-condition-item ${
					!passwordErr.uppercase ? "no-err" : ""
				}`}
			>
				{checkIcon}At least one capital letter
			</div>
			<div
				className={`password-condition-item ${
					!passwordErr.lowercase ? "no-err" : ""
				}`}
			>
				{checkIcon}At least one small letter
			</div>
			<div
				className={`password-condition-item ${
					!passwordErr.specialChar ? "no-err" : ""
				}`}
			>
				{checkIcon}At least one special character
			</div>
			<div
				className={`password-condition-item ${
					!passwordErr.number ? "no-err" : ""
				}`}
			>
				{checkIcon}At least one number
			</div>
			<div
				className={`password-condition-item ${
					!passwordErr.matching ? "no-err" : ""
				}`}
			>
				{checkIcon}
				Matching passwords
			</div>
		</StyledPasswordValidation>
	);
}

export default PasswordValidation;
