import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./shedule.scss";
import Grid from "@mui/material/Grid";
import useSetTitle from "../hooks/useSetTitle";
import { useSelector } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { ApiCalls } from "../api/allApiCalls";
import Swal from "sweetalert2";
import sikka_logo from "../assets/images/sikka_logo_transparent.png";
import ErrorPng from "../assets/images/Error.png";
import Big_spinner from "../assets/images/Spinner.gif";
import { itemName, itemType, trackAcvitity } from "../utils/activityTracking";
import { useNavigate } from "react-router-dom";
import Trademark from "./Trademark/Trademark";
import Tooltip from "@mui/material/Tooltip";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
const tooltipContent = (
    <div
        style={{
            maxWidth: 300,
            backgroundColor: "blueviolet",
            padding: "0px",
            margin: "0px",
        }}
    >
        The Patient Portal is a user-friendly platform designed to empower
        individuals with seamless access to their healthcare journey. Upon
        login, patients can effortlessly schedule self-appointments, ensuring
        convenience and flexibility in managing their healthcare needs. The
        portal provides a comprehensive overview of treatment plans, enabling
        patients to stay informed about their health journey. Additionally,
        users can conveniently view and manage payments and benefits, fostering
        transparency and control over financial aspects. Communication with
        healthcare providers is streamlined through messages and emails directly
        within the portal, facilitating a secure and efficient channel for
        inquiries and updates. The Patient Portal also offers the flexibility to
        modify profile details, ensuring that personal information is always
        up-to-date. With these features, the Patient Portal aims to enhance the
        patient experience by placing control and information at the fingertips
        of individuals, ultimately contributing to a more engaged and informed
        healthcare journey.
    </div>
);

const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
const initialSlotTime = [
    { time: "7:00 AM" },
    { time: "7:30 AM" },
    { time: "8:00 AM" },
    { time: "8:30 AM" },
    { time: "9:00 AM" },
    { time: "9:30 AM" },
    { time: "10:00 AM" },
    { time: "10:30 AM" },
    { time: "11:00 AM" },
    { time: "11:30 AM" },
    { time: "12:00 PM" },
    { time: "12:30 PM" },
    { time: "2:00 PM" },
    { time: "2:30 PM" },
    { time: "3:00 PM" },
    { time: "3:30 PM" },
    { time: "4:00 PM" },
    { time: "4:30 PM" },
    { time: "5:00 PM" },
    { time: "5:30 PM" },
    { time: "6:00 PM" },
    { time: "6:30 PM" },
];

const Schedule = () => {
    useSetTitle("Appointments");
    const { apiToken } = useSelector((state) => state.userInfo);
    const navigate = useNavigate();

    const currentUrl = window.location.href;
    const fullURL = currentUrl.split("?");
    const parameters = {};
    const queryParameters = fullURL[1].split("&");
    queryParameters.forEach((parameter) => {
        const firstEqualsIndex = parameter.indexOf("=");
        if (firstEqualsIndex !== -1) {
            const key = parameter.substring(0, firstEqualsIndex);
            const value = parameter.substring(firstEqualsIndex + 1);
            parameters[key] = value;
        }
    });
    const refId = parameters["ReferenceId"];
    const referenceId = encodeURIComponent(refId);

    useEffect(() => {
        if (!referenceId || apiToken) return navigate("*");
    }, []);

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const [date, setDate] = useState(tomorrow); //selected Date

    const formatDate = (dateObj) => {
        const dateStr = dateObj.toLocaleString("en-US");

        const splitted = dateStr.split(",");
        const [m, d, y] = splitted[0].split("/");

        return `${y}-${m.padStart(2, "0")}-${d.padStart(2, "0")}`;
    };

    var day = date.getDate();
    var month = date.getMonth();
    var year = date.getFullYear();
    const formattedMonth = shortMonthNames[month];
    const [error, setError] = useState(false);
    const [provider, setProvider] = useState("");
    const [procedure, setProcedure] = useState("");
    const [indexStore, setIndexStore] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const [appointmentData, setAppointmentData] = useState({
        doctor: "",
        treatment: "",
    });

    const [disableSlots, setDisableSlots] = useState(false);

    const [selectedAppointments, setSelectedAppointments] = useState([]);
    const [petData, setPetData] = useState([]); //here store petData
    const [comments, setComment] = useState("");
    const [specialty, setSpecialty] = useState("");
    const [slots, setSlots] = useState(initialSlotTime);
    const [showSpinner, setShowSpinner] = useState(true);
    const [selectedPet, setSelectedPet] = useState("");
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [showSpinner]);

    useEffect(() => {
        const fetchSpecialty = async () => {
            if (!referenceId) {
                return;
            } else {
                try {
                    const res = await ApiCalls.authenticationGET(
                        `/v2/EmailResponse/Specialty?ReferenceId=${referenceId}`
                    );

                    setSpecialty(res.specialty);
                } catch (err) {
                    console.log(err);
                }
            }
        };
        fetchSpecialty();
    }, [referenceId]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Promise.all([
                    ApiCalls.authenticationGET(
                        `/v2/EmailResponse/ProcedureCategory?Specialty=${specialty}`
                    ),
                    ApiCalls.authenticationGET(
                        `/v2/EmailResponse/Providers?ReferenceId=${referenceId}`
                    ),
                ]);

                setDoctors(res[1]);

                const treatmentList = res[0].map((i) => i.categoryName);
                setTreatments(treatmentList);
                setAppointmentData((prev) => ({
                    ...prev,
                    treatment: treatmentList[0],
                }));
            } catch (err) {
                console.log(err);
                if (err) return navigate("*");
            }
        };

        fetchData();
    }, [specialty]);

    useEffect(() => {
        const fetchSlots = async () => {
            if (!procedure || !provider) {
                return;
            } else {
                try {
                    const dateString = formatDate(date);
                    const res = await ApiCalls.authenticationGET(
                        `/v2/EmailResponse/PracticeOpenSlots?ReferenceId=${referenceId}&ApptDate=${dateString}&ProcedureType=${procedure}&ProviderId=${provider}`
                    );

                    if (res[0]?.time) setSlots(res[0]?.time);
                } catch (err) {
                    console.log(err);
                }
            }
        };
        fetchSlots();
    }, [provider, date, procedure]);

    useEffect(() => {
        setSelectedAppointments([]);
    }, [provider, procedure]);

    const isDateDisabled = () => {
        if (!provider || !procedure) {
            setDisableSlots(false);
        }

        return false;
    };

    const handleOnChange = (date) => {
        setDate(date);
    };
    const handleComment = (e) => {
        const commentsString = e.target.value;
        setComment(commentsString);
    };
    const removeSelectedAppointments = (appointment) => {
        const removeAppointment = selectedAppointments.filter(
            (item) => item.time !== appointment.time
        );
        setSelectedAppointments(removeAppointment);
    };

    const setSlotButtonColor = (index) => {
        if (selectedAppointments.length === 3) {
            return;
        }
        if (
            !provider ||
            !procedure ||
            (specialty === "Veterinary" && !selectedPet)
        ) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        if (
            !appointmentData.doctor ||
            !appointmentData.treatment ||
            !provider ||
            !procedure
        ) {
            return;
        }

        setIndexStore([index]);
        const checkExistingId = selectedAppointments.some(
            (item) =>
                item.time === index.time &&
                item.day === day &&
                item.formattedMonth === formattedMonth &&
                item.year === year &&
                item.treatment === procedure &&
                item.providerId === provider
        );
        if (checkExistingId) {
            setDisableSlots(false);
            return;
        }

        const selectedAppointment = {
            day,
            formattedMonth,
            year,
            time: index.time,
            providerId: provider,
            treatment: procedure,
        };

        setSelectedAppointments([...selectedAppointments, selectedAppointment]);
    };
    useEffect(() => {
        setSlotButtonColor;
    }, [selectedAppointments, date]);

    useEffect(() => {
        const checkExistingId = selectedAppointments.some(
            (item) =>
                item.id === indexStore.id &&
                item.day === indexStore.day &&
                item.formattedMonth === indexStore.formattedMonth &&
                item.year === indexStore.year &&
                item.treatment === indexStore.treatment &&
                item.providerId === indexStore.providerId
        );
        if (checkExistingId) {
            setIndexStore([]);
            return;
        }
    }, [date]);
    const handleChange = (e) => {
        if (e.target.name === "selectedpet") {
            setSelectedPet(e.target.value);
        }
        if (e.target.name === "doctor") {
            setProvider(e.target.value);
        } else {
            const data = e.target.value;

            setProcedure(data.split("|")[0]);
        }

        const { name, value } = e.target;
        setAppointmentData({
            ...appointmentData,
            [name]: value,
        });
    };

    // ApptDatesTimes: 2023-05-27?9:30,3:00,4:30|2023-05-28?9:30,3:00,4:30|2023-05-29?9:30,3:00,4:30

    const groupedAppointments = {};
    selectedAppointments.forEach((appointment) => {
        const Month = shortMonthNames.findIndex(
            (month) => month === appointment.formattedMonth
        );
        const monthFormat = Month + 1;
        const day = appointment.day;
        const year = appointment.year;

        // Format month and day with leading zeros
        const formattedMonth =
            monthFormat < 10 ? `${0}${monthFormat}` : `${monthFormat}`;
        const formattedDay = day < 10 ? `${0}${day}` : `${day}`;

        // Create the formatted date
        const date = `${year}-${formattedMonth}-${formattedDay}`;

        if (!groupedAppointments[date]) {
            groupedAppointments[date] = [];
        }
        groupedAppointments[date].push(appointment.time);
    });

    const formattedAppointments = Object.keys(groupedAppointments).map(
        (date) => {
            const times = groupedAppointments[date];
            const formattedTimes = times.join(",");
            return `${date}?${formattedTimes}`;
        }
    );

    // Step 3: Combine formatted dates into the final string
    const data = formattedAppointments.join("|");
    const appDateTime = data.replace(/[APM ]/g, "");
    // ApptDates: 2023-05-11,2023-06-13
    const newArray = [];

    selectedAppointments.forEach((appointment) => {
        const { day, formattedMonth, year } = appointment;
        const Month =
            shortMonthNames.findIndex((month) => month === formattedMonth) + 1;
        const monthFormat = Month < 10 ? `0${Month}` : `${Month}`;
        const dayFormat = day < 10 ? `0${day}` : `${day}`;
        newArray.push(`${year}-${monthFormat}-${dayFormat}`);
    });

    const uniqueValuesArray = Array.from(new Set(newArray));
    const dateArray = uniqueValuesArray.join(", ");
    const dateArray1 = dateArray.replace(/ /g, "");
    const appDates = dateArray1.replace(/,/g, "|");

    // InitialParam: patid|practiceid|custid|providerid|fname|lname|emailorcell|guarid|provider#
    useEffect(() => {
        if (!referenceId) {
            return;
        }
        const fetchPatienData = async () => {
            let res = await ApiCalls.authenticationGET(
                `/v2/EmailResponse/PatientData?ReferenceId=${referenceId}`
            );

            setPetData(res);

            return;
        };
        fetchPatienData();
    }, []);

    const makeAppointement = async () => {
        if (!petData) {
            return;
        }
        const patientData = petData;
        let providerName;
        providerName = doctors.find(
            (d) => d.providerId === appointmentData.doctor
        ).providerName;
        let InitialParams;
        if (specialty === "Veterinary") {
            InitialParams = `${selectedPet}|${patientData[0].practiceId}|${patientData[0].custId}|${provider}|${patientData[0].firstName}|${patientData[0].lastName}|${patientData[0].cell}|${patientData[0].guarId}|${providerName}#`;
        } else {
            InitialParams = `${patientData[0].patientId}|${patientData[0].practiceId}|${patientData[0].custId}|${provider}|${patientData[0].firstName}|${patientData[0].lastName}|${patientData[0].cell}|${patientData[0].guarId}|${providerName}#`;
        }

        if (
            !appointmentData.doctor ||
            !appointmentData.treatment ||
            selectedAppointments.length <= 0 ||
            !provider ||
            !procedure
        ) {
            setError(true);
            return;
        }
        try {
            const res = await ApiCalls.authenticationPOST(
                "/v2/EmailResponse/ScheduleAppointment",
                {
                    referenceId: referenceId,
                    initialParam: InitialParams,
                    procedureType: procedure,
                    apptDates: appDates,
                    apptDatesTimes: appDateTime,
                    description: comments,
                }
            );

            if (res.isSuccess === true) {
                trackAcvitity(
                    itemType.EMAILRESPONSE_MAKE_APPOINTEMENT,
                    itemName.EMAILRESPONSE_MAKE_APPOINTEMENT,
                    "Click on Link >> Select treatement,doctor,date, select slot/s >> Click 'Make appointment' button >> Success"
                );
                Swal.fire({
                    text: `${
                        res.message + " "
                    }You will receive a message confirming your appointment date and time.`,
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    text: `${res.message}`,
                    icon: "warning",
                });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setError(false);
        }
    };

    return (
        <div className="Sechedule-page  ">
            {showSpinner ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                        background: `url('${sikka_logo}') center center no-repeat`,
                        backgroundSize: "400px",
                    }}
                >
                    <div style={{ marginTop: "200px" }}>
                        <img
                            src={Big_spinner}
                            alt="Loading..."
                            height={80}
                            width={80}
                        ></img>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="container mt-3">
                        <div className="row mt-3">
                            <div className="col">
                                <div className=" head-title ">
                                    <h1 className="head-title ">
                                        Book Appointment
                                    </h1>
                                    <h1 className="head-title-1 mt-3">
                                        What type of appointment you would like
                                        to schedule?
                                    </h1>
                                </div>
                            </div>

                            <div className="col banner">
                                <img
                                    src="https://www.sikka.ai/V3/img/spp/spp-description.png"
                                    style={{ width: "100%" }}
                                ></img>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-5  select-calender ">
                        <div className="row">
                            <div className="col mt-3">
                                <div className="row ">
                                    <span>
                                        {" "}
                                        Select Treatment
                                        {error ? (
                                            <span className="require"> *</span>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </div>
                                <div className="row mt-3 ">
                                    <select
                                        name="treatment"
                                        value={appointmentData.treatment}
                                        onChange={handleChange}
                                    >
                                        {treatments.map((i) => (
                                            <option key={i} value={i}>
                                                {i.split("|")[0]}
                                            </option>
                                        ))}
                                    </select>
                                    {error &&
                                    appointmentData.treatment ==
                                        "--Select any treatment--" ? (
                                        <span className="require">
                                            Please select treatment!
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="row mt-3 ">
                                    <span>
                                        Select Doctor{" "}
                                        {error ? (
                                            <span className="require"> *</span>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </div>
                                <div className="row mt-3">
                                    <select
                                        name="doctor"
                                        value={appointmentData.doctor}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Doctor</option>
                                        {doctors.map((i) => (
                                            <option
                                                key={
                                                    i.providerId +
                                                    i.providerName
                                                }
                                                value={i.providerId}
                                            >
                                                {i.providerName}
                                            </option>
                                        ))}
                                    </select>
                                    {error && appointmentData.doctor == 0 ? (
                                        <span className="require">
                                            Please select Doctor!
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {specialty === "Veterinary" ? (
                                    <>
                                        <div className="row mt-3 ">
                                            <span>
                                                Select Pet{" "}
                                                {error ? (
                                                    <span className="require">
                                                        {" "}
                                                        *
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                        </div>
                                        <div className="row mt-3">
                                            <select
                                                name="selectedpet"
                                                onChange={handleChange}
                                            >
                                                <option value="">
                                                    Select Pet
                                                </option>
                                                {petData.map((i, index) => (
                                                    <option
                                                        key={index}
                                                        value={i.patientId}
                                                    >
                                                        {i.petName}
                                                    </option>
                                                ))}
                                            </select>
                                            {error &&
                                            selectedPet.length == 0 ? (
                                                <span className="require">
                                                    Please Select Petname!
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}

                                <div className="col mt-4">
                                    <div className="mt-5">
                                        <span>
                                            Select Your Prefferrable Time & Date{" "}
                                            {error ? (
                                                <span className="require">
                                                    {" "}
                                                    *
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </div>
                                    <div className="calender mt-3">
                                        <Calendar
                                            minDate={tomorrow}
                                            onChange={handleOnChange}
                                            value={date}
                                            className="react-calender"
                                            tileDisabled={() =>
                                                isDateDisabled(false)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col mt-3 slot-container">
                                <span>
                                    Slots Available
                                    {"  " +
                                        "[" +
                                        formattedMonth +
                                        " " +
                                        day +
                                        ", " +
                                        year +
                                        "]"}
                                    {error ? (
                                        <span className="require"> *</span>
                                    ) : (
                                        ""
                                    )}
                                </span>
                                {/* <div className="Date-Time"> */}
                                <div className="col-8  slot-scroll mt-3">
                                    <Grid container spacing={2}>
                                        <Grid item s={4}>
                                            <Grid
                                                container
                                                mt-5
                                                justifyContent="start"
                                                spacing={2}
                                            >
                                                {slots.map((item) => (
                                                    <Grid key={item.id} item>
                                                        <button
                                                            onClick={() => {
                                                                disableSlots
                                                                    ? false
                                                                    : setSlotButtonColor(
                                                                          item
                                                                      );
                                                            }}
                                                            className={
                                                                selectedAppointments.some(
                                                                    (
                                                                        appointment
                                                                    ) =>
                                                                        appointment.time ===
                                                                            item.time &&
                                                                        appointment.day ===
                                                                            day &&
                                                                        appointment.formattedMonth ===
                                                                            formattedMonth &&
                                                                        appointment.year ===
                                                                            year &&
                                                                        appointment.treatment ===
                                                                            procedure &&
                                                                        appointment.providerId ===
                                                                            provider
                                                                )
                                                                    ? "selectedslots"
                                                                    : "nonselectedslots "
                                                            }
                                                        >
                                                            {item.time}
                                                        </button>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="mt-4">
                                    <span>Selected Appointments</span>
                                </div>

                                <div className="mt-3">
                                    {selectedAppointments.length > 0 ? (
                                        selectedAppointments.map(
                                            (appointment, index) => (
                                                <div
                                                    key={index}
                                                    className="mt-3"
                                                >
                                                    <InsertInvitationIcon
                                                        id="s-icon"
                                                        fontSize="small"
                                                    />
                                                    &nbsp;
                                                    {appointment.formattedMonth}
                                                    &nbsp;&nbsp;
                                                    {appointment.day}&nbsp;,
                                                    &nbsp;
                                                    {appointment.year}
                                                    {"    "}&nbsp;&nbsp;
                                                    <AccessTimeIcon
                                                        id="s-icon"
                                                        fontSize="small"
                                                    />
                                                    &nbsp;&nbsp;
                                                    {appointment.time}
                                                    &nbsp;&nbsp;
                                                    <span
                                                        style={{
                                                            color: "blueviolet",
                                                            textDecorationLine:
                                                                "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            removeSelectedAppointments(
                                                                appointment
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </span>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <div className="mt-5 noslot-selected">
                                            <img
                                                src={ErrorPng}
                                                style={{
                                                    height: "150px",
                                                    width: "150px",
                                                }}
                                            ></img>
                                            <br></br>
                                            No slot selected yet.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-3">
                        <div className="comment-text">
                            <span>Your Comments</span>
                        </div>
                        <div className="row">
                            <textarea
                                placeholder="write your comments here..."
                                className="txtarea"
                                name="comments"
                                onChange={handleComment}
                            ></textarea>
                        </div>

                        <div className="row mt-4">
                            <div className="button-flex">
                                <button
                                    className="appointment-btn"
                                    onClick={() => makeAppointement()}
                                >
                                    Make Appointment
                                </button>{" "}
                                <div style={{ marginLeft: "50%" }}>
                                    <Tooltip
                                        title={tooltipContent}
                                        PopperProps={{
                                            sx: {
                                                "& .MuiTooltip-tooltip": {
                                                    border: "solid skyblue 1px",
                                                    backgroundColor:
                                                        "blueviolet",
                                                },
                                            },
                                        }}
                                        placement="top-start"
                                        arrow
                                    >
                                        {/* <button
                                        className="button-php mt-3"
                                        onClick={() =>
                                            (window.location.href =
                                                "https://patientportal.sikkasoft.com/login")
                                        }
                                    >
                                        {" "}
                                        <ArrowForwardIosIcon className="php-icon" />
                                    </button> */}
                                        <ArrowCircleRightIcon
                                            onClick={() =>
                                                (window.location.href =
                                                    "https://patientportal.sikkasoft.com/login")
                                            }
                                            style={{
                                                fontSize: "50px",
                                                color: "blueviolet",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Trademark year={year} />
                </div>
            )}
        </div>
    );
};

export default Schedule;
