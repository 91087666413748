import React, { useState, useEffect } from "react";
import Modal from "../../components/modals/Modal";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ApiCalls } from "../../api/allApiCalls";
import ButtonLoader from "../../components/loaders/ButtonLoader";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import { VETERINARY } from "../../utils";

function ComposeMailForm({
	closeModal,
	openModal,
	updateSentMail,
	contactSupport,
}) {
	const {
		masterId,
		userId,
		custId,
		patientId,
		specialty,
		practiceId,
		firstName,
		lastName,
	} = useSelector((state) => state.userInfo);
	const { selectedPet } = useSelector((state) => state.petInfo);
	const [inputValues, setInputValues] = useState({
		subject: "",
		message: "",
	});
	const [loading, setLoading] = useState(false);
	const [useContactSupport, setUseContactSupport] = useState(false);
	const [title, setTitle] = useState("Send a message");

	useEffect(() => {
		if (contactSupport) {
			setUseContactSupport(true);
			setTitle("Contact Support");
		}
	}, [contactSupport]);

	const handleContactSupport = async (e) => {
		e.preventDefault();
		try {
			if (!inputValues.subject.trim() || !inputValues.message.trim()) {
				Swal.fire({
					text: "Subject and Message are required. Please check your input.",
				});
				return;
			}
			setLoading(true);
			const res = await ApiCalls.authPostAPI("/v2/Patient/SendEmail", {
				masterId,
				userId,
				practiceId,
				patientId:
					specialty === VETERINARY
						? selectedPet.patientId
						: patientId,
				fromName: firstName + " " + lastName,
				toEmail: "support@sikka.ai",
				subject: inputValues.subject,
				message: inputValues.message,
			});

			trackAcvitity(
				itemType.MESSAGES,
				itemName.SEND_MAIL,
				"Any page >> Click Help Icon >> compose mail with subject >> Click 'Send Message' button to send the mail to sikka support"
			);

			if (res?.isSuccess && res?.message.toLowerCase() === "successful") {
				Swal.fire({
					icon: "success",
					text: "Your message has been successfully sent to support.",
				});
			} else {
				Swal.fire({
					text: "Something wrong happened. Please try again later.",
				});
			}
			closeModal();
		} catch (err) {
			console.log(err);
			closeModal();
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (!inputValues.subject.trim() || !inputValues.message.trim()) {
				Swal.fire({
					text: "Subject and Message are required. Please check your input.",
				});
				return;
			}
			setLoading(true);
			const res = await ApiCalls.authPostAPI(
				"/v2/Patient/CommunicationData",
				{
					masterId,
					userId,
					custId,
					patientId:
						specialty === VETERINARY
							? selectedPet.patientId
							: patientId,
					practiceId,
					subject: inputValues.subject,
					message: inputValues.message,
				}
			);

			trackAcvitity(
				itemType.MESSAGES,
				itemName.SEND_MAIL,

				"Profile&Setting page>>Pactice Information  >> Fill in practice name,number and address >> Click 'Save Changes' button to send the mail to practice"
			);

			if (res?.isSuccess && res?.message.toLowerCase() === "successful") {
				Swal.fire({
					icon: "success",
					text: "Your message has been successfully sent to your practice.",
				});
				updateSentMail();
			} else {
				Swal.fire({
					text: "Something wrong happened. Please try again later.",
				});
			}
			closeModal();
		} catch (err) {
			console.log(err);
			closeModal();
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		setInputValues((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const onSubmit = (event) => {
		if (useContactSupport) {
			handleContactSupport(event);
		} else {
			handleSubmit(event);
		}
	};

	return (
		<Modal openModal={openModal} closeModal={closeModal}>
			<div className="compose-mail-form">
				<h2>{title}</h2>
				<form onSubmit={onSubmit}>
					<div className="d-flex flex-column gap-2 mb-3">
						<div>
							<label className="fz-16 fw-bold">Subject</label>
							<input
								required
								name="subject"
								value={inputValues.subject}
								onChange={handleChange}
							/>
						</div>
						<div>
							<label className="fz-16 fw-bold">
								Your Message
							</label>
							<textarea
								required
								name="message"
								value={inputValues.message}
								onChange={handleChange}
								placeholder="Type your message here"
								style={{ minHeight: 200 }}
							/>
						</div>
					</div>
					<div className="d-flex gap-3">
						<button
							className="button button-primary"
							type="submit"
							style={{ minWidth: 172 }}
						>
							{loading ? <ButtonLoader /> : "Send Message"}
						</button>
						<button
							className="button button-secondary"
							onClick={closeModal}
							disabled={loading ? true : false}
						>
							Discard
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
}

export default ComposeMailForm;
