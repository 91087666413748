import React from "react";
import { Grid, GridColumn as Col } from "@progress/kendo-react-grid";

import Trash_icon from "../../assets/images/Trash_icon.png";
import CheckboxCell from "./tableComponents/CheckboxCell";
import CheckboxHeader from "./tableComponents/CheckboxHeader";
import SelectedCell from "./tableComponents/SelectedCell";
import { INBOX } from "./messagesHelpers";
import CardLoader from "../../components/loaders/CardLoader";
import MessagesDateCell from "./tableComponents/MessagesDateCell";

function MessagesTable({
	selectedCount,
	handleRemove,
	data,
	onDataStateChange,
	state,
	handleMailCheck,
	checkAll,
	handleCheckAll,
	mailType,
	loading,
	selectAllConversations,
	originalData,
	handleClickRow,
}) {
	const trashIcon = (
		<img
			alt="Delete"
			src={Trash_icon}
			style={{ width: 24, marginRight: 8 }}
			role="button"
			onClick={() => handleRemove(mailType)}
		/>
	);
	return (
		<>
			{loading && <CardLoader />}
			<div
				className="table-header-subSection d-flex align-items-center font-black d-flex align-items-center gap-3"
				style={{
					paddingLeft: 9,
				}}
			>
				{!originalData.find((i) => !i.selected) &&
				originalData.length > 0 ? (
					<>
						{trashIcon}
						<span>All conversations in Inbox are selected.</span>
						<span
							className="select-all-button px-3 py-2 border-radius-6 ms-3"
							onClick={() =>
								selectAllConversations(mailType, "deselect")
							}
							role="button"
						>
							Clear selection
						</span>
					</>
				) : (
					<>
						{selectedCount === 0 ? (
							<span style={{ marginLeft: 5 }}>
								No conversation selected yet.
							</span>
						) : (
							<>
								{trashIcon}
								{selectedCount} conversation
								{selectedCount > 1 && "s"} on this page{" "}
								{selectedCount > 1 ? "are" : "is"} selected.{" "}
								<span
									role="button"
									className="select-all-button px-3 py-2 border-radius-6 ms-3"
									onClick={() =>
										selectAllConversations(
											mailType,
											"select"
										)
									}
								>
									Select all {originalData.length}{" "}
									conversations in{" "}
									{mailType === INBOX ? "Inbox" : "Sent Mail"}
								</span>
							</>
						)}
					</>
				)}
			</div>
			<div className="table-header-right-line"></div>
			<Grid
				data={data}
				onDataStateChange={(e) => onDataStateChange(e, mailType)}
				{...state}
				pageable={{
					pageSizes: [20, 50, 100],
				}}
				resizable={true}
				sortable={true}
			>
				<Col
					title="All"
					field="selected"
					width={100}
					cell={(props) => (
						<CheckboxCell
							{...props}
							handleCheck={handleMailCheck}
							mailType={mailType}
							handleClickRow={handleClickRow}
						/>
					)}
					headerCell={(props) => (
						<CheckboxHeader
							{...props}
							checkAll={checkAll}
							handleCheckAll={() => handleCheckAll(mailType)}
						/>
					)}
					sortable={false}
				/>
				<Col
					title={`${mailType === INBOX ? "From" : "To"}`}
					field={`${mailType === INBOX ? "fromName" : "toName"}`}
					cell={(props) => (
						<SelectedCell
							{...props}
							handleClickRow={handleClickRow}
						/>
					)}
				/>
				<Col
					title="Subject"
					field="subject"
					cell={(props) => (
						<SelectedCell
							{...props}
							handleClickRow={handleClickRow}
						/>
					)}
				/>
				<Col
					title="Date"
					field="date"
					cell={(props) => (
						<MessagesDateCell
							{...props}
							handleClickRow={handleClickRow}
						/>
					)}
				/>
			</Grid>
		</>
	);
}

export default MessagesTable;
