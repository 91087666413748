import React from "react";

function Comments({ comments, setAppointment }) {
	return (
		<div>
			<h3 style={{ fontSize: 16, color: "#121b21" }}>Your Comments</h3>
			<div>
				<textarea
					style={{ minHeight: 70 }}
					placeholder="Write your comments here..."
					name="comments"
					value={comments}
					onChange={(e) =>
						setAppointment((prev) => ({
							...prev,
							comments: e.target.value,
						}))
					}
				/>
			</div>
		</div>
	);
}

export default Comments;
