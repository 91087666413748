import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApiCalls } from "../../api/allApiCalls";
import VaccinesAndTreatmentTemplate from "./VaccinesAndTreatmentTemplate";
//
function VaccinesAndTreatments() {
    const { masterId, userId, practiceId, custId } = useSelector(
        (state) => state.userInfo
    );
    const { selectedPet } = useSelector((state) => state.petInfo);
    const [vaccinations, setVaccinations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [treatments, setTreatments] = useState([]);

    useEffect(() => {
        const fetchVaccineData = async () => {
            try {
                setLoading(true);
                const res = await ApiCalls.authGetAPI(
                    "/v2/Patient/PetComplianceData",
                    {
                        masterId,
                        userId,
                        practiceId,
                        custId,
                        patientId: selectedPet.patientId,
                    }
                );

                const vac = [];
                const tm = [];
                res.vaccinations.forEach((v) => {
                    const item = {
                        ...v,
                        issuedDate: v.issuedDate.split(" ")[0],
                    };

                    vac.push(item);
                });
                res.addlTreatment.forEach((t) => {
                    const item = {
                        ...t,
                        issuedDate: t.issuedDate.split(" ")[0],
                    };

                    tm.push(item);
                });

                setVaccinations(
                    vac.sort((a, b) => {
                        const dateA = new Date(a.issuedDate);
                        const dateB = new Date(b.issuedDate);
                        return dateB - dateA; // Sorting in descending order (latest to oldest)
                    })
                );
                setTreatments(
                    tm.sort((a, b) => {
                        const dateA = new Date(a.issuedDate);
                        const dateB = new Date(b.issuedDate);
                        return dateB - dateA; // Sorting in descending order (latest to oldest)
                    })
                );
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        if (!selectedPet) return;
        fetchVaccineData();
    }, [selectedPet]);

    return (
        <div
            className="d-flex flex-column justify-content-between position-relative bg-white"
            id="vaccines-and-treatment-id"
        >
            <VaccinesAndTreatmentTemplate
                title="Vaccines"
                data={vaccinations}
                loading={loading}
            />
            <VaccinesAndTreatmentTemplate
                title="Additional Treatments"
                data={treatments}
                loading={loading}
            />
        </div>
    );
}

export default VaccinesAndTreatments;
