import React from "react";
import EmptyInbox from "../../assets/images/EmptyInbox.png";
import CardLoader from "../../components/loaders/CardLoader";
import useFetchProgressiveProcedures from "../../hooks/useFetchProgressiveProceduces";
import ProgressiveProcedurePredictionTooltip from "../../components/tooltips/ProgressiveProcedurePredictionTooltip";

function PPP() {
	const [loading, data] = useFetchProgressiveProcedures();

	return (
		<div>
			<h3>
				Progressive Procedure Prediction{" "}
				<ProgressiveProcedurePredictionTooltip />
			</h3>
			<div
				className="ppp position-relative card-border border-radius-6"
				style={{ padding: "16px 20px" }}
			>
				{loading && <CardLoader />}
				{data && data.procedureCode && (
					<div>
						{data.procedureCode} {data.procedureName} -{" "}
						{data?.predictedDate}
					</div>
				)}

				{(!data || (!data?.procedureCode && !data?.procedureName)) && (
					<div className="height-100p d-flex flex-column justify-content-center align-items-center">
						<img alt="" src={EmptyInbox} style={{ height: 120 }} />
						<div
							className="fz-14 text-center"
							style={{ color: "#121b21" }}
						>
							No procedures predicted
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default PPP;
