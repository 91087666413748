import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { CustomTooltip } from "./customeTooltip";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";

function AccountsReceivableTooltip() {
	const AccountsReceivableTooltipContent = (
		<div className="d-flex align-items-start" style={{ fontSize: "13px" }}>
			<div className="mt-2 ms-4">
				<h4 className="fz-18 text-dark">Accounts Receivable:</h4>
				<div style={{ color: "#414d55" }}>
					This section highlights how much is owed to your dentist. It
					also shows many days past due those payments are, and who is
					responsible for the balance whether that’s your guarantor or
					your insurance.
				</div>
			</div>
		</div>
	);

	return (
		<CustomTooltip title={AccountsReceivableTooltipContent}>
			<InfoIcon
				className="font-purple ms-2"
				fontSize="small"
				onMouseEnter={() =>
					trackAcvitity(
						itemType.DESCRIPTION,
						itemName.HOVER_ACCOUNTS_RECEIVABLE_TOOLTIP,
						"Payments & Benenfits page >> Hover on Accounts Receivable tooltip"
					)
				}
			/>
		</CustomTooltip>
	);
}

export default AccountsReceivableTooltip;
