import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiCalls } from "../../../api/allApiCalls";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { addEmailId } from "../../../states/userInfoSlice";
import useSetTitle from "../../../hooks/useSetTitle";

function ForgotPassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [inputMessage, setInputMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	//const timeoutRef = useRef(null);

	useSetTitle("Forgot Password");

	useEffect(() => {
		handleInputChange(inputValue);
	}, [inputValue]);

	const handleInputChange = (value) => {
		setInputValue(value);
		setInputMessage("");

		if (!value) {
			setIsValid(false);
			setInputMessage("Email is required.");
		} else {
			setIsValid(/^\S+@\S+\.\S+$/.test(value));
			if (!isValid)
				setInputMessage("Please enter a valid email address.");
		}
	};

	const handleChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleInput = (event) => {
		handleInputChange(event.target.value);
		setInputValue(event.target.value);
	};

	const handleSubmit = async () => {
		setError("");
		try {
			setLoading(true);
			// send request to API. if wrong, subtract attempt
			const res = await ApiCalls.authenticationPOST(
				"/v2/Password/SendVerificationCode",
				{
					email: inputValue,
				}
			);

			if (res?.isSuccess) {
				dispatch(addEmailId(inputValue));
				navigate("/forgotPassword/verifyOTP");
			} else {
				if (!isValid) {
					setError("Please verify your email address.");
				} else {
					setError(
						"Email address you entered does not match in our database. Re-enter your email address."
					);
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="login-form-page">
			<div className="form-content-wrapper" style={{ maxWidth: 700 }}>
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 36, margin: "1.5rem" }}
				/>
				{error && (
					<div
						className="error-message mb-4 py-2 px-3"
						style={{ maxWidth: 470, textAlign: "center" }}
					>
						{error}
					</div>
				)}

				<div
					className="font-black fw-bold text-center mb-4"
					style={{ fontSize: "20px" }}
				>
					Forgot Password
				</div>

				<form className="login-form" onSubmit={handleSubmit}>
					<div className="login-form__input">
						<label>Enter Your Email Address *</label>
						<input
							name="emailId"
							required
							placeholder="Enter here"
							value={inputValue}
							type="email"
							onInput={handleInput}
							onChange={handleChange}
						/>
						<p style={{ color: "#dc3545" }}>{inputMessage}</p>
					</div>

					<button
						className="button button-primary mt-3 width-100p"
						type="submit"
						onClick={handleSubmit}
						disabled={loading ? true : false}
					>
						{loading ? <ButtonLoader /> : "Send Code"}
					</button>
				</form>
			</div>
			<div className="company-copyright">
				© {new Date().getFullYear()} Sikka Software Corporation, The
				Practice Optimizer Company®. All Rights Reserved.
			</div>
		</div>
	);
}

export default ForgotPassword;
