import React from "react";

function CheckboxHeader({ children, onClick, checkAll, handleCheckAll }) {
	return (
		<a className="k-link k-link-custom checkboxHeader" onClick={onClick}>
			<span className="k-cell-inner font-grey01">
				<input
					type="checkbox"
					className="me-2"
					checked={checkAll}
					onChange={handleCheckAll}
				/>
				All
			</span>
			{children}
		</a>
	);
}

export default CheckboxHeader;
