import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";

import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { ApiCalls } from "../../../api/allApiCalls";
import {
	resetAuth,
	setShowNoMatchMessage,
	updateAuthData,
} from "../../../states/authSlice";
import Swal from "sweetalert2";

function VerifyCell() {
	const { authToken, maskedCell } = useSelector(
		(state) => state.authentication
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [attemptLeft, setAttemptLeft] = useState(3);
	const [inputValue, setInputValue] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!authToken || !maskedCell) {
			navigate("/authentication");
		}
	}, []);

	const handleSubmit = async () => {
		const formattedCell = inputValue
			.replaceAll("-", "")
			.replaceAll(" ", "");

		if (!formattedCell) {
			setError("Phone number is required");
			return;
		}

		try {
			setLoading(true);
			// send request to API. if wrong, subtract attempt
			const res = await ApiCalls.authenticationPOST(
				"/v2/SignUp/ValidateEmailOrCell",
				{
					authToken,
					email: "",
					cell: formattedCell,
					mediaType: "Text",
				}
			);

			// If Phone Number matched, send request CODE, save info and navigate to Verification Screen
			if (
				Array.isArray(res) &&
				res[0]?.authToken &&
				res[0]?.masterId &&
				res[0]?.custId &&
				res[0]?.patientId
			) {
				const result = await ApiCalls.authenticationPOST(
					"/v2/SignUp/SendVerificationCode",
					{
						authToken: res[0].authToken,
						masterId: res[0].masterId,
						practiceId: res[0].practiceId,
						patientId: res[0].patientId,
						email: res[0].email,
						cell: res[0].cell,
						mediaType: "text",
					}
				);

				if (result?.isSuccess && result?.authToken) {
					dispatch(
						updateAuthData({
							masterId: res[0].masterId,
							custId: res[0].custId,
							practiceId: res[0].practiceId,
							patientId: res[0].patientId,
							email: res[0].email,
							cell: res[0].cell,
							mediaType: "Text",
						})
					);
					navigate("/authentication/verification");
				} else {
					Swal.fire({
						icon: "error",
						text: "Verification Failed",
						footer: `<a href="${document.location.origin}/authentication">Restart the process</a>`,
					});
				}
			} else {
				// If Cell does not match, subtract attempt
				const remaining = attemptLeft - 1;
				if (remaining === 0) {
					// If there is no attempt left, clear all data in Reducer and Navigate to Follow-up page
					dispatch(resetAuth());
					dispatch(setShowNoMatchMessage());
					navigate("/authentication/follow-up");
				} else {
					setError(
						`Invalid Phone Number. ${remaining} attempts left.`
					);
					setAttemptLeft(remaining);
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="d-flex flex-column">
			<div className="border-radius-6 border-warning bg-clr-warning p-2 px-3 mb-4">
				Please verify the complete phone number
			</div>
			<div className="d-flex flex-column gap-4">
				<div className="d-flex flex-column align-items-start">
					<label className="mb-1">Enter Your Phone Number </label>
					<PatternFormat
						format="###-###-#########"
						onChange={(e) => setInputValue(e.target.value)}
						value={inputValue}
						// mask="_"
						placeholder={maskedCell}
					/>
				</div>
				<button
					className="button button-primary width-100p"
					onClick={handleSubmit}
					disabled={loading ? true : false}
				>
					{loading ? <ButtonLoader /> : "Submit"}
				</button>
			</div>
			{error && <div className="font-destructive">{error}</div>}
		</div>
	);
}

export default VerifyCell;
