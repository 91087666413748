/*eslint-disable*/
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Collapse } from "antd";
const { Panel } = Collapse;

import { faqData } from "../../dummy";

import "./FAQ.scss";
import useSetTitle from "../../hooks/useSetTitle";

function FAQ() {
	useSetTitle("FAQ");

	return (
		<div className="faq-page">
			<div className="faq-page__left-col">
				<div className="faq-page__left-col--top">
					<h3>FAQ&apos;s</h3>
					<div>
						Frequently asked questions. Have questions about the
						product, search here we are happy to help.
					</div>
				</div>
				<div className="faq-page__left-col--bottom">
					<h3>Still have questions?</h3>
					<div>
						Everything you need to know about the Patient Portal and
						how it works. Can&apos;t find an answer?{" "}
						<a
							className="font-purple"
							href="mailto:support@sikka.ai"
						>
							Please contact to our team for support
						</a>
					</div>
				</div>
			</div>

			<div className="faq-page__right-col">
				<Collapse
					defaultActiveKey={["1"]}
					ghost
					size="large"
					expandIconPosition="right"
					expandIcon={({ isActive }) =>
						isActive ? (
							<ExpandLessIcon
								style={{
									color: "#681c9a",
									cursor: "pointer",
									fontSize: 32,
								}}
							/>
						) : (
							<ExpandMoreIcon
								style={{
									color: "#681c9a",
									cursor: "pointer",
									fontSize: 32,
								}}
							/>
						)
					}
				>
					{faqData.map((item, idx) => (
						<Panel header={item.question} key={idx + 1}>
							<p>{item.answer}</p>
						</Panel>
					))}
				</Collapse>
				{/* {faqData.map((item, idx) => (
					<div key={idx} className="faq-item py-4">
						<div className="faq-question d-flex justify-content-between align-items-center">
							<div>{item.question}</div>
							{expandIndex === idx ? (
								<ExpandLessIcon
									style={{
										color: "#681c9a",
										cursor: "pointer",
									}}
									onClick={() => setExpandIndex(null)}
								/>
							) : (
								<ExpandMoreIcon
									style={{
										color: "#681c9a",
										cursor: "pointer",
									}}
									onClick={() => setExpandIndex(idx)}
								/>
							)}
						</div>

						<div
							className={`faq-answer ${
								expandIndex === idx ? "expanded" : ""
							}`}
						>
							<div>{item.answer}</div>
						</div>
					</div>
				))} */}
			</div>
		</div>
	);
}

export default FAQ;
