import HomeIcon from "@mui/icons-material/Home";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PaidIcon from "@mui/icons-material/Paid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SchoolIcon from "@mui/icons-material/School";
import SmsIcon from "@mui/icons-material/Sms";
// import oralB_logo from "../../assets/images/oralB_logo.png";
// import HelpIcon from "@mui/icons-material/Help";

export const DASHBOARD = "Dashboard";
export const TREATMENT_PLANS = "Treatment Plans";
export const PAYMENT_AND_BENEFITS = "Payments & Benefits";
export const MESSAGES = "Messages";
export const PROFILE_AND_SETTINGS = "Profile & Settings";
export const EDUCATION = "Education";

export const menu = [
	{ title: DASHBOARD, icon: <HomeIcon />, path: "/" },
	// {
	// 	title: "My Care",
	// 	icon: (
	// 		<img
	// 			alt=""
	// 			src={oralB_logo}
	// 			style={{
	// 				width: 40,
	// 				objectFit: "contain",
	// 				marginInline: "0.75rem",
	// 				marginTop: 2,
	// 			}}
	// 		/>
	// 	),
	// 	path: "/my-care",
	// },
	{
		title: TREATMENT_PLANS,
		icon: <NoteAddIcon />,
		path: "/treatment-plans",
	},
	{
		title: PAYMENT_AND_BENEFITS,
		icon: <PaidIcon />,
		path: "/payments-and-benefits",
	},
	{
		title: MESSAGES,
		icon: <SmsIcon />,
		path: "/messages",
	},
	{
		title: PROFILE_AND_SETTINGS,
		icon: <AccountCircleIcon />,
		path: "/profile-settings",
	},
	{ title: EDUCATION, icon: <SchoolIcon />, path: "/education" },
	// { title: "Settings", icon: <SettingsIcon />, path: "/settings" },
	// { title: "FAQ's", icon: <HelpIcon />, path: "/FAQ" },
];
