import { API_KEY, API_ROOT } from "./apiRoot";
import { authHeader } from "../utils/authHeader";
import { browserStore } from "../utils";
import Swal from "sweetalert2";
import axios from "axios";
import omitBy from "lodash/omitBy";
import CryptoJS from "crypto-js";

export const ApiCalls = {
    login,
    // unauthGet,
    // unauthPost,
    authPostAPI,
    authGetAPI,
    authPutAPI,
    authDeleteAPI,
    axiosGet,
    authenticationPOST,
    authenticationGET,
};

///////////////////////////////////////////////////////
// RESULT DISPLAY POP UP
///////////////////////////////////////////////////////
const errorCodeDesc = {
    400: {
        title: "Bad request",
        text: "Please check your input",
        icon: "warning",
    },
    500: {
        title: "Internal server error",
        text: "Something wrong with the server",
        icon: "warning",
    },
    403: {
        title: "Forbidden",
        text: "You don't have access right to this content",
        icon: "warning",
    },
};

function login({ emailId, password, publicIP }) {
    const config = {
        method: "post",
        url: `${API_ROOT}/v2/Auth/PatientAuth`,
        headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
        },
        data: {
            emailId,
            password,
            publicIP,
            machineName: "",
            machineGUID: "",
            appName: "PatientPortal",
            apiKey: API_KEY,
        },
    };

    // console.log(config);

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    const error = response.statusText;
                    reject(error);
                }
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

// function unauthGet(endpoint, data) {
// 	let url = API_ROOT;
// 	url += endpoint;

// 	let query = new URLSearchParams(omitBy(data, (v) => v === "")).toString(); // omitBy(data, v => v === "") helps remove empty values in the object data
// 	query = query.replace(new RegExp("\\+", "g"), " ");
// 	url += "?" + query;

// 	var config = {
// 		method: "get",
// 		url,
// 		headers: {
// 			"cache-control": "no-cache",
// 		},
// 	};

// 	return new Promise((resolve, reject) => {
// 		axios(config)
// 			.then((response) => {
// 				if (response.status === 200 || response.status === 204) {
// 					resolve(response.data);
// 				} else {
// 					const error = response.statusText;
// 					reject(error);
// 				}
// 			})
// 			.catch(function (error) {
// 				if (error.response === undefined) {
// 					resolve("no data");
// 					console.log("500 Internal server error");
// 				}
// 				if (error.response) {
// 					if (error.response.status === 401) {
// 						if (browserStore.get("token")) {
// 							Swal.fire({
// 								title: "401 Unauthorized",
// 								text: "Your session was inactive and timed out. Please re-login.",
// 								icon: "warning",
// 							});
// 						}
// 						window.location = "/logout";
// 					} else {
// 						resolve("no data");
// 						let errorObj = errorCodeDesc[error.response.status];
// 						let title = errorObj ? errorObj.title : "";
// 						let text = errorObj ? errorObj.text : "Error";
// 						let icon = errorObj ? errorObj.icon : "warning";

// 						if (error.response.status !== 500) {
// 							Swal.fire({
// 								title: error.response.status + ` ${title}`,
// 								text,
// 								icon,
// 							});
// 						}
// 					}
// 				} else {
// 					resolve("no data");
// 					console.log("500 Internal server error");
// 					Swal.fire({
// 						title: "500 Internal server error",
// 						icon: "warning",
// 					});
// 				}
// 				reject(error);
// 			});
// 	});
// }

////////////////////////////////////////////////////////////////////////
// V2
////////////////////////////////////////////////////////////////////////

function authGetAPI(endpoint, data, signal) {
    let url = API_ROOT + endpoint;
    let query = new URLSearchParams(omitBy(data, (v) => v === "")).toString(); // omitBy(data, v => v === "") helps remove key-value that has empty value in the object data
    query = query.replace(new RegExp("\\+", "g"), " "); // replace "+" with a space
    url += "?" + query;

    let config;
    if (signal) {
        config = {
            method: "get",
            headers: authHeader(),
            url,
            signal,
        };
    } else {
        config = {
            method: "get",
            headers: authHeader(),
            url,
        };
    }

    // console.log(config);

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(response.data);
                } else {
                    const error = response.statusText;
                    reject(error);
                }
            })
            .catch(function (error) {
                if (error.message !== "canceled") {
                    if (error.response === undefined) {
                        // resolve("no data");
                        console.log("500 Internal server error");
                    }
                    if (error.response) {
                        if (error.response.status === 401) {
                            let checktoken = browserStore.get("apiToken");
                            if (checktoken) {
                                Swal.fire({
                                    title: "401 Unauthorized",
                                    text: "Your session was inactive and timed out. Please re-login.",
                                    icon: "warning",
                                });
                            }
                            window.location = "/logout";
                        } else {
                            // resolve("no data");
                            let errorMessage = "";
                            if (error.response.data["message"]) {
                                if (
                                    error.response.data["message"] ===
                                    "Auth token expired."
                                ) {
                                    errorMessage =
                                        "Auth token expired. Please re-login.";
                                } else {
                                    errorMessage =
                                        error.response.data["message"];
                                }
                            }
                            let errorObj = errorCodeDesc[error.response.status];
                            let title = errorObj
                                ? errorObj.title
                                : "Server Error. Please try again later";
                            let text = errorMessage
                                ? errorMessage
                                : errorObj
                                ? errorObj.text
                                : "";
                            let icon = errorObj ? errorObj.icon : "warning";

                            const isTimeout =
                                text === "Auth token expired. Please re-login.";

                            Swal.fire({
                                title: error.response.status + ` ${title}`,
                                text,
                                icon,
                                allowOutsideClick: isTimeout ? false : true,
                                allowEscapeKey: isTimeout ? false : true,
                            }).then((res) => {
                                if (res.isConfirmed && isTimeout) {
                                    window.location = "/logout";
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            title: "Server error. Please try again later",
                            icon: "warning",
                        });
                    }
                }
                reject(error);
            });
    });
}

function authPostAPI(endpoint, data) {
    let url = API_ROOT + endpoint;

    const config = {
        method: "post",
        headers: authHeader(),
        url,
        data,
    };

    // console.log(config);

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve(response.data);
                } else {
                    const error = response.statusText;
                    reject(error);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        let checktoken = browserStore.get("apiToken");
                        if (checktoken) {
                            Swal.fire({
                                title: "401 Unauthorized",
                                text: "Your session was inactive and timed out. Please re-login.",
                                icon: "warning",
                            });
                        }
                        window.location = "/logout";
                    } else {
                        let errorMessage = "";
                        if (error.response.data["message"]) {
                            if (
                                error.response.data["message"] ===
                                "Auth token expired."
                            ) {
                                errorMessage =
                                    "Auth token expired. Please re-login.";
                            } else {
                                errorMessage = error.response.data["message"];
                            }
                        }
                        let errorObj = errorCodeDesc[error.response.status];
                        let title = errorObj
                            ? errorObj.title
                            : "Server Error. Please try again later";
                        let text = errorMessage
                            ? errorMessage
                            : errorObj
                            ? errorObj.text
                            : "";
                        let icon = errorObj ? errorObj.icon : "warning";

                        const isTimeout =
                            text === "Auth token expired. Please re-login.";

                        Swal.fire({
                            title: error.response.status + ` ${title}`,
                            text,
                            icon,
                            allowOutsideClick: isTimeout ? false : true,
                            allowEscapeKey: isTimeout ? false : true,
                        }).then((res) => {
                            if (res.isConfirmed && isTimeout) {
                                window.location = "/logout";
                            }
                        });
                    }
                } else {
                    Swal.fire({
                        title: "Server error. Please try again later",
                        icon: "warning",
                    });
                }
                reject(error);
            });
    });
}

function authPutAPI(endpoint, data) {
    let url = API_ROOT + endpoint;

    const config = {
        method: "put",
        headers: authHeader(),
        url,
        data,
    };

    // console.log(config);

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    const error = response.statusText;
                    reject(error);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        let checktoken = browserStore.get("apiToken");
                        if (checktoken) {
                            Swal.fire({
                                title: "401 Unauthorized",
                                text: "Your session was inactive and timed out. Please re-login.",
                                icon: "warning",
                            });
                        }
                        window.location = "/logout";
                    } else {
                        let errorMessage = "";
                        if (error.response.data["message"]) {
                            if (
                                error.response.data["message"] ===
                                "Auth token expired."
                            ) {
                                errorMessage =
                                    "Auth token expired. Please re-login.";
                            } else {
                                errorMessage = error.response.data["message"];
                            }
                        }
                        let errorObj = errorCodeDesc[error.response.status];
                        let title = errorObj
                            ? errorObj.title
                            : "Server Error. Please try again later";
                        let text = errorMessage
                            ? errorMessage
                            : errorObj
                            ? errorObj.text
                            : "";
                        let icon = errorObj ? errorObj.icon : "warning";

                        const isTimeout =
                            text === "Auth token expired. Please re-login.";

                        Swal.fire({
                            title: error.response.status + ` ${title}`,
                            text,
                            icon,
                            allowOutsideClick: isTimeout ? false : true,
                            allowEscapeKey: isTimeout ? false : true,
                        }).then((res) => {
                            if (res.isConfirmed && isTimeout) {
                                window.location = "/logout";
                            }
                        });
                    }
                } else {
                    Swal.fire({
                        title: "Server error. Please try again later",
                        icon: "warning",
                    });
                }
                reject(error);
            });
    });
}

function authDeleteAPI(endpoint, data) {
    let url = API_ROOT + endpoint;

    const config = {
        method: "delete",
        headers: authHeader(),
        url,
        data,
    };

    // console.log(config);

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    const error = response.statusText;
                    reject(error);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        let checktoken = browserStore.get("apiToken");
                        if (checktoken) {
                            Swal.fire({
                                title: "401 Unauthorized",
                                text: "Your session was inactive and timed out. Please re-login.",
                                icon: "warning",
                            });
                        }
                        window.location = "/logout";
                    } else {
                        let errorMessage = "";
                        if (error.response.data["message"]) {
                            if (
                                error.response.data["message"] ===
                                "Auth token expired."
                            ) {
                                errorMessage =
                                    "Auth token expired. Please re-login.";
                            } else {
                                errorMessage = error.response.data["message"];
                            }
                        }
                        let errorObj = errorCodeDesc[error.response.status];
                        let title = errorObj
                            ? errorObj.title
                            : "Server Error. Please try again later";
                        let text = errorMessage
                            ? errorMessage
                            : errorObj
                            ? errorObj.text
                            : "";
                        let icon = errorObj ? errorObj.icon : "warning";

                        const isTimeout =
                            text === "Auth token expired. Please re-login.";

                        Swal.fire({
                            title: error.response.status + ` ${title}`,
                            text,
                            icon,
                            allowOutsideClick: isTimeout ? false : true,
                            allowEscapeKey: isTimeout ? false : true,
                        }).then((res) => {
                            if (res.isConfirmed && isTimeout) {
                                window.location = "/logout";
                            }
                        });
                    }
                } else {
                    Swal.fire({
                        title: "Server error. Please try again later",
                        icon: "warning",
                    });
                }
                reject(error);
            });
    });
}

// function unauthPost(endpoint, data) {
// 	let url = API_ROOT + endpoint;

// 	var config = {
// 		method: "post",
// 		url,
// 		data,
// 		headers: {
// 			"Content-Type": "application/json",
// 			"cache-control": "no-cache",
// 		},
// 	};

// 	// console.log(config);

// 	return new Promise((resolve, reject) => {
// 		axios(config)
// 			.then((response) => {
// 				if (response.status === 200 || response.status === 201) {
// 					resolve(response.data);
// 				} else {
// 					const error = response.statusText;
// 					reject(error);
// 				}
// 			})
// 			.catch(function (error) {
// 				reject(error);
// 			});
// 	});
// }

/* 
	This function does not show error message even if the API call is rejected. 	
	This is used when we want to update an existing data without setting loading status
	For instance, in Home page, we have activity stream. The stream is updated when user creates a new group.
	Then the stream is updated behind the scene without showing Spinner or failed message.
	It is ok if API call fails.
*/
function axiosGet(endpoint, data) {
    let url = API_ROOT + endpoint;
    let query = new URLSearchParams(omitBy(data, (v) => v === "")).toString(); // omitBy(data, v => v === "") helps remove empty values in the object data
    query = query.replace(new RegExp("\\+", "g"), " "); // replace "+" with a space
    url += "?" + query;

    const config = {
        method: "get",
        headers: authHeader(),
        url,
    };

    // console.log(config);

    return new Promise((resolve, reject) => {
        axios(config).then((response) => {
            if (response.status === 200 || response.status === 204) {
                resolve(response.data);
            } else {
                const error = response.statusText;
                reject(error);
            }
        });
    });
}

function EncryptString(str) {
    var key = CryptoJS.enc.Utf8.parse("SikkaAxar2641990");
    var iv = CryptoJS.enc.Utf8.parse("SikkaAxar2641990");
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(str), key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encrypted;
}

function authenticationPOST(endpoint, data) {
    let url = API_ROOT + endpoint;

    // Get the current UTC time
    const now = new Date();

    // Add 5 minute to the current time
    now.setSeconds(now.getSeconds() + 300);

    // Format the time string in the desired format
    const formattedTime = now.toISOString().replace("T", " ").slice(0, 19);

    const headers = {
        "x-auth-token":
            "Bearer " +
            EncryptString("SikkaSoftware~Axar~2641990~" + formattedTime),
        "Content-Type": "application/json",
        "cache-control": "no-cache",
    };

    const config = {
        method: "post",
        headers,
        url,
        data,
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve(response.data);
                } else {
                    const error = response.statusText;
                    reject(error);
                }
            })
            .catch(function (error) {
                Swal.fire({
                    title: "Server error. Please try again later",
                    icon: "warning",
                });
                reject(error);
            });
    });
}
function authenticationGET(endpoint, data) {
    let url = API_ROOT + endpoint;

    // Get the current UTC time
    const now = new Date();

    // Add 30 seconds to the current time
    now.setSeconds(now.getSeconds() + 60);

    // Format the time string in the desired format
    const formattedTime = now.toISOString().replace("T", " ").slice(0, 19);

    const headers = {
        "x-auth-token":
            "Bearer " +
            EncryptString("SikkaSoftware~Axar~2641990~" + formattedTime),
        "Content-Type": "application/json",
        "cache-control": "no-cache",
    };
    const config = {
        method: "get",
        headers,
        url,
        data,
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    resolve(response.data);
                } else {
                    const error = response.statusText;
                    reject(error);
                }
            })
            .catch(function (error) {
                Swal.fire({
                    title: "Server error. Please try again later",
                    icon: "warning",
                });
                reject(error);
            });
    });
}
