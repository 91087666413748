import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PatternFormat } from "react-number-format";
import Swal from "sweetalert2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import "./SettingsProfile.scss";
import CardLoader from "../../components/loaders/CardLoader";
import { ApiCalls } from "../../api/allApiCalls";
import PasswordValidation from "../../components/PasswordValidation";
import {
    formatSocialSecurityNumber,
    initialErrObj,
    validatePassword,
} from "../../utils";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";

const initialValues = {
    userName: "",
    password: "",
    passwordConfirm: "",
    ssn: "",
    practiceName: "",
    practiceNumber: "",
    practiceAddress: "",
};

function SettingsProfile() {
    const { masterId, userId, practiceId, patientId } = useSelector(
        (state) => state.userInfo
    );
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState(initialValues);
    const [data, setData] = useState(initialValues);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [passwordErr, setPasswordErr] = useState(initialErrObj);
    const [updateData, setUpdateData] = useState(false);

    useEffect(() => {
        const getSettingsProfileData = async () => {
            try {
                const res = await ApiCalls.authGetAPI(
                    "/v2/Patient/SettingsProfileData",
                    {
                        masterId,
                        userId,
                        practiceId,
                        patientId,
                    }
                );

                const dataToStore = {
                    ...res[0],
                    passwordConfirm: res[0].password,
                    ssn: formatSocialSecurityNumber(res[0].ssn),
                };
                setData(dataToStore);
                setFormValues(dataToStore);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        getSettingsProfileData();
    }, [updateData]);

    /**
     * Whenever the user type in new password or confirm the new password,
     * validate if it satisfies the condition of password
     */
    useEffect(() => {
        setPasswordErr(
            validatePassword(formValues.password, formValues.passwordConfirm)
        );
    }, [formValues.password, formValues.passwordConfirm]);

    const handleDiscard = () => {
        setEditing(false);
        setFormValues(data);
        setShowPassword(false);
        setShowPasswordConfirm(false);
    };

    /**
     * First, check if password satisfies conditions.
     * Check if SSN is empty
     * If Password and SSN are valid, call API
     * update data && formValues
     * set Editing to False
     */
    const handleSaveChanges = async () => {
        if (Object.values(passwordErr).includes(true)) {
            Swal.fire({ text: "Password is invalid." });
            return;
        }
        const ssn = formValues.ssn.replaceAll("-", "").replaceAll(" ", "");
        if (!ssn || ssn.length !== 9) {
            Swal.fire({
                text: "Social Security Number is missing or invalid.",
            });
            return;
        }

        try {
            setLoading(true);

            const res = await ApiCalls.authPutAPI(
                "/v2/Patient/SettingsProfileData",
                {
                    masterId,
                    userId,
                    patientId,
                    practiceId,
                    userName: formValues.userName,
                    password: formValues.password,
                    ssn,
                    practiceName: formValues.practiceName,
                    practiceNumber: formValues.practiceNumber,
                    practiceAddress: formValues.practiceAddress,
                }
            );

            if (res.isSuccess && res.message === "Successful") {
                setUpdateData(!updateData);
            } else {
                Swal.fire({
                    text: "Something wrong happened. Please try again later",
                });
            }
            trackAcvitity(
                itemType.SETTINGS,
                itemName.UPDATE_PROFILE_INFO,
                "Settings >> Click Edit icon >> Edit profile >> Click 'Save Changes' button"
            );
        } catch (err) {
            console.log(err);
        } finally {
            setEditing(false);
            setLoading(false);
            setShowPassword(false);
            setShowPasswordConfirm(false);
        }
    };

    const handleChange = (e) => {
        setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <div className="position-relative mt-1">
            {loading && (
                <div className="border" style={{ top: 10 }}>
                    <CardLoader />
                </div>
            )}
            <div className="settings-profile mb-4 pt-4 pe-5">
                <div className="fz-14 mb-4">
                    {!editing ? (
                        <div
                            className="d-flex align-items-center"
                            onClick={() => setEditing(true)}
                        >
                            <BorderColorIcon
                                style={{
                                    color: "681c9a",
                                    fontSize: 20,
                                    marginRight: 8,
                                }}
                                role="button"
                            />
                            Edit
                        </div>
                    ) : (
                        <span className="font-destructive">
                            Password and Social Security Number are required
                            fields *
                        </span>
                    )}
                </div>

                {/* USERNAME */}
                <div className="settings-profile__input-item">
                    <div className="label-container">
                        <label>Username</label>
                        <div>Manage your account settings and preferences.</div>
                    </div>
                    <input
                        name="userName"
                        className={`${!editing ? "editing-input" : ""}`}
                        placeholder="Username"
                        disabled={editing ? false : true}
                        value={formValues.userName}
                        onChange={handleChange}
                    />
                </div>

                {/* PASSWORD */}
                <div className="settings-profile__input-item">
                    <div className="label-container">
                        <label>Password</label>
                        <div>Manage your account settings and preferences.</div>
                    </div>
                    <div className="position-relative">
                        <input
                            name="password"
                            type={`${showPassword ? "text" : "password"}`}
                            className={`${!editing ? "editing-input" : ""}`}
                            placeholder="Password"
                            disabled={editing ? false : true}
                            onChange={handleChange}
                            value={formValues.password}
                        />
                        {showPassword ? (
                            <VisibilityOffIcon
                                style={{
                                    position: "absolute",
                                    right: 7,
                                    bottom: 7,
                                    color: "#767b87",
                                }}
                                onClick={() => setShowPassword(false)}
                            />
                        ) : (
                            <VisibilityIcon
                                style={{
                                    position: "absolute",
                                    right: 7,
                                    bottom: 7,
                                    color: "#767b87",
                                }}
                                onClick={() => setShowPassword(true)}
                            />
                        )}
                    </div>
                </div>

                {/* PASSWORD CONFIRMATION */}
                {editing && (
                    <>
                        <div className="settings-profile__input-item">
                            <div className="label-container">
                                <label>Confirm Password</label>
                                <div>Re-enter your password</div>
                            </div>
                            <div className="position-relative">
                                <input
                                    name="passwordConfirm"
                                    type={`${
                                        showPasswordConfirm
                                            ? "text"
                                            : "password"
                                    }`}
                                    className={`${
                                        !editing ? "editing-input" : ""
                                    }`}
                                    placeholder="Re-enter Password"
                                    disabled={editing ? false : true}
                                    onChange={handleChange}
                                    value={formValues.passwordConfirm}
                                />
                                {showPasswordConfirm ? (
                                    <VisibilityOffIcon
                                        style={{
                                            position: "absolute",
                                            right: 7,
                                            bottom: 7,
                                            color: "#767b87",
                                        }}
                                        onClick={() =>
                                            setShowPasswordConfirm(false)
                                        }
                                    />
                                ) : (
                                    <VisibilityIcon
                                        style={{
                                            position: "absolute",
                                            right: 7,
                                            bottom: 7,
                                            color: "#767b87",
                                        }}
                                        onClick={() =>
                                            setShowPasswordConfirm(true)
                                        }
                                    />
                                )}
                            </div>
                        </div>

                        {/* PASSWORD REQUIREMENTS */}
                        <div>
                            <div className="fw-bold mb-2">
                                Password Requirements
                            </div>
                            <PasswordValidation passwordErr={passwordErr} />
                        </div>
                    </>
                )}
            </div>

            <div className="settings-profile border-top pt-4 border-top pe-5">
                {/* SOCIAL SECURITY NUMBER */}
                <div className="settings-profile__input-item">
                    <div className="label-container">
                        <label>Social Security Number</label>
                        <div>Enter your correct 9 digit social security</div>
                    </div>
                    <PatternFormat
                        name="ssn"
                        format="###-##-####"
                        allowEmptyFormatting
                        className={`${!editing ? "editing-input" : ""}`}
                        placeholder="###-##-####"
                        disabled={editing ? false : true}
                        onChange={handleChange}
                        value={formValues.ssn}
                    />
                </div>

                {/* PRACTICE NAME */}
                <div className="settings-profile__input-item">
                    <div className="label-container">
                        <label>Practice Name</label>
                        <div>Enter full name of your doctor</div>
                    </div>
                    <input
                        name="practiceName"
                        className={`${!editing ? "editing-input" : ""}`}
                        placeholder="Enter full name of your doctor"
                        disabled={editing ? false : true}
                        onChange={handleChange}
                        value={formValues.practiceName}
                    />
                </div>

                {/* PRACTICE PHONE */}
                <div className="settings-profile__input-item">
                    <div className="label-container">
                        <label>Practice Phone Number</label>
                        <div>Enter country code & number of your practice</div>
                    </div>
                    <input
                        name="practiceNumber"
                        className={`${!editing ? "editing-input" : ""}`}
                        placeholder="Enter country code & number of your practice"
                        disabled={editing ? false : true}
                        onChange={handleChange}
                        value={formValues.practiceNumber}
                    />
                </div>

                {/* PRACTICE ADDRESS */}
                <div className="settings-profile__input-item">
                    <div className="label-container">
                        <label>Practice Address</label>
                        <div>Manage your account settings and preferences.</div>
                    </div>
                    <input
                        name="practiceAddress"
                        className={`${!editing ? "editing-input" : ""}`}
                        placeholder="Enter practice address"
                        disabled={editing ? false : true}
                        onChange={handleChange}
                        value={formValues.practiceAddress}
                    />
                </div>
            </div>

            {editing && (
                <div className="mt-5 mb-3 d-flex gap-3">
                    <button
                        className="button button-primary"
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </button>
                    <button
                        className="button button-secondary"
                        onClick={handleDiscard}
                    >
                        Discard
                    </button>
                </div>
            )}
        </div>
    );
}

export default SettingsProfile;
