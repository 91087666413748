import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiCalls } from "../api/allApiCalls";
import { monthFullName } from "../utils";

const useFetchProgressiveProcedures = () => {
	const { masterId, userId, practiceId, custId, patientId } = useSelector(
		(state) => state.userInfo
	);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	function addMonths(monthsToAdd) {
		const currentMonthIndex = new Date().getMonth();
		const targetMonthIndex = (currentMonthIndex + monthsToAdd) % 12;
		const year = (currentMonthIndex + monthsToAdd) / 12;
		return (
			monthFullName[targetMonthIndex] +
			" " +
			(new Date().getFullYear() + Math.floor(year))
		);
	}

	useEffect(() => {
		const fetchProgressiveProceduresData = async () => {
			try {
				setLoading(true);
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/ProcedurePrediction",
					{
						masterId,
						userId,
						practiceId,
						custId,
						patientId,
					}
				);

				const predictedDate = addMonths(parseInt(res.months));

				setData({ ...res, predictedDate });
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		fetchProgressiveProceduresData();
	}, []);

	return [loading, data];
};

export default useFetchProgressiveProcedures;
