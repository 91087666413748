import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import NoDocument from "../../assets/images/NoDocument.png";
import CardLoader from "../../components/loaders/CardLoader";
import { ApiCalls } from "../../api/allApiCalls";
import EditMedicalNote from "./EditMedicalNote";
import HealthConditionsTooltip from "../../components/tooltips/HealthConditionsTooltip";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../utils/activityTracking";
import { VETERINARY } from "../../utils";
import EditIcon from "../../assets/images/EditIcon.svg";

function HealthConditions() {
    const { masterId, userId, practiceId, custId, patientId, specialty } =
        useSelector((state) => state.userInfo);
    const { selectedPet } = useSelector((state) => state.petInfo);
    const [data, setData] = useState("");
    const [dataArr, setDataArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [updateData, setUpdateData] = useState(false);

    useEffect(() => {
        const fetchMedicalNote = async () => {
            try {
                setLoading(true);
                const res = await ApiCalls.authGetAPI(
                    "/v2/Patient/PatientMedicalNote",
                    {
                        masterId,
                        userId,
                        practiceId,
                        custId,
                        patientId:
                            specialty === VETERINARY
                                ? selectedPet.patientId
                                : patientId,
                    }
                );
                if (res?.result) {
                    const parts = res.result.split("\n");
                    const filteredArr = parts.filter(
                        (item) => item.trim() !== ""
                    );
                    setDataArr(filteredArr);
                    setData(res.result);
                } else {
                    setData("");
                    setDataArr([]);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        if (specialty === VETERINARY && !selectedPet) return;
        fetchMedicalNote();
    }, [updateData, selectedPet]);

    return (
        <div className="position-relative dashboard-item-card-container flex-1 d-flex flex-column justify-content-between">
            <h3>
                Health Conditions
                <HealthConditionsTooltip />
            </h3>

            <div
                className="position-absolute d-flex align-items-center"
                style={{ right: 0, top: 0, cursor: "pointer" }}
                onClick={() => {
                    setOpenModal(true);
                    trackAcvitity(
                        itemType.HEALTH_CONDITIONS,
                        itemName.CLICK_EDIT_HEALTH_CONDITIONS,
                        "Home >> Click Edit icon in Health Conditions section to start edit the information"
                    );
                }}
            >
                <img src={EditIcon} style={{ height: 20, marginRight: 5 }} />
                <div className="fz-14">Edit</div>
            </div>

            <div className="flex-1 border-radius-6 card-border position-relative d-flex flex-column justify-content-between">
                <div className="overflow-blur"></div>
                <div
                    className="ps-2 position-relative flex-1"
                    style={{
                        maxHeight: 145,
                        overflowY: "auto",
                        padding: "16px 20px",
                    }}
                >
                    {loading && <CardLoader />}
                    <div className="position-relative">
                        <ul
                            style={{
                                paddingInline: 27,
                                marginBottom: 0,
                            }}
                        >
                            {dataArr.map((item, idx) => (
                                <li key={idx}>{item}</li>
                            ))}
                        </ul>
                    </div>

                    {!data && (
                        <div className="d-flex flex-column align-items-center justify-content-center text-center">
                            <img
                                alt=""
                                src={NoDocument}
                                style={{ height: 76 }}
                            />
                            <div className="fw-bold mb-2">
                                No health conditions added
                            </div>
                            <div className="font-gray lh-sm">
                                You can start editing your health conditions
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {openModal && (
                <EditMedicalNote
                    data={data}
                    openModal={openModal}
                    closeModal={() => setOpenModal(false)}
                    updateData={() => setUpdateData(!updateData)}
                    patientId={
                        specialty === VETERINARY && selectedPet
                            ? selectedPet.patientId
                            : patientId
                    }
                />
            )}
        </div>
    );
}

export default HealthConditions;
