import React from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";

import OverflowBlur from "../../components/OverflowBlur";
import NoDocuments from "../../assets/images/NoDocuments.png";
import CardLoader from "../../components/loaders/CardLoader";

const formatDate = (inputDateString) => {
	const inputDate = new Date(inputDateString);
	const options = { year: "numeric", month: "short", day: "2-digit" };
	return inputDate.toLocaleDateString("en-US", options);
};

function VaccinesAndTreatmentTemplate({ title, data, loading }) {
	return (
		<div className="dashboard-item-card-container">
			<h3>{title}</h3>

			<div className="border-radius-6 card-border fz-14 position-relative">
				{loading && <CardLoader />}
				<OverflowBlur />
				<div
					className="d-flex flex-column"
					style={{
						gap: 16,
						maxHeight: 300,
						overflowY: "auto",
						padding: "16px 20px",
					}}
				>
					{data.length === 0 && (
						<div className="height-100p d-flex flex-column justify-content-center align-items-center">
							<img
								alt=""
								src={NoDocuments}
								style={{ maxHeight: 100 }}
							/>
							<div>No {title} Record</div>
						</div>
					)}
					{data.map((v, idx) => (
						<div
							key={idx}
							className="d-flex gap-4 align-items-center justify-content-between"
						>
							<div>
								{v.description}{" "}
								{title === "Vaccines" ? (
									<span> ({v.noOfYears} year)</span>
								) : (
									" "
								)}
							</div>

							<div className="timestamp">
								<DateRangeIcon className="icon" />{" "}
								{formatDate(v.issuedDate)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default VaccinesAndTreatmentTemplate;
