import React from "react";

import "./AuthenticationLeftColumn.scss";
import sikka_logo_transparent from "../assets/images/sikka_logo_transparent.png";
import SikkaScores from "../assets/images/SikkaScores.png";
import IntroCard from "../assets/images/IntroCard.png";

function LoginLeftContent() {
	return (
		<div className="login-left-content">
			<div style={{ padding: 8 }}>
				<img
					className="sikka-ai-logo mt-4 mb-3"
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 36 }}
				/>
			</div>

			<div className="image-container">
				<img alt="" src={IntroCard} />
			</div>

			<div className="image-container">
				<img alt="" src={SikkaScores} />
			</div>

			<div
				className="welcome-message pb-4 width-100p mt-2"
				style={{ paddingInline: 8 }}
			>
				<div className="fw-bold fz-20">Welcome to Patient Portal</div>
				<div>
					Start managing your finances, scores and procedures through
					your patient portal screen.
				</div>
			</div>
		</div>
	);
}

export default LoginLeftContent;
