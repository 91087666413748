import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import "./InsuranceDetails.scss";
import { ApiCalls } from "../../api/allApiCalls";
import InsuranceCardForm from "./InsuranceCardForm";
import CardLoader from "../../components/loaders/CardLoader";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import Swal from "sweetalert2";

function InsuranceDetails() {
	const { masterId, userId, patientId, practiceId } = useSelector(
		(state) => state.userInfo
	);
	const [loading, setLoading] = useState(true);
	const [updateData, setUpdateData] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [data, setData] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(0);

	useEffect(() => {
		const fetchInsuranceData = async () => {
			try {
				setLoading(true);
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/InsuranceCardData",
					{
						masterId,
						userId,
						patientId,
						practiceId,
					}
				);

				setData(res);
				if (res.length > 0) {
					setSelectedIndex(0);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		fetchInsuranceData();
	}, [updateData]);

	const handleRemove = async (uid, cardname) => {
		//uid, cardname
		Swal.fire({
			title: `Are you sure you want to delete <span > ${cardname}</span> card?`,
			showCancelButton: true,
			confirmButtonText: "Remove",
			customClass: {
				confirmButton: "swal-button swal-button--confirm",
				cancelButton: "swal-button swal-button--cancel",
				title: "swal-title swal-title--title",
			},
			buttonsStyling: false,
			confirmButtonColor: "#681c9a",
		}).then(async (result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				// Swal.fire("Saved!", "", "success");
				try {
					const res = await ApiCalls.authDeleteAPI(
						"/v2/Patient/InsuranceCardData",
						{
							masterId,
							userId,
							patientId,
							uniqueId: uid,
							practiceId,
						}
					);

					if (
						res?.isSuccess === true &&
						res?.message === "successful"
					) {
						Swal.fire({
							icon: "success",
							text: ` ${cardname} card has been deleted successfully!`,
						});
					} else {
						Swal.fire({
							icon: "error",
							text: `Something went wrong. Please try again later!`,
						});
					}
					setUpdateData((prev) => !prev);

					trackAcvitity(
						itemType.USER_PROFILE,
						itemName.DELETE_INSURANCE_CARD,
						" User Profile Page >> Click delete icon >>result 'has been delete successfully'"
					);
				} catch (err) {
					console.log(err);
				} finally {
					setLoading(false);
				}
			} else if (result.isDenied) {
				Swal.fire("Changes are not saved", "", "info");
			}
		});
	};

	return (
		<div className="ins-details">
			<div className="profile-header">
				<h2>Insurance Details</h2>
			</div>
			<div className="position-relative">
				{loading && <CardLoader />}
				<div className="mb-4">
					<button
						className="button button-primary width-100p mb-2"
						onClick={() => {
							setOpenModal(true);
							trackAcvitity(
								itemType.USER_PROFILE,
								itemName.CLICK_ADD_INSURANCE_CARD,
								"User Profile page >> Click Plus icon to open New Insurance Card Modal"
							);
						}}
					>
						Upload Insurance Card
					</button>
					<div>
						Upload both Front & End images of the card. Jpg, Png,
						Jpeg are the accepted formats for the insurance card.
					</div>
				</div>

				{data.length > 0 && (
					<div>
						<div className="fz-16 font-black mb-2">Insurance</div>
						<div
							className="d-flex gap-4"
							style={{ overflowX: "auto" }}
						>
							<select
								value={selectedIndex}
								onChange={(e) =>
									setSelectedIndex(e.target.value)
								}
							>
								{data.map((item, idx) => (
									<option key={idx} value={idx}>
										{item.cardName}
									</option>
								))}
							</select>
						</div>

						<div className="mt-3 d-flex flex-wrap gap-3">
							<div>
								<div className="d-flex justify-content-between align-items-center mb-1">
									<div>Front</div>
									<DeleteIcon
										style={{
											color: "#681c9a",
											fontSize: 20,
											marginRight: 8,
											cursor: "pointer",
										}}
										onClick={() =>
											handleRemove(
												data[selectedIndex]?.uniqueId,
												data[selectedIndex]?.cardName
											)
										}
									/>
								</div>
								<img
									alt=""
									src={data[selectedIndex]?.frontImageUrl}
									className="width-100p border border-radius-6"
								/>
							</div>

							<div>
								<div className="mb-1">Back</div>
								<img
									alt=""
									src={data[selectedIndex]?.backImageUrl}
									className="width-100p border border-radius-6"
								/>
							</div>
						</div>
					</div>
				)}
			</div>

			{openModal && (
				<InsuranceCardForm
					openModal={openModal}
					closeModal={() => setOpenModal(false)}
					setUpdateData={setUpdateData}
				/>
			)}
		</div>
	);
}

export default InsuranceDetails;
