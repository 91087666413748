import React from "react";
import "./ProfileAndSettings.scss";
import BasicInformation from "./BasicInformation";
import AdditionalInformation from "./AdditionalInformation";
import PracticeInformation from "./PracticeInformation";
import PrivacyAndSecurity from "./PrivacyAndSecurity";
import InsuranceDetails from "./InsuranceDetails";
import useSetTitle from "../../hooks/useSetTitle";
//import SaigeButton from "../../components/SAIGE/SaigeButton";
import { useSelector } from "react-redux";
import { DENTAL, VETERINARY } from "../../utils";
import PetInformation from "./PetInformation";
function ProfileAndSettings() {
    useSetTitle("Profile & Settings");
    const { specialty } = useSelector((state) => state.userInfo);

    return (
        <div style={{ paddingLeft: 14 }}>
            <div className="me-5 d-flex justify-content-end mt-4">
                {/* <SaigeButton /> */}
            </div>
            <div className="ProfileAndSettings-page">
                <div className="ProfileAndSettings-page__col-1">
                    <div className="userInfo-flex-item userInfo-flex-item-1">
                        <BasicInformation />
                    </div>
                    {specialty === DENTAL && (
                        <div className="userInfo-flex-item userInfo-flex-item-2">
                            <AdditionalInformation />
                        </div>
                    )}
                    {specialty === VETERINARY && (
                        <div className="userInfo-flex-item userInfo-flex-item-2">
                            <PetInformation />
                        </div>
                    )}
                    <div className="userInfo-flex-item userInfo-flex-item-3">
                        <PracticeInformation />
                    </div>
                    <div className="userInfo-flex-item userInfo-flex-item-4">
                        <PrivacyAndSecurity />
                    </div>
                </div>
                {specialty === DENTAL && (
                    <div className="ProfileAndSettings-page__col-2 mb-5">
                        <InsuranceDetails />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProfileAndSettings;
