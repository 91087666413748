import React from "react";
import CloseIcon from "@mui/icons-material/Close";

function CloseModalButton({ closeModal }) {
	return (
		<div className="generic-modal-btn" onClick={closeModal}>
			<CloseIcon />
		</div>
	);
}

export default CloseModalButton;
