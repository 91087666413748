///////////////////////////////////////////////////////
// STORE DATA IN BROWSER
///////////////////////////////////////////////////////
// We will only store the data in "session storage", not "local storage"
const set = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

const get = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
};

export const browserStore = {
    set,
    get,
};

///////////////////////////////////////////////////////
// Log out
///////////////////////////////////////////////////////
export const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
    window.location = "/login";
};

///////////////////////////////////////////////////////
// FORMAT A NUMBER TO CURRENCY
///////////////////////////////////////////////////////
export const toCurrencyConverter = (data) => {
    // data could be a string or a number. convert a number into currency with format like $25.20
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    return formatter.format(data);
};

///////////////////////////////////////////////////////
// FORMAT TIME && DATE
///////////////////////////////////////////////////////
export const currentMonth = () => {
    return new Date().getMonth() + 1;
};

export const currentYear = () => {
    return new Date().getFullYear();
};

// Format from "YYYY-MM-DD" to "MM-DD-YYYY"
export const formatDateString = (date) => {
    const parts = date.split("-"); // Split the date string into an array of parts

    const formattedDate = `${parts[1]}-${parts[2]}-${parts[0]}`; // Reorder the parts and join them back into a string

    return formattedDate;
};

// Format from "YYYY-MM-DD" to "MMM-DD-YYYY"
export const formatDateShortMonth = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");

    // Array of month names in three-letter abbreviation format
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    // Format the month component as a three-letter abbreviation
    const formattedMonth = monthNames[parseInt(month, 10) - 1];

    // Join the components back together in the desired format
    const formattedDate = `${formattedMonth} ${day}, ${year}`;
    return formattedDate;
};

// convert STRING such as "2023-06-29T00:00:00" or "2023-06-29" to "Jun 29, 2023"
export const formatISOtoShortMonthDate = (isoDateString) => {
    if (isoDateString) {
        const date = new Date(isoDateString);
        const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            timeZone: "UTC",
        };
        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
            date
        );

        return formattedDate;
    } else return "";
};

// Convert a Date OBJECT to a string format short month
// Example: Sun May 14 2023 00:00:00 GMT-0500 (Central Daylight Time) ==> "May 14, 2023"
export const formateDateObjToShortMonth = (dateObj) => {
    return new Date(dateObj).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });
};

// reformat time 16:00 or 16:00:00 to 4:00 PM
export const formatTime = (timeStr) => {
    const date = new Date(`2000-01-01T${timeStr}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${amPm}`;
};

// reformat time from 4:00 PM to 16:00
export const formatTime2 = (input) => {
    // Parse the input string into a Date object
    const date = new Date(`01/01/2000 ${input}`);

    // Extract the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the output string
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
};

export const isNumeric = (s) => {
    return !isNaN(Number(s));
};

/*
 * Mask a cell phone to format ***-X**-XXXX
 */
export const maskCell = (cell) => {
    let result = "";
    for (let i = 0; i < cell.length; i++) {
        if (i < 3 || i === 5 || i === 6) {
            result += "*";
        } else {
            result += cell[i];
        }
    }
    return result;
};

export const maskEmail = (email) => {
    const [username, remaining] = email.split("@");
    const [server, domain] = remaining.split(".");

    let maskedUsername = "";
    for (let i = 0; i < username.length; i++) {
        if (i < 4) {
            maskedUsername += username[i];
        } else {
            maskedUsername += "*";
        }
    }

    let maskedServer = "";
    for (let i = 0; i < server.length; i++) {
        maskedServer += "*";
    }

    return maskedUsername + "@" + maskedServer + "." + domain;
};

export const initialErrObj = {
    min: false,
    max: false,
    lowercase: false,
    uppercase: false,
    specialChar: false,
    number: false,
    matching: false,
};

/**
 * validate if the password satisfies requirement:
 * min 12 char
 * max 40 char
 * 1 uppercase
 * 1 lowercase
 * 1 number
 * 1 special char
 * matching password
 *
 * @param {} passwordString
 * @param {} passwordConfirmString
 * @returns error object
 */
export const validatePassword = (passwordString, passwordConfirmString) => {
    const errObj = { ...initialErrObj };

    if (passwordString.length < 12) errObj["min"] = true;
    if (passwordString.length > 40) errObj["max"] = true;
    if (!/[a-z]/.test(passwordString)) errObj["lowercase"] = true;
    if (!/[A-Z]/.test(passwordString)) errObj["uppercase"] = true;
    if (!/[~!@#$%^&*()-+]/.test(passwordString)) errObj["specialChar"] = true;
    if (!/\d/.test(passwordString)) errObj["number"] = true;
    if (passwordString !== passwordConfirmString) errObj["matching"] = true;

    return errObj;
};

export const formatSocialSecurityNumber = (socialSecurityNumber) => {
    const formattedSSN = socialSecurityNumber.replace(
        /^(\d{3})(\d{2})(\d{4})$/,
        "$1-$2-$3"
    );
    return formattedSSN;
};

export const convertBase64 = (urlString) => {
    if (typeof urlString === "string") {
        return urlString.replace("data:", "").replace(/^.+,/, "");
    } else {
        return "";
    }
};

export const DENTAL = "Dental";
export const VETERINARY = "Veterinary";

export const getSelectedPet = () => {
    return browserStore.get("selectedPet");
};

export const storeSelectedPet = (data) => {
    browserStore.set("selectedPet", data);
};

export const setPetList = (petData) => {
    return petData.map((p) => {
        if (p?.updatedDetails?.name) {
            // If there is update data, store update data
            return {
                age: p.updatedDetails.age,
                breed: p.updatedDetails.breed,
                custId: p.custId,
                gender: p.updatedDetails.gender,
                guarId: p.guarId,
                height: p.height,
                name: p.updatedDetails.name,
                patientId: p.patientId,
                practiceId: p.practiceId,
                species: p.updatedDetails.species,
                weight: p.weight,
                weightType: p.weightType,
                pictureUrl: p.pictureUrl,
                uniqueId: p.uniqueId,
            };
        } else {
            // Else store original data
            return p;
        }
    });
};

export const containsSpecialCharacters = (text) => {
    const regex = /^[a-zA-Z0-9\s]+(?:[.?])?$/;
    return !regex.test(text.trim());
};

export const monthFullName = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
