import React, { useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { NavLink } from "react-router-dom";

import "./Articles.scss";
import ArticleModal from "../../components/articles/ArticleModal";
import { articleData } from "../../components/articles/articleData";
import { formatISOtoShortMonthDate } from "../../utils";
import oralB_logo from "../../assets/images/oralB_logo.png";
import Crest_logo from "../../assets/images/Crest_logo.png";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";

const sourceImgStyles = {
	height: 18,
};

function EducationArticles() {
	const [openModal, setOpenModal] = useState(false);
	const [selectedArticle, setSelectedArticle] = useState(null); // eslint-disable-line

	return (
		<div className="dashboard__education-article">
			<div
				className="education-article-header"
				style={{ paddingBottom: 24 }}
			>
				<h2 style={{ marginTop: 8 }}>Education Articles</h2>
				<div className="gradient-line"></div>
				<NavLink
					className="fz-14 font-gray view-all-text"
					to="/education"
					style={{ textDecoration: "none" }}
				>
					<LaunchIcon
						className="font-purple me-2"
						style={{ height: 16, width: 16, marginBottom: 3 }}
						role="button"
					/>
					<span>View all</span>
				</NavLink>
			</div>

			<div className="articles-container">
				{articleData.map((item, idx) => {
					if (
						item.articleId !== 4 &&
						item.articleId !== 9 &&
						item.articleId !== 11
					) {
						return (
							<div key={idx} style={{ display: "none" }}></div>
						);
					} else
						return (
							<a
								href={item.url}
								target="_blank"
								rel="noopener noreferrer"
								key={idx}
								className="dashboard-item-card article-item-card flex-1 font-gray d-flex flex-column justify-content-between px-3 pt-3 pb-2"
								onClick={() =>
									trackAcvitity(
										itemType.EDUCATION_ARTICLES,
										itemName.CLICK_EDUCATION_ARTICLE_LINK,
										"Home >> Click on Eduction Articles card"
									)
								}
							>
								<div className="d-flex align-items-start gap-3">
									<img
										style={{
											width: 85,
											height: 85,
											objectFit: "cover",
											borderRadius: 6,
										}}
										alt=""
										src={item.imgSrc}
									/>

									<div
										className="height-100p d-flex align-items-start flex-column justify-content-start"
										style={{ overflow: "hidden" }}
									>
										<div className="article-card-line-clamp line-clamp-container">
											<div
												className="fw-bold font-grey01 line-clamp"
												style={{ fontSize: 16 }}
											>
												{item.articleTitle}
											</div>
										</div>
										<div className="article-card-line-clamp line-clamp-container">
											<div className="line-clamp fz-14">
												{item.content}
											</div>
										</div>
									</div>
								</div>

								<div className="d-flex align-items-center justify-content-between mt-3">
									{item.timestamp && (
										<div
											style={{
												fontSize: 12,
											}}
										>
											{formatISOtoShortMonthDate(
												item.timestamp
											)}
										</div>
									)}
									<div className="d-flex align-items-center gap-1">
										Source:{" "}
										{item.source === "Oral B" ? (
											<img
												alt="Oral B"
												src={oralB_logo}
												style={sourceImgStyles}
											/>
										) : item.source === "Crest" ? (
											<img
												alt="Crest"
												src={Crest_logo}
												style={sourceImgStyles}
											/>
										) : (
											item.source
										)}
									</div>
									<div className="font-purple text-underline">
										View Full Article
									</div>
								</div>
							</a>
						);
				})}
			</div>

			{openModal && (
				<ArticleModal
					openModal={openModal}
					closeModal={() => setOpenModal(false)}
					articleId={selectedArticle}
				/>
			)}
		</div>
	);
}

export default EducationArticles;
