import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiCalls } from "../api/allApiCalls";
import { VETERINARY } from "../utils";

const useFetchAccountsReceivable = () => {
	const { masterId, userId, practiceId, custId, patientId, specialty } =
		useSelector((state) => state.userInfo);
	const { selectedPet } = useSelector((state) => state.petInfo);

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchAccountsReceivableData = async () => {
			try {
				setLoading(true);
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/AccountsReceivableData",
					{
						masterId,
						userId,
						practiceId,
						custId,
						patientId:
							specialty === VETERINARY
								? selectedPet.patientId
								: patientId,
					}
				);

				let insuranceArray = res?.insurance;
				let guarantorArray = res?.guarantor;

				let insurance_amtLess30 = 0;
				let insurance_amt30to60 = 0;
				let insurance_amt60to90 = 0;
				let insurance_amt90Above = 0;

				// If there is content in insurance Array
				if (insuranceArray.length > 0) {
					for (const obj of insuranceArray) {
						insurance_amtLess30 += obj.amtLess30;
						insurance_amt30to60 += obj.amt30to60;
						insurance_amt60to90 += obj.amt60to90;
						insurance_amt90Above += obj.amt90Above;
					}
				}

				// If there is content in guarantor Array
				let guarantor_amtLess30 = 0;
				let guarantor_amt30to60 = 0;
				let guarantor_amt60to90 = 0;
				let guarantor_amt90Above = 0;

				if (guarantorArray.length > 0) {
					for (const obj of guarantorArray) {
						guarantor_amtLess30 += obj.amtLess30;
						guarantor_amt30to60 += obj.amt30to60;
						guarantor_amt60to90 += obj.amt60to90;
						guarantor_amt90Above += obj.amt90Above;
					}
				}

				const formattedData = [
					{
						period: "0-30 days",
						insurance: insurance_amtLess30,
						guarantor: guarantor_amtLess30,
					},
					{
						period: "31-60 days",
						insurance: insurance_amt30to60,
						guarantor: guarantor_amt30to60,
					},
					{
						period: "61-90 days",
						insurance: insurance_amt60to90,
						guarantor: guarantor_amt60to90,
					},
					{
						period: "Above 90 days",
						insurance: insurance_amt90Above,
						guarantor: guarantor_amt90Above,
					},
				];

				setData(formattedData);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		if (specialty === VETERINARY && !selectedPet) return;
		fetchAccountsReceivableData();
	}, [selectedPet]);

	return [loading, data];
};

export default useFetchAccountsReceivable;
