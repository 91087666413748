import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Swal from "sweetalert2";
import { ApiCalls } from "../../api/allApiCalls";
import { useSelector } from "react-redux";
import Modal from "../../components/modals/Modal";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";

function EditMedicalNote({
	data,
	openModal,
	closeModal,
	updateData,
	patientId,
}) {
	const { masterId, userId, practiceId, custId } = useSelector(
		(state) => state.userInfo
	);
	const [inputValue, setInputValue] = useState("");
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		setInputValue(data);
	}, []);

	const handleCloseModal = () => {
		// If there are changes, ask user if they want to close or stay
		if (!isSaving && inputValue !== data) {
			Swal.fire({
				icon: "warning",
				text: "Are you sure you wish to close? Your changes will not be saved.",
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: "Close",
				cancelButtonText: "Don't close",
			}).then((res) => {
				// If user chooses "Close", close the modal, else stayde
				if (res.isConfirmed) {
					closeModal();
				}
			});
		} else {
			// In case there is no changes, simply Close the modal
			closeModal();
		}
	};

	const handleSave = async () => {
		// If there is no changes, simply close the Modal
		if (inputValue === data) {
			closeModal();

			// Else if there are changes, save to DB and close the modal
		} else {
			try {
				setIsSaving(true);
				const res = await ApiCalls.authPutAPI(
					"/v2/Patient/PatientMedicalNote",
					{
						masterId,
						userId,
						practiceId,
						custId: parseInt(custId),
						patientId,
						note: inputValue,
					}
				);

				if (res.isSuccess && res.message === "Successful") {
					Swal.fire({
						icon: "success",
						text: "Medical Note has been successfully updated.",
					});
					updateData();
					trackAcvitity(
						itemType.HEALTH_CONDITIONS,
						itemName.EDIT_HEALTH_CONDITIONS,
						"Home >> Click Edit icon in Health Conditions section >> Edit >> Save >> SUCCESS"
					);
				} else {
					Swal.fire("Something went wrong. Please try again later");
					trackAcvitity(
						itemType.HEALTH_CONDITIONS,
						itemName.EDIT_HEALTH_CONDITIONS,
						"Home >> Click Edit icon in Health Conditions section >> Edit >> Save >> ERROR"
					);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setIsSaving(false);
				closeModal();
			}
		}
	};

	return (
		<Modal openModal={openModal} closeModal={handleCloseModal}>
			<div
				className="text-center d-flex flex-column justify-content-center"
				style={{
					minWidth: 400,
					minHeight: 300,
				}}
			>
				{isSaving ? (
					<div className="width-100p height-100p d-flex flex-column justify-content-center align-items-center">
						<ThreeDots
							height="60"
							width="60"
							radius="9"
							color="#681c9a"
							ariaLabel="three-dots-loading"
							visible={true}
						/>
						<div>
							Saving changes...
							<br />
							Please keep the modal open.
						</div>
					</div>
				) : (
					<>
						<h2 className="fz-18 fw-bold mb-4">
							Edit Your Health Conditions
						</h2>
						<div>
							<textarea
								style={{
									minWidth: 300,
									minHeight: 200,
								}}
								autoFocus
								value={inputValue}
								placeholder="Add your health condition"
								onChange={(e) => setInputValue(e.target.value)}
							/>

							<div className="d-flex gap-2 justify-content-center mt-3">
								<button
									className="button button-primary"
									onClick={handleSave}
								>
									Save
								</button>
								<button
									className="button button-secondary"
									onClick={closeModal}
								>
									Discard
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
}

export default EditMedicalNote;
