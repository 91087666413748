import React from "react";
import InfoIcon from "@mui/icons-material/Info";

import { CustomTooltip } from "./customeTooltip";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";

const PPPTooltipContent = (
	<div
		className="d-flex flex-column align-items-start"
		style={{ fontSize: "13px" }}
	>
		<h4 className="fz-18 text-dark">Progressive Procedure Prediction:</h4>
		<div style={{ color: "#414d55" }}>
			Progressive Procedure Prediction is an AI tool that references your
			treatment history to predict the next major procedure you may
			require.
		</div>
	</div>
);

function ProgressiveProcedurePredictionTooltip() {
	return (
		<CustomTooltip title={PPPTooltipContent}>
			<InfoIcon
				className="font-purple ms-2"
				fontSize="small"
				onMouseEnter={() => {
					trackAcvitity(
						itemType.DESCRIPTION,
						itemName.HOVER_PPP_TOOLTIP,
						"Dashboard >> Hover on PPP Tooltip"
					);
				}}
			/>
		</CustomTooltip>
	);
}

export default ProgressiveProcedurePredictionTooltip;
