import { useState, useEffect } from "react";

const useScrollToTop = (targetDivRef) => {
    const [showUpButton, setShowUpButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (targetDivRef?.current) {
                const isScrolledDown = targetDivRef.current.scrollTop > 0;
                setShowUpButton(isScrolledDown);
            }
        };

        const container = targetDivRef?.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [targetDivRef]);

    const scrollToTop = () => {
        if (targetDivRef?.current) {
            targetDivRef.current.scroll({
                top: 0,
                behavior: "smooth",
            });
        }
    };

    return { showUpButton, scrollToTop };
};

export default useScrollToTop;
