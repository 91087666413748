import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Col } from "@progress/kendo-react-grid";
import { Collapse } from "antd";
const { Panel } = Collapse;
import { CSVLink } from "react-csv";

import "./TransactionHistory.scss";
import NoCreditCard from "../../../assets/images/NoCreditCard.png";
import { ApiCalls } from "../../../api/allApiCalls";
import {
	toCurrencyConverter,
	formatISOtoShortMonthDate,
	DENTAL,
	VETERINARY,
} from "../../../utils";
import TransactionDescriptionCell from "./TransactionDescriptionCell";
import CardLoader from "../../../components/loaders/CardLoader";
import Download from "../../../assets/images/Download.png";
import DateCell from "../../../components/tables/DateCell";
import AmountCell from "../../../components/tables/AmountCell";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../../utils/activityTracking";

export const initTableState = {
	skip: 0,
	take: 20,
	sort: [
		{
			field: "date",
			dir: "desc",
		},
	],
	filter: null,
};

function TransactionHistory() {
	const { masterId, userId, practiceId, custId, patientId, specialty } =
		useSelector((state) => state.userInfo);
	const { selectedPet } = useSelector((state) => state.petInfo);

	const [transactionData, setTransactionData] = useState([]);
	const [data, setData] = useState(process([], initTableState));
	const [state, setState] = useState(initTableState);
	const [loading, setLoading] = useState(false);
	const [csvData, setCsvData] = useState([]);

	useEffect(() => {
		const fetchTransactionHistoryData = async () => {
			try {
				setLoading(true);
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/TransactionHistoryData",
					{
						masterId,
						userId,
						practiceId,
						custId,
						patientId:
							specialty === DENTAL
								? patientId
								: selectedPet.patientId,
					}
				);

				setData(process(res, state));
				setTransactionData(res);

				const formattedRes = res.map((item) => ({
					...item,
					amount: toCurrencyConverter(item.amount),
					date: formatISOtoShortMonthDate(item.date),
				}));

				const csv = formattedRes.map((item) => ({
					Date: item.date,
					"Payment Type": item.paymentType,
					Description: item.description,
					Amount: item.amount,
				}));
				setCsvData(csv);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};
		if (specialty === VETERINARY && !selectedPet) return;
		fetchTransactionHistoryData();
	}, [selectedPet]);

	const dataStateChange = (event) => {
		setData(process(transactionData, event.dataState));
		setState(event.dataState);
	};

	return (
		<>
			<CSVLink
				filename={"Transaction_History.csv"}
				data={csvData}
				className="transaction-download-icon d-flex gap-2 align-items-center position-absolute font-purple fz-16"
				style={{ right: 5, top: -3, textDecoration: "none" }}
			>
				<span>Download Transaction History</span>
				<img
					alt="Download Excel"
					src={Download}
					role="button"
					style={{ width: 25, height: 25 }}
					onClick={() => {
						trackAcvitity(
							itemType.EXPORT_DATA,
							itemName.EXPORT_EXCEL,
							"Payments & Benefits page >> Click on download icon in 'Transaction History' section >> Download Excel file"
						);
					}}
				/>
			</CSVLink>

			<div className="pb-card transaction-table position-relative">
				{loading && <CardLoader />}
				{data.data.length !== 0 && (
					<>
						<Grid
							data={data}
							onDataStateChange={dataStateChange}
							{...state}
							pageable={{
								buttonCount: 4,
								pageSizes: [10, 20, 50],
							}}
							resizable={true}
							sortable={true}
						>
							<Col
								title="Date"
								field="date"
								width={150}
								cell={DateCell}
							/>
							<Col
								title="Payment Type"
								field="paymentType"
								width={150}
							/>
							<Col
								title="Descripton"
								field="description"
								cell={TransactionDescriptionCell}
							/>
							<Col
								title="Amount Paid"
								field="amount"
								cell={AmountCell}
							/>
						</Grid>

						<div className="sm-transaction-history-tbl">
							<Collapse defaultActiveKey={["1"]}>
								{transactionData.map((item, idx) => (
									<Panel
										header={
											<div className="d-flex justify-content-between align-items-center gap-1">
												<div className="fw-bold panel-header-clamp">
													{item.description}
												</div>
												<div>{item.date}</div>
											</div>
										}
										key={idx + 1}
									>
										<div className="d-flex flex-column gap-3">
											<div className="d-flex gap-5 justify-content-between flex-wrap">
												<div>
													<span className="fw-bold">
														Date:{" "}
													</span>
													{formatISOtoShortMonthDate(
														item.date
													)}
												</div>
												<div className="flex-1">
													<span className="fw-bold">
														Payment Type:{" "}
													</span>
													{item.paymentType}
												</div>
											</div>

											<div>
												<span className="fw-bold">
													Description:{" "}
												</span>
												{item.description}
											</div>
											<div>
												<span className="fw-bold">
													Amount:{" "}
												</span>
												{toCurrencyConverter(
													item.amount
												)}
											</div>
										</div>
									</Panel>
								))}
							</Collapse>
						</div>
					</>
				)}

				{data.data.length === 0 && (
					<div className="d-flex flex-column justify-content-center align-items-center height-100p">
						<img
							alt=""
							src={NoCreditCard}
							style={{ width: "30%" }}
						/>

						<div className="fz-18 fw-bold mb-2 font-black">
							No Transaction History Yet
						</div>
						<div className="fz-16 font-gray mb-4">
							You haven&apos;t made any payments yet
						</div>
					</div>
				)}

				<div></div>
			</div>
		</>
	);
}

export default TransactionHistory;
