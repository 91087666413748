import React from "react";

function NewFamilyMemberForm({ newMember, setNewMember }) {
	const handleChange = (e) => {
		setNewMember((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	return (
		<div className="family-member-flex flex-1">
			<div className="family-member-flex-item">
				<label>First Name *</label>
				<input
					name="firstName"
					autoFocus
					value={newMember.firstName}
					onChange={handleChange}
				/>
			</div>
			<div className="family-member-flex-item">
				<label>Last Name *</label>
				<input
					name="lastName"
					value={newMember.lastName}
					onChange={handleChange}
				/>
			</div>
			<div className="family-member-flex-item">
				<label>Email</label>
				<input
					name="email"
					value={newMember.email}
					onChange={handleChange}
				/>
			</div>
			<div className="family-member-flex-item">
				<label>Phone Number</label>
				<input
					name="phoneNumber"
					value={newMember.phoneNumber}
					onChange={handleChange}
				/>
			</div>
			<div className="family-member-flex-item">
				<label>Address</label>
				<input
					name="address"
					value={newMember.address}
					onChange={handleChange}
				/>
			</div>
		</div>
	);
}

export default NewFamilyMemberForm;
