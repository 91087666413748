import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { maskEmail } from "../../../utils";
import "../Verification/Verification.scss";
import sikka_logo_transparent from "../../../assets/images/sikka_logo_transparent.png";
import ButtonLoader from "../../../components/loaders/ButtonLoader";
import { ApiCalls } from "../../../api/allApiCalls";
import { addUserIdVerification } from "../../../states/userInfoSlice";
import useSetTitle from "../../../hooks/useSetTitle";

function OtpVerification() {
	const { emailId } = useSelector((state) => state.userInfo);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]); // Initial state with 6 empty strings
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const inputRefs = [
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
	]; // Refs for each input element

	useSetTitle("Forgot Password");

	const handleSubmit = async (e) => {
		e.preventDefault();
		// if the code is valid, navigate to createPassword page

		// Call API
		try {
			setLoading(true);
			const verificationCode = otpValues.join("");
			const res = await ApiCalls.authenticationPOST(
				"/v2/Password/VerifyAuthCode",
				{
					email: emailId,
					verificationCode,
				}
			);

			if (res?.isSuccess && res?.userId) {
				dispatch(
					addUserIdVerification({
						userId: res.userId,
						verificationCode,
					})
				);
				navigate("/forgotPassword/resetPassword");
			} else {
				setError(
					"The verification code entered is incorrect. Please try again."
				);
				setOtpValues(["", "", "", "", "", ""]);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleResendCode = async () => {
		try {
			setLoading(true);
			const res = await ApiCalls.authenticationPOST(
				"/v2/Password/SendVerificationCode",
				{
					email: emailId,
				}
			);

			if (res?.isSuccess) {
				setError("The verification code has been re-sent to you.");
			} else {
				setError(
					"The verification code entered is incorrect. Please try again."
				);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (event, index) => {
		const value = event.target.value;

		if (isNaN(value)) {
			return; // Only allow numeric values
		}

		const newOtpValues = [...otpValues];
		newOtpValues[index] = value;

		setOtpValues(newOtpValues);

		if (value && index < 5) {
			inputRefs[index + 1].current.focus(); // Focus on the next input element
		}
		if (!value && index > 0) {
			inputRefs[index - 1].current.focus(); // Focus on the previous input element if user deleted the current input
		}
	};

	return (
		<div className="login-form-page">
			<div className="form-content-wrapper" style={{ maxWidth: 700 }}>
				<img
					alt=""
					src={sikka_logo_transparent}
					style={{ height: 36, margin: "1.5rem" }}
				/>
				{error && (
					<div
						className="error-message mb-4 py-2 px-3"
						style={{ maxWidth: 470, textAlign: "center" }}
					>
						{error}
					</div>
				)}
				<div
					className="font-black fw-bold text-center mb-4"
					style={{ fontSize: "20px" }}
				>
					OTP Sent
				</div>
				<div className="mb-5">
					A verification code has been sent to {maskEmail(emailId)}.
					<br /> Please enter the code below.
					<br />
				</div>

				<form
					className="d-flex flex-column"
					onSubmit={handleSubmit}
					style={{ minWidth: 320 }}
				>
					<div className="width-100p d-flex justify-content-between">
						{otpValues.map((value, index) => (
							<input
								key={index}
								required
								value={value}
								onChange={(event) => handleChange(event, index)}
								autoFocus={index === 0 ? true : false}
								maxLength={1}
								style={{
									width: 42,
									height: 42,
									textAlign: "center",
									fontSize: 20,
								}}
								ref={inputRefs[index]}
							/>
						))}
					</div>
					<button
						className="button button-primary mt-3 mb-3"
						disabled={loading ? true : false}
					>
						{loading ? <ButtonLoader /> : "Verify OTP Code"}
					</button>

					<div
						className="font-purple resend-code-btn text-underline"
						role="button"
						disabled={loading ? true : false}
						onClick={handleResendCode}
					>
						Resend Code
					</div>
				</form>
			</div>
			<div className="company-copyright">
				© {new Date().getFullYear()} Sikka Software Corporation, The
				Practice Optimizer Company®. All Rights Reserved.
			</div>
		</div>
	);
}

export default OtpVerification;
