import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./SikkaScores.scss";
import { ApiCalls } from "../../api/allApiCalls";
import CardLoader from "../../components/loaders/CardLoader";
import { DENTAL, VETERINARY, isNumeric } from "../../utils";
import SikkaScoresTooltip from "../../components/tooltips/SikkaScoresTooltip";

function SikkaScore() {
	const { masterId, userId, practiceId, custId, patientId, specialty } =
		useSelector((state) => state.userInfo);
	const { selectedPet } = useSelector((state) => state.petInfo);
	const [leca, setLeca] = useState("");
	const [pews, setPews] = useState("");
	const [loadingLeca, setLoadingLeca] = useState(false);
	const [loadingPews, setLoadingPews] = useState(false);
	const [fetchedPPEWS, setFetchedPPEWS] = useState(false);

	useEffect(() => {
		const req = {
			masterId,
			userId,
			practiceId,
			custId,
			patientId,
		};
		const getLeca = async () => {
			try {
				setLoadingLeca(true);
				let res = await ApiCalls.authGetAPI(
					"/v2/Patient/HealthRiskScore",
					req
				);

				// If the score is numeric, set it to leca
				if (isNumeric(res.lecaScore)) {
					setLeca(res.lecaScore);
				}
				setLoadingLeca(false);
			} catch (err) {
				console.log(err);
				setLoadingLeca(false);
			}
		};
		const getPews = async () => {
			try {
				setLoadingPews(true);
				let res;
				if (specialty === DENTAL) {
					res = await ApiCalls.authGetAPI("/v2/Patient/PEWS", req);
				} else {
					// If already fetch PPEWS, no need to fetch again when user selects another pet
					if (fetchedPPEWS) {
						setLoadingPews(false);
						return;
					}
					res = await ApiCalls.authGetAPI("/v2/Patient/PPEWS", {
						masterId,
						userId,
						practiceId,
						custId,
						guarId: selectedPet.guarId,
					});
					setFetchedPPEWS(true);
				}

				// If the score is numeric, set it to pews
				if (isNumeric(res.pewsScore)) {
					setPews(res.pewsScore);
				}
				setLoadingPews(false);
			} catch (err) {
				console.log(err);
				setLoadingPews(false);
			}
		};

		if (specialty === VETERINARY && !selectedPet) return;
		if (specialty === DENTAL) {
			getLeca(); // Mortality score
		}
		getPews(); // economic well Being
	}, [selectedPet]);

	return (
		<div className="dashboard-item-card-container">
			<h3>
				Sikka Scores <SikkaScoresTooltip specialty={specialty} />
			</h3>

			<div
				className="border-radius-6 card-border d-flex flex-column"
				style={{ padding: "16px 20px", gap: 32 }}
			>
				{specialty === DENTAL && (
					<div className="sikka-score-item position-relative">
						{loadingLeca && <CardLoader />}
						<div className="d-flex align-items-center fw-bold fz-14">
							Health Risk Score
						</div>
						{leca ? (
							<div className="score-bar-wrapper">
								<div className="score">{leca}</div>
								<div className="bar-container">
									<div className="bar leca">
										<div
											className="gray-bar"
											style={{
												width: `calc(${
													100 - leca
												}% + 4px)`,
											}}
										></div>
										<div
											className="circle-container"
											style={{ width: `${leca}%` }}
										>
											<div className="circle"></div>
										</div>
									</div>
									<div className="scale">
										<div>1 - Low Risk</div>
										<div>100 - High Risk</div>
									</div>
								</div>
							</div>
						) : (
							<div className="fw-normal fz-14 mt-2 font-gray">
								Health Score will be reflected in few days
							</div>
						)}
					</div>
				)}

				<div className="sikka-score-item position-relative">
					{loadingPews && <CardLoader />}
					<div className="d-flex align-items-center fw-bold fz-14">
						Economic Well Being Score
					</div>

					{pews ? (
						<div className="score-bar-wrapper">
							<div className="score">{pews}</div>
							<div className="bar-container">
								<div className="bar pews">
									<div
										className="gray-bar"
										style={{
											width: `calc(${100 - pews}% + 4px)`,
										}}
									></div>
									<div
										className="circle-container"
										style={{ width: `${pews}%` }}
									>
										<div className="circle"></div>
									</div>
								</div>
								<div className="scale">
									<div>1 - Low Score</div>
									<div>100 - High Score</div>
								</div>
							</div>
						</div>
					) : (
						<div className="fw-normal fz-14 mt-2 font-gray">
							Economic Well Being Score will be reflected in few
							days
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default SikkaScore;
