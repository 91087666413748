import React from "react";

const Trademark = () => {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <div className="conatiner mt-5 footer-line">
            <p>
                {year + " "}Sikka Software Corporation, The Practice Optimizer
                Company®. All Rights Reserved.
            </p>
        </div>
    );
};
export default Trademark;
