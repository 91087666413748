import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiCalls } from "../api/allApiCalls";
import { VETERINARY } from "../utils";

const useFetchFinances = () => {
	const { masterId, userId, practiceId, custId, patientId, specialty } =
		useSelector((state) => state.userInfo);
	const { selectedPet } = useSelector((state) => state.petInfo);

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchFinancesData = async () => {
			try {
				setLoading(true);
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/PatientFinancialData",
					{
						masterId,
						userId,
						practiceId,
						custId,
						patientId:
							specialty === VETERINARY
								? selectedPet.patientId
								: patientId,
					}
				);

				if (res.length > 0) {
					setData(res[0]);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		if (specialty === VETERINARY && !selectedPet) return;
		fetchFinancesData();
	}, [selectedPet]);

	return [loading, data];
};

export default useFetchFinances;
