import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";
import Swal from "sweetalert2";
import { ApiCalls } from "../../api/allApiCalls";
import PasswordValidation from "../../components/PasswordValidation";
import {
	itemName,
	itemType,
	trackAcvitity,
} from "../../utils/activityTracking";
import { initialErrObj, validatePassword } from "../../utils";
import EditIcon from "../../assets/images/EditIcon.svg";

const StyledPrivacyAndSecurity = styled.div`
	.input-container {
		display: flex;
		column-gap: 3rem;
		row-gap: 16px;

		label {
			font-size: 16px;
			font-weight: bold;
			color: #080b11;
		}

		.input-description,
		input {
			font-size: 14px;
			color: #767b87;
			width: 300px;
		}
	}

	.privacy-and-security-container {
		display: flex;
		flex-direction: column;
		gap: 18px;
	}
	.MuiInputAdornment-positionEnd {
		margin-left: 16.9rem;
		position: static;
		margin-top: -1rem;
	}
	.MuiIconButton-root {
		padding-bottom: -10rem !important;
	}
	.MuiIconButton-root:hover {
		background-color: none !important;
	}

	@media screen and (max-width: 680px) {
		.input-container {
			flex-direction: column;
		}
	}
`;

function PrivacyAndSecurity() {
	const { masterId, userId, practiceId, custId, patientId, emailId } =
		useSelector((state) => state.userInfo);

	const [isEditing, setIsEditing] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showcPassword, setCShowPassword] = useState(false);
	const [currentPassword, setCurrentPassword] = useState("");
	const [passwordErr, setPasswordErr] = useState(initialErrObj);
	const [practiceData, setPracticeData] = useState([]);
	const [newPassword, setNewPassword] = useState({
		newPasswordString: "",
		newConfirmPasswordString: "",
	});

	useEffect(() => {
		const getSettingsProfileData = async () => {
			try {
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/SettingsProfileData",
					{
						masterId,
						userId,
						practiceId,
						patientId,
					}
				);

				const dataToStore = {
					...res[0],
				};
				setPracticeData(dataToStore);
			} catch (err) {
				console.log(err);
			}
		};
		getSettingsProfileData();
	}, []);

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleCancel = () => {
		setNewPassword({
			newPasswordString: currentPassword,
			newConfirmPasswordString: currentPassword,
		});
		setIsEditing(false);
		setShowPassword(false);
		setCShowPassword(false);
	};

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const handleToggleCPasswordVisibility = () => {
		setCShowPassword((prevShowPassword) => !prevShowPassword);
	};

	useEffect(() => {
		const fetchCurrentPassword = async () => {
			try {
				const res = await ApiCalls.authGetAPI(
					"/v2/Patient/SettingsProfileData",
					{
						masterId,
						userId,
						practiceId,
						custId,
						patientId,
					}
				);
				const data = res[0].password;

				setCurrentPassword(data);

				setNewPassword({
					newPasswordString: data,
					newConfirmPasswordString: data,
				});
			} catch (err) {
				console.log(err);
			}
		};
		fetchCurrentPassword();
	}, []);

	const handleOnchange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setNewPassword({ ...newPassword, [name]: value });
	};

	useEffect(() => {
		setPasswordErr(
			validatePassword(
				newPassword.newPasswordString,
				newPassword.newConfirmPasswordString
			)
		);
	}, [newPassword]);

	const savePassword = async () => {
		if (Object.values(passwordErr).includes(true)) {
			Swal.fire({ text: "Password is invalid!", icon: "error" });
			return;
		}

		try {
			const res = await ApiCalls.authPutAPI(
				"/v2/Patient/SettingsProfileData",
				{
					masterId,
					userId,
					patientId,
					practiceId,
					userName: emailId,
					password: newPassword.newConfirmPasswordString,
					practiceName: practiceData.practiceName,
					practiceNumber: practiceData.practiceNumber,
					practiceAddress: practiceData.practiceAddress,
				}
			);

			if (res.isSuccess && res.message === "Successful") {
				Swal.fire({
					text: "Password has been updated Successfully!",
					icon: "success",
				});

				trackAcvitity(
					itemType.USER_PROFILE,
					itemName.CHANGE_PASSWORD,
					" Profile & Setting Page >> Privancy & Security >> Click Edit icon >> Edit Password >> Click 'Save Changes' button"
				);
				setCurrentPassword(newPassword.newPasswordString);
			} else {
				Swal.fire({
					text: "Something wrong happened. Please try again later",
					icon: "error",
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsEditing(false);
			setShowPassword(false);
			setCShowPassword(false);
		}
	};

	return (
		<StyledPrivacyAndSecurity>
			<div className="profile-header">
				<h2>Privacy & Security</h2>
				<div
					className="d-flex align-items-center"
					role="button"
					onClick={handleEdit}
					hidden={isEditing ? true : false}
				>
					<img
						src={EditIcon}
						style={{ height: 20, marginRight: 5 }}
					/>
					Edit
				</div>
			</div>
			<div className="privacy-and-security-container">
				<div className="input-container">
					<div>
						<label>Email Id</label>

						<div className="input-description">
							Manage your account settings and preferences
						</div>
					</div>
					<div>
						<input
							placeholder="Email Id"
							value={emailId}
							disabled
						/>
					</div>
				</div>
				<div className="input-container">
					<div>
						<label>Password</label>
						<div className="input-description">
							Manage your account settings and preferences
						</div>
					</div>
					<div>
						<input
							value={newPassword.newPasswordString}
							name="newPasswordString"
							disabled={isEditing ? false : true}
							type={showPassword ? "text" : "password"}
							onChange={handleOnchange}
						/>
						<InputAdornment
							position="end"
							className="visiblity-icon"
						>
							<span style={{ color: "#681c9a" }}>
								{showPassword ? (
									<Visibility
										onClick={handleTogglePasswordVisibility}
									/>
								) : (
									<VisibilityOff
										onClick={handleTogglePasswordVisibility}
									/>
								)}
							</span>
						</InputAdornment>
					</div>
				</div>

				{isEditing && (
					<div className="input-container">
						<div>
							<label>Confirm Password</label>
							<div className="input-description">
								Manage your account settings and preferences
							</div>
						</div>
						<div>
							<input
								value={newPassword.newConfirmPasswordString}
								name="newConfirmPasswordString"
								type={showcPassword ? "text" : "password"}
								onChange={handleOnchange}
							/>
							<InputAdornment
								position="end"
								className="visiblity-icon"
							>
								<span
									style={{ color: "#681c9a" }}
									onClick={handleToggleCPasswordVisibility}
								>
									{showcPassword ? (
										<Visibility />
									) : (
										<VisibilityOff />
									)}
								</span>
							</InputAdornment>
						</div>
					</div>
				)}

				{isEditing && (
					<div>
						<div>
							<div className="input-container">
								<label>Password Rquirements:</label>
							</div>
							<PasswordValidation passwordErr={passwordErr} />
							<br></br>
						</div>
						<div>
							<div
								className="input-container"
								style={{ gap: 16 }}
							>
								<button
									className="button button-primary"
									onClick={savePassword}
								>
									Save Changes
								</button>
								<button
									className="button button-secondary"
									onClick={handleCancel}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</StyledPrivacyAndSecurity>
	);
}

export default PrivacyAndSecurity;
