import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RunningFigure from "../../../assets/images/RunningFigure.png";
import { login } from "../../../states/userInfoSlice";
import { browserStore } from "../../../utils";
import { setPageLoaderStatus } from "../../../states/pageLoaderSlice";
import useSetTitle from "../../../hooks/useSetTitle";
import {
    itemName,
    itemType,
    trackAcvitity,
} from "../../../utils/activityTracking";

function CreateNewAccountSuccess() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useSetTitle("Authentication");

    useEffect(() => {
        // If there is no api Token or userInfo (first and lastname), navigate to authentication page
        if (!browserStore.get("userInfo")?.apiToken) {
            navigate("/login");
        }
    }, []);

    const handleClick = () => {
        // change status of authenticated in reducer and session storage to true
        const updatedUserInfo = {
            ...browserStore.get("userInfo"),
            authenticated: true,
        };
        browserStore.set("userInfo", updatedUserInfo);
        dispatch(login(updatedUserInfo));
        dispatch(setPageLoaderStatus(true));
        setTimeout(() => {
            dispatch(setPageLoaderStatus(false));
        }, 300);
        browserStore.set("first_time_user", true);
        trackAcvitity(
            itemType.NAVIGATION,
            itemName.NAVIGATE_TO_HOME_PAGE_AFTER_CREATING_ACCOUNT,
            "/authentication/create-account/success >> Click 'Home Page' button to navigate to Home page after creating new account"
        );
        navigate("/");
    };

    return (
        <div className="authentication-form-page">
            <div className="form-content-wrapper">
                <img alt="" src={RunningFigure} />

                <div style={{ maxWidth: 400 }}>
                    <div className="mb-4 mt-5">
                        Congratulations! You have successfully created your
                        account. You can start using our service now.
                    </div>

                    <button
                        className="button button-primary"
                        onClick={handleClick}
                    >
                        Home Page
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateNewAccountSuccess;
